import React from 'react';
import { type DetailedProxyMetadata } from 'venn-api';
import { capitalize } from 'lodash';
import type { FundToBulkProxy } from '../../types';
import { formatDateByFrequency } from '../../../../manage-data/utils';
import { Icon } from 'venn-ui-kit';

export const SingleInvestmentInfo = ({
  investment,
  proxyData,
}: {
  investment: FundToBulkProxy;
  proxyData: DetailedProxyMetadata | undefined;
}) => {
  const proxyName = proxyData?.proxyName;

  const startRange = proxyData?.unproxiedStartDate ?? investment.startRange;
  const endRange = proxyData?.unproxiedEndDate ?? investment.endRange;
  // TODO(VENN-28401): get an unproxiedFrequency not a proxiedfrequency from proxyData
  const unproxiedFrequency = proxyData?.unproxiedFrequency ?? investment.returnFrequency;
  const proxiedFrequency = proxyData?.proxiedFrequency ?? investment.returnFrequency;

  return (
    <div className="flex whitespace-nowrap gap-8 bg-venn-grey-10 px-3 py-2">
      <div className="flex flex-col gap-2 flex-grow">
        <div className="font-bold">Asset Name</div>
        {investment.name}
      </div>
      <div className="flex gap-6 min-w-0">
        {proxyName && (
          <div className="flex flex-col gap-2 flex-1 min-w-0 max-w-lg">
            <div className="font-bold overflow-hidden overflow-ellipsis">Existing Proxy</div>
            <div className="min-w-0 overflow-hidden overflow-ellipsis">{proxyName}</div>
          </div>
        )}
        <div className="flex flex-col gap-2">
          <div className="font-bold">Frequency</div>
          {(proxyData?.proxyType === 'INTERPOLATE' || proxyData?.proxyType === 'DESMOOTH_INTERPOLATE') &&
          proxiedFrequency !== unproxiedFrequency ? (
            <span>
              {capitalize(unproxiedFrequency)} <Icon type="arrow-right" className="inline text-venn-grey-60" />{' '}
              {capitalize(proxiedFrequency)}
            </span>
          ) : (
            capitalize(unproxiedFrequency)
          )}
        </div>
        <div className="flex flex-col gap-2">
          <div className="font-bold">Available Dates</div>
          {formatDateByFrequency(startRange, investment.returnFrequency)} -{' '}
          {formatDateByFrequency(endRange, investment.returnFrequency)}
        </div>
      </div>
    </div>
  );
};
