import React from 'react';
import { useRecoilValue } from 'recoil';
import { blockDisplayHeader, blockSettings, customizedBlock, isReportState } from 'venn-state';
import { Block } from '../../components/core';
import { useAppPrintMode } from '../../../print';
import { DefaultSubjects } from '../../components/secondary/subjects/default-subjects';
import { AsOfDate } from '../../components/secondary/as-of-date';
import { useHoldings } from '../../../studio-blocks';
import { assertNotNil, getDateBehavior } from 'venn-utils';
import { HoldingsCalculationInfo } from '../../components/secondary/holdings-calculation-info';
import { BenchmarkType } from '../../components/secondary/benchmark-type';

interface HoldingsHeaderProps {
  id: string;
}

export const HoldingsHeader = ({ id }: HoldingsHeaderProps) => {
  const { inPrintModeOrReportLab } = useAppPrintMode();
  const { subHeader } = useRecoilValue(customizedBlock(id));
  const title = useRecoilValue(blockDisplayHeader(id));
  const isReport = useRecoilValue(isReportState);
  const { data } = useHoldings(id);
  const holdingsData = assertNotNil(data);
  const blockSetting = useRecoilValue(blockSettings(id));
  const dateBehavior = getDateBehavior(blockSetting.customBlockType);
  const blockType = blockSetting?.customBlockType;

  return (
    <>
      <Block.HeaderTopContainer inPrintMode={inPrintModeOrReportLab}>
        <Block.Title>
          <Block.Headline id={id} isReport={isReport}>
            {title}
          </Block.Headline>
          {subHeader && <Block.SubHeadline id={id}>{subHeader}</Block.SubHeadline>}
        </Block.Title>
        <Block.Metadata id={id}>
          <BenchmarkType id={id} />
          <AsOfDate holdingsData={holdingsData} dateBehavior={dateBehavior} />
          <HoldingsCalculationInfo blockType={blockType} />
        </Block.Metadata>
      </Block.HeaderTopContainer>

      <DefaultSubjects id={id} relativeToBenchmark={false} />
    </>
  );
};
