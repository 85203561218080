import React from 'react';
import { useRecoilValue } from 'recoil';

import DeveloperFallbackComponent from '../../../../error-boundary/DeveloperFallbackComponent';
import { withErrorBoundary } from '../../../../error-boundary/CustomizableErrorBoundary';

import { type DATE_BEHAVIOR_ENUM } from 'venn-utils';
import { blockDateRange } from 'venn-state';

import { EndDateRange } from './end-date-range';
import { DefaultDateRange } from './default-date-range';

import { Block } from '../../core';

interface PrivateAssetGrowthDateRangeProps {
  id: string;
  dateBehavior: DATE_BEHAVIOR_ENUM;
}

export const PrivateAssetGrowthDateRange = withErrorBoundary(
  DeveloperFallbackComponent,
  ({ id, dateBehavior }: PrivateAssetGrowthDateRangeProps) => {
    const dateRange = useRecoilValue(blockDateRange(id));

    return (
      <>
        <Block.MetadataItem iconType="clock">Quarterly</Block.MetadataItem>
        {dateBehavior === 'END_DATE_RANGE' ? (
          <EndDateRange
            dateRange={dateRange}
            to={dateRange?.range.to}
            frequency="QUARTERLY"
            endDateRangePrefix="Simulation Start Date: "
          />
        ) : (
          <DefaultDateRange dateRange={dateRange} to={dateRange?.range.to} frequency="QUARTERLY" />
        )}
      </>
    );
  },
);
