import { isNil } from 'lodash';
import type {
  RangeAnalysisResponse,
  AnalysisRequest,
  Portfolio,
  SubjectRangeAnalysis,
  DetailedProxyMetadata,
  SimpleFund,
  Fund,
} from 'venn-api';
import { getAppTitle } from 'venn-ui-kit';
import type { AnalysisSubject, WithStrategies } from 'venn-utils';
import { IS_PROD, flattenNodeWithStrategyPath, getAnalysisLabels, logExceptionIntoSentry } from 'venn-utils';
import type { BulkManageRow, CommonRangeData, CreateUpdateMetadata } from './types';
import type { FundToBulkProxy } from '../modals/pickers/types';

export const getBulkManagementData = (
  subject: AnalysisSubject,
  funds: SimpleFund[],
  proxyDataByFund: Record<string, DetailedProxyMetadata | undefined>,
  rangeAnalysis: RangeAnalysisResponse | undefined,
  rangeAnalysisRequest: Partial<AnalysisRequest> | undefined,
  benchmark: Portfolio | Fund | null | undefined,
  commonRangeData: CommonRangeData,
): BulkManageRow[] => {
  const result: BulkManageRow[] = [];
  const fundIdToFund = new Map(funds?.map((f) => [f.id, f]));

  const primaryIndex = rangeAnalysisRequest?.subjects?.findIndex((s) => s.comparisonType === 'PRIMARY');
  const primaryRangeAnalysis = !isNil(primaryIndex) ? rangeAnalysis?.rangeAnalyses[primaryIndex] : undefined;
  const rangeAnalyses = primaryRangeAnalysis
    ? primaryRangeAnalysis.investmentId
      ? [primaryRangeAnalysis]
      : primaryRangeAnalysis.rangeAnalyses
    : [];
  const rangeAnalysesByInvestmentId = new Map(rangeAnalyses.map((a) => [a.investmentId, a]));

  const flattenedPortfolioWithStrategyPaths = flattenNodeWithStrategyPath(subject.portfolio);
  const rangeLoading = isNil(rangeAnalysis);

  if (subject.fund) {
    result.push(
      mapFundToRow(
        commonRangeData,
        subject.fund.name,
        [],
        subject.fund,
        proxyDataByFund[subject.fund.id],
        rangeAnalysesByInvestmentId.get(subject.fund.id),
        rangeLoading,
        false,
        undefined,
      ),
    );
  }

  const portfolioFundEntryMap = new Map<string, BulkManageRow>();
  for (const portfolioEntry of flattenedPortfolioWithStrategyPaths) {
    if (!portfolioEntry.fund) {
      // If the node doesn't have a fund, it is either the root or a strategy.
      if (portfolioEntry.id !== subject.portfolio?.id && portfolioEntry.children.length) {
        result.push(mapStrategyToRow(commonRangeData, portfolioEntry));
      }
      continue;
    }

    const fund = fundIdToFund.get(portfolioEntry.fund.id);
    if (!fund) {
      // hasn't loaded yet, so skip it for now
      continue;
    }

    const newEntry = mapFundToRow(
      commonRangeData,
      fund.name,
      portfolioEntry.strategyIdPath,
      fund,
      proxyDataByFund[fund.id],
      rangeAnalysesByInvestmentId.get(fund.id),
      rangeLoading,
      false,
      portfolioEntry.allocation,
    );

    const existingEntry = portfolioFundEntryMap.get(newEntry.rowId);
    if (existingEntry) {
      // If we already have an entry with the the same exact rowId, we should just merge the allocations.
      // This is a rare edge case that can happen if somehow someone adds the same investment twice to a strategy.
      if (!isNil(existingEntry.allocation) || !isNil(portfolioEntry.allocation)) {
        existingEntry.allocation = (existingEntry.allocation ?? 0) + (portfolioEntry.allocation ?? 0);
      }
      continue;
    }
    portfolioFundEntryMap.set(newEntry.rowId, newEntry);
  }
  result.push(...portfolioFundEntryMap.values());

  if (benchmark) {
    const labels = getAnalysisLabels(subject.type, subject.secondaryLabel, subject.secondaryPortfolio?.updated);
    const benchmarkIndex = rangeAnalysisRequest?.subjects?.findIndex((s) => s.comparisonType === 'BENCHMARK');
    const benchmarkRange = !isNil(benchmarkIndex) ? rangeAnalysis?.rangeAnalyses[benchmarkIndex] : undefined;

    const name = benchmark.name ? `${labels.benchmark}: ${benchmark.name}` : labels.benchmark;
    const row =
      'children' in benchmark
        ? mapPortfolioToRow(commonRangeData, name, benchmark, benchmarkRange, rangeLoading)
        : mapFundToRow(
            commonRangeData,
            name,
            [],
            benchmark,
            proxyDataByFund[benchmark.id],
            benchmarkRange,
            rangeLoading,
            true,
          );
    row.secondary = true;
    row.isBenchmark = true;
    result.push(row);
  }

  if (rangeAnalysis?.factorRange) {
    const factorRange = rangeAnalysis.factorRange;
    const rowId = `Factors${factorRange.start}${factorRange.end}`;
    const row: BulkManageRow = {
      commonRangeData,
      name: 'Factors',
      rowId,
      fullPath: [rowId],
      investment: undefined,
      fundProxyInfo: undefined,
      allocation: undefined,
      startDate: factorRange.start,
      endDate: factorRange.end,
      frequency: factorRange.frequency,
      secondary: true,
      rangeLoading,
      dataSource: getAppTitle(),
    };
    result.push(row);
  }

  try {
    // verify that all results have unique rowIds and paths
    const rowIds = new Set<string>();
    const paths = new Set<string>();
    for (const row of result) {
      if (rowIds.has(row.rowId)) {
        throw new Error(`Duplicate rowId: ${row.rowId} in ${subject.name}`);
      }
      rowIds.add(row.rowId);

      const path = row.fullPath.join('>');
      if (paths.has(path)) {
        throw new Error(`Duplicate path: ${path} in ${subject.name}}`);
      }
      paths.add(path);
    }
  } catch (e) {
    if (IS_PROD) {
      logExceptionIntoSentry(e);
    } else {
      throw e;
    }
  }

  return result;
};

const getCreateUpdateMetadata = (subject?: CreateUpdateMetadata): CreateUpdateMetadata => ({
  created: subject?.created,
  updated: subject?.updated,
  owner: subject?.owner,
  updatedBy: subject?.updatedBy,
});

const mapStrategyToRow = (commonRangeData: CommonRangeData, strategy: WithStrategies<Portfolio>): BulkManageRow => {
  const strategyId = String(strategy.id);
  const fullPath = strategy.strategyIdPath.map(String);
  fullPath.push(String(strategy.id));
  return {
    ...getCreateUpdateMetadata(strategy),
    name: strategy.name,
    rowId: strategyId,
    fullPath,
    investment: undefined,
    fundProxyInfo: undefined,
    allocation: strategy.allocation,
    startDate: strategy.periodStart,
    endDate: strategy.periodEnd,
    frequency: strategy.lowestFrequency,
    isStrategy: true,
    commonRangeData,
  };
};

const mapPortfolioToRow = (
  commonRangeData: CommonRangeData,
  name: string,
  portfolio: Portfolio,
  portfolioRange: SubjectRangeAnalysis | undefined,
  rangeLoading: boolean,
): BulkManageRow => {
  const rowId = `bm${String(portfolio.id)}`;
  return {
    ...getCreateUpdateMetadata(portfolio),
    name,
    rowId,
    fullPath: [rowId],
    investment: undefined,
    fundProxyInfo: undefined,
    allocation: portfolio.allocation,
    startDate: portfolio.periodStart,
    endDate: portfolio.periodEnd,
    // TODO(VER-816, VENN-28438): if we stop using range analysis for funds, we can also stop using it here by using lowestFrequencyOf with
    // flattenNode(portfolio) alternatively we can add a frequency to the portfolio object returned by the backend.
    frequency: portfolioRange?.frequency,
    rangeLoading,
    commonRangeData,
  };
};

const mapFundToRow = (
  commonRangeData: CommonRangeData,
  name: string,
  strategyPath: number[],
  fund: FundToBulkProxy,
  fundProxyInfo: DetailedProxyMetadata | undefined,
  investmentRange: SubjectRangeAnalysis | undefined,
  rangeLoading: boolean,
  secondary: boolean,
  allocation?: number,
): BulkManageRow => {
  const strategyStrPath = strategyPath.map(String);
  strategyStrPath.push(fund.id + (secondary ? 'bm' : ''));

  return {
    ...getCreateUpdateMetadata(fund),
    name,
    rowId: strategyStrPath.join('>'),
    fullPath: strategyStrPath,
    fundProxyInfo,
    investment: fund,
    allocation,
    dataSource: fund.dataSource ?? (fund.userUploaded ? 'Upload' : getAppTitle()),
    startDate: fund.startRange,
    endDate: fund.endRange,

    // TODO(VER-816, VENN-28438): if we could add proxyStartDate, proxyEndDate, extrapolateStartDate, and extrapolateEndDate to the bulk proxy metadata endpoint
    // we wouldn't need a separate resource intensive range analysis call.
    proxyStartDate: investmentRange?.proxyStartDate ?? undefined,

    proxyEndDate: investmentRange?.proxyEndDate ?? undefined,
    extrapolateStartDate: investmentRange?.extrapolateStartDate ?? undefined,
    extrapolateEndDate: investmentRange?.extrapolateEndDate ?? undefined,
    frequency: fund.returnFrequency,
    rangeLoading,
    secondary,
    commonRangeData,
  };
};
