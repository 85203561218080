import React, { memo } from 'react';

import { COMMON_ERROR_WRAPPERS } from '../../../studio-blocks/components/error-wrappers/common';
import { Block } from '../../components/core';
import { HoldingsHeader } from './holdings-header';
import { HoldingsContent } from './holdings-content';
import { AssetExposureErrorWrapper } from '../../../studio-blocks/components/error-wrappers/AssetExposureErrorWrapper';

interface HoldingsBlockProps {
  id: string;
}

export const HoldingsBlock = memo(function InternalHoldingsBlock({ id }: HoldingsBlockProps) {
  return (
    <Block.Root id={id}>
      <Block.Header>
        <HoldingsHeader id={id} />
      </Block.Header>
      <Block.Content id={id} errorWrappers={[...COMMON_ERROR_WRAPPERS, AssetExposureErrorWrapper]}>
        <HoldingsContent id={id} />
      </Block.Content>
    </Block.Root>
  );
});
