import React from 'react';
import styled, { useTheme } from 'styled-components';
import { getDataColor } from './analysisPeriodUtils';
import type { FrequencyEnum } from 'venn-api';
import { overlapColor, rangeBarBackground } from './columns/analysisRangeColDef';
import { useHasFF } from 'venn-utils';

export const BulkManagementLegend = () => {
  const hasBulkProxy = useHasFF('portfolio_bulk_proxy_ff');
  const theme = useTheme();

  const normalColors = getDataColor(theme, false);
  const zeroAllocationColors = getDataColor(theme, true);

  if (!hasBulkProxy) return null;

  return (
    <LegendContainer>
      <LegendItem>
        <LegendSwatch color={normalColors.investmentColor} zeroAllocation={false} frequency="DAILY" />
        <LegendText>Investment returns</LegendText>
      </LegendItem>
      <LegendItem>
        <LegendSwatch color={normalColors.investmentColor} zeroAllocation={false} frequency="QUARTERLY" />
        <LegendText>Quarterly frequency returns</LegendText>
      </LegendItem>
      <LegendItem>
        <LegendSwatch color={normalColors.proxyColor} zeroAllocation={false} frequency="DAILY" />
        <LegendText>Proxy data: backfill, substitution, interpolation, desmoothing</LegendText>
      </LegendItem>
      <LegendItem>
        <LegendSwatch color={normalColors.extrapolationColor} zeroAllocation={false} frequency="DAILY" />
        <LegendText>Proxy data: extrapolation</LegendText>
      </LegendItem>
      <LegendItem>
        <LegendSwatch color={zeroAllocationColors.investmentColor} zeroAllocation frequency="DAILY" />
        <LegendText>Investment returns (no allocation)</LegendText>
      </LegendItem>
      <LegendItem>
        <LegendSwatch color={zeroAllocationColors.proxyColor} zeroAllocation frequency="DAILY" />
        <LegendText>Proxy (no allocation)</LegendText>
      </LegendItem>
      <LegendItem>
        <LegendSwatch
          style={{ height: '16px' }}
          color={overlapColor({ theme })}
          zeroAllocation={false}
          frequency="DAILY"
        />
        <LegendText>Available analysis period</LegendText>
      </LegendItem>
    </LegendContainer>
  );
};

const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const LegendItem = styled.div`
  display: flex;
  gap: 4px;
  align-items: baseline;
  align-self: stretch;
`;

const LegendSwatch = styled.div<{
  color: string;
  zeroAllocation: boolean;
  frequency: FrequencyEnum;
}>`
  ${rangeBarBackground}

  min-width: 20px;
  height: 4px;
`;

const LegendText = styled.span`
  font-size: 14px;
`;
