import { useQuery, createProxyMetadataKey, VennQueryClient } from 'venn-utils';
import { analysis, type AnalysisSubject } from 'venn-api';
import { zipObject, first } from 'lodash';

type QueryOptions = {
  suspense?: boolean;
};

export const prefetchProxyData = (fundIds: string[]) => {
  return VennQueryClient.getInstance().prefetchQuery(createQuery(fundIds));
};

/**
 * Fetches proxy metadata for a list of funds.
 */
export const useProxyDataQuery = (fundIds: string[], options?: QueryOptions) => {
  return useQuery(createQuery(fundIds, options));
};

function createQuery(fundIds: string[], { suspense = false }: QueryOptions = {}) {
  return {
    staleTime: 5 * 60 * 1000,
    queryKey: createProxyMetadataKey(fundIds),
    enabled: fundIds.length > 0,
    suspense,
    queryFn: async () => {
      const result = await analysis({
        analyses: [
          {
            analysisType: 'DETAILED_PROXY_METADATA',
            relative: false,
            scenarios: [],
          },
        ],
        subjects: fundIds.map(
          (id) =>
            ({
              id,
              comparisonType: 'PRIMARY',
              subjectType: 'INVESTMENT',
            }) as AnalysisSubject,
        ),
      });

      return zipObject(
        fundIds,
        result.content.analyses?.[0]?.detailedProxyMetadata?.map((dpm) => first(dpm)),
      );
    },
  };
}
