import { isNil, max, negate } from 'lodash';
import moment from 'moment';
import { atomFamily, selectorFamily } from 'recoil';
import type { Portfolio } from 'venn-api';
import type { DateRange } from 'venn-ui-kit';
import { PRO_FORMA_HISTORICAL_BLOCKS, PRO_FORMA_HISTORICAL_METRICS } from 'venn-utils';
import type { BlockId, StudioRequestSubject, Subject } from '../types';
import { blockLimitedRequestSubjects, blockMetrics } from './blockConfig';
import { blockSettings } from './blockSettings';
import { subjectsDateRanges } from './dateRange';
import { isHistoricalSubject } from './subjects';

export const historicalPortfolioAsOfDateSetting = atomFamily<string | undefined, BlockId>({
  key: 'historicalPortfolioAsOfDateSetting',
  default: undefined,
});

export const historicalPortfolioAsOfDate = selectorFamily<number | undefined, BlockId>({
  key: 'historicalPortfolioAsOfDate',
  get:
    (blockId) =>
    ({ get }) => {
      const setting = get(historicalPortfolioAsOfDateSetting(blockId));
      if (setting === 'Current' || isNil(setting)) {
        return undefined;
      }
      if (setting === 'Last Uploaded') {
        return get(lastHistoricalAllocationDate(blockId))?.getTime();
      }
      return moment(setting, 'MM/DD/YYYY').valueOf();
    },
});

export const historicalSubjectAsOfDate = selectorFamily<number | undefined, BlockId>({
  key: 'historicalSubjectAsOfDate',
  get:
    (blockId) =>
    ({ get }) => {
      const setting = get(blockSettings(blockId));
      const selectedMetrics = get(blockMetrics(blockId));
      const range = get(historicalPortfolioDateRange(blockId));
      const customBlockType = setting.customBlockType;
      const subjects = get(blockLimitedRequestSubjects(blockId));

      const isHistorical = subjects.some(isHistoricalSubject);
      const isProFormaBlock = customBlockType && PRO_FORMA_HISTORICAL_BLOCKS.includes(customBlockType);
      const isProFormaMetric = selectedMetrics?.some((metric) => PRO_FORMA_HISTORICAL_METRICS.includes(metric));
      return isHistorical && (isProFormaBlock || isProFormaMetric) ? range?.to : undefined;
    },
});

/**
 * This is just a utility selector combining together historicalSubjectAsOfDate and historicalPortfolioAsOfDate
 */
export const historicalAsOfDateSelector = selectorFamily<number | undefined, BlockId>({
  key: 'historicalAsOfDateSelector',
  get:
    (blockId) =>
    ({ get }) => {
      const subjectAsOfDate = get(historicalSubjectAsOfDate(blockId));
      const portfolioAsOfDate = get(historicalPortfolioAsOfDate(blockId));
      return portfolioAsOfDate ?? subjectAsOfDate;
    },
});

export const historicalPortfolioDateRange = selectorFamily<DateRange | undefined, BlockId>({
  key: 'historicalPortfolioDateRange',
  get:
    (blockId) =>
    ({ get }) => {
      const subjects = get(blockLimitedRequestSubjects(blockId));
      const historicalSubjects = subjects.filter(isHistoricalSubject).map(requestSubjectToSubject);
      if (historicalSubjects.length === 0) {
        return undefined;
      }
      return get(subjectsDateRanges(historicalSubjects))?.[0]?.range;
    },
});

export const lastHistoricalAllocationDate = selectorFamily<Date | undefined, BlockId>({
  key: 'lastHistoricalAllocationDate',
  get:
    (blockId) =>
    ({ get }) => {
      const subjects = get(blockLimitedRequestSubjects(blockId));
      return getLastHistoricalAllocationDate(subjects.length > 0 ? subjects[0].portfolio : undefined);
    },
});

const getLastHistoricalAllocationDate = (portfolio?: Portfolio): Date | undefined => {
  if (!portfolio) {
    return undefined;
  }

  const nodeMaxAlloc = max(portfolio.closingAllocationsTs?.map((allocation) => allocation[0]));
  const childrenMaxAlloc = portfolio.children?.map(getLastHistoricalAllocationDate);
  const maxDate = max(
    [nodeMaxAlloc ? moment(nodeMaxAlloc).toDate() : undefined, ...childrenMaxAlloc].filter(negate(isNil)),
  );
  return maxDate ? moment(maxDate).endOf('month').toDate() : undefined;
};

export const requestSubjectToSubject = (subject: StudioRequestSubject): Subject => ({
  privateFundId: subject?.privateFund?.id,
  privatePortfolioId: subject?.privatePortfolio?.id,
  fundId: subject?.fund?.id,
  portfolioId: (subject?.modifiedPortfolio ?? subject?.portfolio)?.id,
  portfolioVersion: (subject?.modifiedPortfolio ?? subject?.portfolio)?.version,
});
