import React, { useContext } from 'react';
import { FlexHeader } from '../../../../shared';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  blockDateRangeInputState,
  blockPrivateDefaultAsOfDateBehavior,
  blockRequestSubjects,
  blockSettings,
} from 'venn-state';
import { DateRangeInputDropMenu } from './DateRangeInputDropMenu';
import { UserContext } from 'venn-components';
import { Radio } from 'venn-ui-kit';
import {
  hasDisabledDateRange,
  isHistoricalSubjectAsOfDateParameterized,
  isPrivatesBlock,
  isPrivatesPerformanceBlock,
  isProjectionBasedPrivatesBlock,
  isPublicPrivateAssetGrowthBlock,
} from 'venn-utils';
import styled from 'styled-components';
import { AllocationAsOfDateInput } from '../components/AllocationAsOfDateInput';

/** Section for editing a block config with regard to its {@link blockDateRangeInputState}. */
export const DateInputSection = React.memo(function DateInputSection({ selectedBlockId }: { selectedBlockId: string }) {
  const { hasPermission } = useContext(UserContext);
  const [selectedInputId, setSelectedInputId] = useRecoilState(blockDateRangeInputState(selectedBlockId));
  const subjects = useRecoilValue(blockRequestSubjects(selectedBlockId));
  const readonly = !hasPermission('STUDIO_EDIT_DATES') || !hasPermission('STUDIO_EDIT');

  const blockSetting = useRecoilValue(blockSettings(selectedBlockId));
  const [defaultAsOfDateBehavior, setDefaultAsOfDateBehavior] = useRecoilState(
    blockPrivateDefaultAsOfDateBehavior(selectedBlockId),
  );

  const isPrivate = isPrivatesBlock(blockSetting.customBlockType);

  const isHistoricProFormaOnlyBlock =
    subjects.length > 0 && isHistoricalSubjectAsOfDateParameterized(subjects, blockSetting.customBlockType);

  const isAssetGrowthPrivate = isPublicPrivateAssetGrowthBlock(blockSetting.customBlockType);
  const isProjectionBasedPrivate = isProjectionBasedPrivatesBlock(blockSetting.customBlockType);
  const isPerformanceBasedPrivate = isPrivatesPerformanceBlock(blockSetting.customBlockType);
  const displayCustomAsOfDateSelection = isPerformanceBasedPrivate || isProjectionBasedPrivate || isAssetGrowthPrivate;
  const canShowProFormaDates = !hasDisabledDateRange(blockSetting.customBlockType);
  return (
    <>
      {canShowProFormaDates && displayCustomAsOfDateSelection && (
        <div data-testid="qa-block-date-range">
          <FlexHeader style={{ marginBottom: '8px' }}>
            {isProjectionBasedPrivate && 'Projection Start'}
            {isPerformanceBasedPrivate && 'End Date'}
            {isAssetGrowthPrivate && 'Simulation Start Date'}
          </FlexHeader>
          <StyledRadio
            inputId={isProjectionBasedPrivate ? 'lastAvailableTransaction' : 'defaultAsOfDateBehavior'}
            value={isProjectionBasedPrivate ? 'lastAvailableTransaction' : 'defaultAsOfDateBehavior'}
            checked={defaultAsOfDateBehavior}
            onChange={() => setDefaultAsOfDateBehavior(!defaultAsOfDateBehavior)}
            disabled={readonly}
          >
            {isProjectionBasedPrivate && 'Project from last available transaction'}
            {isPerformanceBasedPrivate && 'Latest available'}
            {isAssetGrowthPrivate && 'Current quarter end date'}
          </StyledRadio>
          <StyledRadio
            inputId="dateRangeSelect"
            value="dateRangeSelect"
            checked={!defaultAsOfDateBehavior}
            onChange={() => setDefaultAsOfDateBehavior(!defaultAsOfDateBehavior)}
            disabled={readonly}
          >
            <DateRangeInputDropMenu
              selectedInputId={selectedInputId}
              onSelectInput={setSelectedInputId}
              readonly={readonly || defaultAsOfDateBehavior}
              isPrivate
              isProjectionBasedPrivate={isProjectionBasedPrivate}
            />
          </StyledRadio>
        </div>
      )}
      {canShowProFormaDates && !displayCustomAsOfDateSelection && !isHistoricProFormaOnlyBlock && (
        <div data-testid="qa-block-date-range">
          <FlexHeader style={{ marginBottom: '12px' }}>{isPrivate ? 'Date' : 'Date Range'}</FlexHeader>
          <DateRangeInputDropMenu
            selectedInputId={selectedInputId}
            onSelectInput={setSelectedInputId}
            isPrivate={isPrivate}
            isProjectionBasedPrivate={isProjectionBasedPrivate}
            readonly={readonly}
          />
        </div>
      )}
      <AllocationAsOfDateInput selectedBlockId={selectedBlockId} />
    </>
  );
});

const StyledRadio = styled(Radio)`
  margin-top: 4px;
  margin-bottom: 4px;
`;
