import React from 'react';
import EmptyState from '../empty-state/EmptyState';
import { getTextThemeProvider } from 'venn-ui-kit';
import type { FallbackComponent } from './FallbackComponent';
import styled from 'styled-components';

export const SomethingWentWrongFallbackComponent: FallbackComponent = () => (
  <ErrorWrapper>
    <EmptyState
      header="Something went wrong"
      message={`Please try refreshing the page. If problem persists, contact ${getTextThemeProvider().supportEmail}.`}
    />
  </ErrorWrapper>
);

const ErrorWrapper = styled.div`
  margin: 20px;
  width: 100%;
`;
