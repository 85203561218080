import React from 'react';
import styled, { css } from 'styled-components';
import { Button, Subtitle1, Headline2, Loading, ZIndex, GetColor, Headline3 } from 'venn-ui-kit';
import { Modal, ModalContent, ModalSize, ModalSubhead, ModalFooter } from '../../modal';
import type { CookieSection } from '../utils';
import { SideMenuItems } from '../utils';
import SideMenu from '../../side-menu/SideMenu';
import SliderToggle from '../../slider-toggle/SliderToggle';
import useCookieTabState from '../logic/useCookieTabState';
import useCookiePersistence from '../logic/useCookiePersistence';
import CookieTab from './CookieTab';
import { isMobile, withSuspense } from 'venn-utils';
import { ADVERTISING_COOKIES, CookiesAllowed, FUNCTIONAL_COOKIES, REQUIRED_COOKIES } from '../CookiesCommon';

const CookiePreferencesModal = ({
  onClose,
  originalCookiePreferences = CookiesAllowed.None,
}: {
  onClose: () => void;
  originalCookiePreferences?: CookiesAllowed;
}) => {
  const {
    selectedSection,
    functionalToggled,
    toggleFunctional,
    advertisingToggled,
    toggleAdvertising,
    setSelectedSection,
  } = useCookieTabState(originalCookiePreferences);
  const { saveSettings, onAcceptAllCookies, loading } = useCookiePersistence(
    functionalToggled,
    advertisingToggled,
    onClose,
    originalCookiePreferences,
  );

  const showSelectedSection = () => {
    if (selectedSection === 'required') {
      return (
        <CookieTab
          headline={
            <>
              Required Cookies<StyledBodyHighlight>Always Active</StyledBodyHighlight>
            </>
          }
          body={REQUIRED_COOKIES.description}
          firstPartyCookies={REQUIRED_COOKIES.firstParty}
          thirdPartyCookies={REQUIRED_COOKIES.thirdParty}
          dataTestId="required-section"
        />
      );
    }
    if (selectedSection === 'functional') {
      return (
        <CookieTab
          headline={
            <>
              Functional Cookies
              <ToggleOutlet>
                <SliderToggle variant="new" toggled={functionalToggled} onToggle={toggleFunctional} />
              </ToggleOutlet>
            </>
          }
          body="Functional cookies help us enhance functions, performance, and services on the website. Examples include
          cookies used to analyze site traffic, cookies used for market research, and cookies used to display
            advertising that is not directed to a particular individual."
          cookies={FUNCTIONAL_COOKIES.providers}
        />
      );
    }
    return (
      <CookieTab
        headline={
          <>
            Advertising Cookies
            <ToggleOutlet>
              <SliderToggle variant="new" toggled={advertisingToggled} onToggle={toggleAdvertising} />
            </ToggleOutlet>
          </>
        }
        body={ADVERTISING_COOKIES.description}
        cookies={ADVERTISING_COOKIES.providers}
        dataTestId="advertising-section"
      />
    );
  };
  return (
    <StyledModal initialFocus=".accept-all" zIndex={ZIndex.Modal} size={ModalSize.Large} noBackground>
      <ModalSubhead>
        <StyledHeadline2 inverted>Cookie Preferences Manager</StyledHeadline2>
        <StyledHeadline3 inverted>Explore and manage cookie categories.</StyledHeadline3>
        <StyledSubtitle1 inverted>
          For more information, read the{' '}
          <a href="/api/file/tag/cookie-policy" target="_blank" rel="noopener noreferrer">
            Cookie Policy
          </a>
          .
        </StyledSubtitle1>
      </ModalSubhead>
      <StyledModalContent>
        <SideMenuOutlet>
          <SideMenu<CookieSection>
            darkTheme
            items={SideMenuItems}
            selectedItems={selectedSection}
            onClick={setSelectedSection}
          />
        </SideMenuOutlet>
        <StyledModalInnerContent>{showSelectedSection()}</StyledModalInnerContent>
      </StyledModalContent>

      <StyledModalFooter
        rightChildren={
          <>
            <StyledButton dense onClick={saveSettings} className="qa-save-settings">
              Save Settings
            </StyledButton>
            <Button data-testid="accept-all" className="accept-all" dense dominant onClick={onAcceptAllCookies}>
              Accept All Cookies
            </Button>
          </>
        }
      />
      {loading && (
        <LoadingOverlay>
          <Loading />
        </LoadingOverlay>
      )}
    </StyledModal>
  );
};

export default withSuspense(null, CookiePreferencesModal);

const StyledHeadline3 = styled(Headline3)`
  display: flex;
`;

const StyledHeadline2 = styled(Headline2)`
  text-transform: none;
  letter-spacing: initial;
  word-spacing: initial;
`;

const ToggleOutlet = styled.div`
  display: inline-flex;
  margin-left: 10px;
`;

const ModalCSS = css`
  background-color: rgba(16, 22, 27, 0.9);
`;

const SideMenuOutlet = styled.div`
  max-height: 127px;
  min-width: 170px;
`;

const StyledBodyHighlight = styled.span`
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-left: 10px;
  align-self: center;
  color: ${GetColor.HighlightLight};
`;

const StyledSubtitle1 = styled(Subtitle1)`
  a {
    text-decoration: none;
  }
`;

const StyledModal = styled(Modal)`
  &.modal-wrapper {
    ${ModalCSS};
    border: solid 1px ${GetColor.DarkGrey};

    ${StyledHeadline3},
    ${StyledHeadline2},
    ${StyledSubtitle1} {
      line-height: 1.3;
      margin: 5px 0;
    }
  }
`;

const LoadingOverlay = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${GetColor.White};
  color: ${GetColor.Black};
  z-index: ${ZIndex.ModalCover};
  align-content: center;
  justify-content: center;
  position: absolute;
  padding-top: 230px;
`;

const StyledModalInnerContent = styled(ModalContent)`
  max-width: 70%;
  height: 300px;
`;

const StyledModalContent = styled(ModalContent)`
  display: flex;
`;

const StyledModalFooter = styled(ModalFooter)`
  ${ModalCSS}
  box-sizing: border-box;
  border-top: solid 1px ${GetColor.DarkGrey};
`;

const StyledButton = styled(Button)`
  font-family: ${(props) => props.theme.Typography.fontFamily};
  border-color: ${GetColor.Primary.Main};
  background-color: transparent;
  color: ${GetColor.Primary.Main};
  align-self: ${isMobile() ? 'center' : 'flex-end'};

  &:hover {
    background-color: ${GetColor.Primary.Main};
    color: ${GetColor.Black};
  }
`;
