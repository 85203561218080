import React from 'react';
import { useRecoilValue } from 'recoil';
import { blockCustomizableMetricsMap, blockDisplayHeader, customizedBlock, isReportState } from 'venn-state';
import { Block } from '../../components/core';
import { useAppPrintMode } from '../../../print';
import { DefaultSubjects } from '../../components/secondary/subjects/default-subjects';
import { BenchmarkType } from '../../components/secondary/benchmark-type';

interface PrivateTimeSeriesHeaderProps {
  id: string;
}

export const PrivateTimeSeriesHeader = ({ id }: PrivateTimeSeriesHeaderProps) => {
  const { inPrintModeOrReportLab } = useAppPrintMode();

  const title = useRecoilValue(blockDisplayHeader(id));
  const { subHeader, selectedMetrics } = useRecoilValue(customizedBlock(id));
  const isReport = useRecoilValue(isReportState);
  const metricDefinitionsMap = useRecoilValue(blockCustomizableMetricsMap(id));

  const hideBenchmark = selectedMetrics
    .map((metric) => metricDefinitionsMap[metric])
    .every((metricDefinition) => metricDefinition.needsBenchmark !== true);

  return (
    <>
      <Block.HeaderTopContainer inPrintMode={inPrintModeOrReportLab}>
        <Block.Title>
          <Block.Headline id={id} isReport={isReport}>
            {title}
          </Block.Headline>
          {subHeader && <Block.SubHeadline id={id}>{subHeader}</Block.SubHeadline>}
        </Block.Title>
        <Block.Metadata id={id}>{!hideBenchmark && <BenchmarkType id={id} />}</Block.Metadata>
      </Block.HeaderTopContainer>

      <DefaultSubjects id={id} relativeToBenchmark={false} />
    </>
  );
};
