import type {
  CellClassFunc,
  CellRendererSelectorFunc,
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  ValueFormatterFunc,
  ValueGetterFunc,
} from 'ag-grid-community';
import { compact } from 'lodash';
import type { Scenario } from 'venn-api';
import { Dates, assertNotNil, getComparisonLabelForBlockLegend } from 'venn-utils';
import { BOLD_CLASS, ITALIC_CLASS, LEFT_ALIGN_CLASS } from '../customAnalysisContants';
import { isReturnsGridRow, isScenarioRow } from './typeUtils';
import ValueCellRenderer from '../components/grid/renderers/ValueCellRenderer';
import { getDefaultValueFormat, getDefaultCellClass, getSpecialClass } from './gridStyling';
import { BasicHeaderRenderer } from '../components/grid/renderers/BasicHeaderRenderer';
import HeaderCellRenderer from '../components/grid/renderers/HeaderCellRenderer';
import { getGroupColDef } from './getGroupColDef';
import type {
  ColumnParser,
  ScenarioRowData,
  HeaderComponentParamsType,
  CreateLabelColumnProps,
  MetricRowData,
} from '../types';
import { formatData } from '../../data-grid';
import ScenarioHeaderCellRenderer from '../components/grid/renderers/ScenarioHeaderCellRenderer';
import ScenarioHeaderGroupCellRenderer from '../components/grid/renderers/ScenarioHeaderGroupCellRenderer';
import ScenarioCombinedCellRenderer from '../components/grid/renderers/ScenarioCombinedCellRenderer';
import { convertByUnits, getUnitPrecision, getUnitSymbol } from '../../utils/scenario';
import ScenarioIndexCellRenderer from '../components/grid/renderers/ScenarioIndexCellRenderer';
import { formatExportableSubjectWithOptionalFee } from '../../legend';
import { measureGridText, measureHeader } from '../../utils';
import { isHistoricalSubject, type StudioAnalysisRequest } from 'venn-state';

// TODO: split this file into multiple files, it is getting too long and complicated.

export const metricsSettingsColumnParser: ColumnParser = ({
  requests,
  analysesGroup,
  metricsSettings,
  theme,
  selectedBlock,
  gridStyle,
}) => {
  const labelColumn = createLabelColumn({
    requests,
  });

  const groupColumns = getGroupColDef(
    requests,
    analysesGroup,
    metricsSettings,
    theme,
    gridStyle,
    selectedBlock?.relativeToBenchmark,
    selectedBlock?.contributionToPercentage,
    selectedBlock?.selectedMetrics,
    selectedBlock?.selectedFactors,
  );

  return [getGroupColumnLabel(labelColumn), ...groupColumns];
};

const getFormattedScenarioShock = (scenario: Scenario) => {
  const sign = scenario.shock > 0 ? '+' : '';
  const shockValue = parseFloat(convertByUnits(scenario.shock, scenario.units)).toFixed(
    getUnitPrecision(scenario.units),
  );
  const unitSymbol = getUnitSymbol(scenario.units);
  return `${sign}${shockValue} ${unitSymbol}`;
};

const scenarioCellRendererSelector: CellRendererSelectorFunc<ScenarioRowData> = ({ data }) => ({
  component: data && isScenarioRow(data) ? ScenarioCombinedCellRenderer : ValueCellRenderer,
});
const scenarioValueFormatter: ValueFormatterFunc<ScenarioRowData> = ({ value }) => formatData(value, 'PERCENTAGE', 1);
const scenarioLabelCellRendererSelector: CellRendererSelectorFunc<ScenarioRowData> = ({ data }) => ({
  component: data && isScenarioRow(data) ? ScenarioIndexCellRenderer : ValueCellRenderer,
});

const scenarioValueGetter: ValueGetterFunc<ScenarioRowData> = ({ data, colDef }) => {
  const index = Number(colDef.field);
  const predicted = data?.scenarioAnalysis?.[index]?.predicted;
  return predicted && !Number.isNaN(predicted) ? predicted : '--';
};
const scenarioReturnValueGetter: ValueGetterFunc<ScenarioRowData> = ({ data }) => {
  const scenario = data?.scenario;
  return scenario ? getFormattedScenarioShock(scenario) : '--';
};
const scenarioReturnValueFormatter: ValueFormatterFunc<ScenarioRowData> = ({ data }) => {
  const scenario = data?.scenario;
  return scenario ? getFormattedScenarioShock(scenario) : '--';
};

const scenarioLabelColumnCellParams = { style: { paddingLeft: '5px', textAlign: 'left', width: '100%' } };

const getAllShockStrings = (request: StudioAnalysisRequest | undefined) =>
  request?.scenarios?.map(getFormattedScenarioShock) ?? [];

export const scenarioColumnParser: ColumnParser<ScenarioRowData> = ({ requests, theme, gridStyle }) => {
  const scenarioReturn = {
    headerName: 'Shock Value',
    headerComponent: ScenarioHeaderCellRenderer,
    valueGetter: scenarioReturnValueGetter,
    valueFormatter: scenarioReturnValueFormatter,
    cellClass: getDefaultCellClass,
    resizable: false,
    minWidth: Math.max(
      ...getAllShockStrings(requests?.[0]).map((shock) => measureGridText(shock, 'normal', gridStyle, theme)),
      measureHeader('Shock Value', theme, gridStyle),
    ),
  };

  const labelColumn = getGroupColumnLabel({
    ...createLabelColumn({
      requests,
    }),
    headerName: 'Input',
    minWidth: 200,
    maxWidth: undefined,
    headerComponent: BasicHeaderRenderer,
    cellRendererParams: scenarioLabelColumnCellParams,
    headerComponentParams: {
      style: { textAlign: 'left', width: '100%', color: theme.Colors.Black },
    },
    cellRendererSelector: scenarioLabelCellRendererSelector,
    resizable: false,
  });
  labelColumn.children.push(scenarioReturn);

  const columns = requests?.map<ColDef>((req, index) => {
    const displayName = getComparisonLabelForBlockLegend(req.subject);
    const headerParams = {
      isScenarioSubjectsHeaderGroup: true,
      displayName,
      subject: req.subject,
      relativeTo: req.relative && req.benchmarkType === 'INDIVIDUAL' ? req.benchmark : undefined,
      isCommonBenchmark: req.isBenchmark,
      style: {
        borderTop: 'none',
      },
    };

    return {
      headerName: `${formatExportableSubjectWithOptionalFee(req.subject)}${
        req.benchmark && req.relative && req.benchmarkType === 'INDIVIDUAL' ? ` relative to ${req.benchmark.name}` : ''
      }`,
      resizable: false,
      isScenarioSubjectsHeaderGroup: true,
      headerGroupComponent: HeaderCellRenderer,
      headerComponent: HeaderCellRenderer,
      headerComponentParams: headerParams,
      headerGroupComponentParams: headerParams,
      field: String(index),
      valueGetter: scenarioValueGetter,
      valueFormatter: scenarioValueFormatter,
      cellRendererSelector: scenarioCellRendererSelector,
      minWidth: Math.max(
        measureGridText('888.8% ± 88.8', 'normal', gridStyle, theme),
        measureHeader(headerParams.displayName, theme, gridStyle),
      ),
      cellRendererParams: { subjectName: requests[index].subject.name, index, relative: requests[index].relative },
    };
  });

  const parentName = requests?.[0].relative ? 'Estimated Relative Return' : 'Estimated Return';

  const columnsMinWidthSum = columns.reduce((acc, col) => acc + (col.minWidth ?? 0), 0);
  const groupMinWidth = measureGridText(parentName, 'bold', gridStyle, theme);
  if (groupMinWidth > columnsMinWidthSum && columns.length > 0) {
    const column = assertNotNil(columns[0]);
    column.minWidth = (column.minWidth ?? 0) + groupMinWidth - columnsMinWidthSum;
  }

  const parentColumn: ColGroupDef = {
    marryChildren: true,
    headerName: parentName,
    headerGroupComponent: ScenarioHeaderGroupCellRenderer,
    headerGroupComponentParams: { style: { height: '20px', borderBottom: 'none' } },
    children: columns,
  };

  return [labelColumn, parentColumn];
};

const getCellRendererParams =
  (isHistorical, selectedMetrics: string[]) =>
  ({ data }: ICellRendererParams<MetricRowData>) => {
    const hasHistoricalError =
      isHistorical &&
      selectedMetrics.filter((m) => m.toLowerCase().includes('forecast')).length !== selectedMetrics.length &&
      data?.label.toLowerCase().includes('forecast');
    const message =
      'As forecast metrics for historical portfolios are based upon the latest allocations and thus use a different calculation, they cannot be displayed alongside other metrics on this block.';
    return {
      errors: hasHistoricalError ? [message] : [],
      errorTooltipContent: hasHistoricalError ? message : undefined,
    };
  };

export const defaultColumnParser: ColumnParser = ({ requests, selectedMetricIds }) => {
  const labelColumn = createLabelColumn({
    requests,
  });

  const columns: ColDef[] = requests.map((req, index) => {
    const headerName = formatExportableSubjectWithOptionalFee(req.subject);
    const headerComponentParams: HeaderComponentParamsType = {
      subject: req.subject,
      isCommonBenchmark: req.isBenchmark,
    };

    return {
      headerName,
      headerComponent: HeaderCellRenderer,
      headerComponentParams,
      field: `value.${index}`,
      valueFormatter: getDefaultValueFormat,
      cellRenderer: ValueCellRenderer,
      cellRendererParams: getCellRendererParams(isHistoricalSubject(req.subject), selectedMetricIds),
      cellClass: getDefaultCellClass,
    };
  });

  return [labelColumn, ...columns];
};

/**
 * Wraps the labelColumn in a group column for use alongside other group columns.
 *
 * If this isn't done, the spacing will be wrong for the other column headings, because the
 * labelColumn will only take up a single cell of space. This function makes it take up two cells of space.
 */
export function getGroupColumnLabel<T>(labelColumn: ColDef<T>): ColGroupDef<T> {
  return {
    headerName: '',
    children: [labelColumn],
  };
}

const labelCellClassFunc: CellClassFunc = ({ data }) =>
  compact([
    isReturnsGridRow(data) ? BOLD_CLASS : undefined,
    getSpecialClass(data.label),
    data.isMetadata ? ITALIC_CLASS : undefined,
    LEFT_ALIGN_CLASS,
  ]);
const labelValueGetter: ValueGetterFunc = ({ data }) => (data.date ? data.date : data.label);

export function createLabelColumn({ requests }: CreateLabelColumnProps): ColDef {
  const frequency = requests?.[0]?.frequency;

  return {
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    minWidth: 100,
    wrapHeaderText: true,
    suppressSizeToFit: true,
    valueGetter: labelValueGetter,
    valueFormatter: ({ data, value }) => (data.date ? Dates.toDDMMMYYYY(value, frequency) : value),
    cellClass: labelCellClassFunc,
  };
}
