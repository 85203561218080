import { useCallback, useLayoutEffect, useRef } from 'react';

type Fn<TArgs extends unknown[], TReturn> = (...args: TArgs) => TReturn;

/**
 * Returns a referentially stable version of the callback.
 * Callback invoked will always be the latest commited form of the callback including any enclosed values.
 *
 * Miniscule performance overhead over a truly static function due to useRef and useLayoutEffect.
 */
export const useEventCallback = <TArgs extends unknown[], TReturn>(fn: Fn<TArgs, TReturn>): Fn<TArgs, TReturn> => {
  const ref = useRef(fn);

  useLayoutEffect(() => {
    ref.current = fn;
  });

  return useCallback((...args: TArgs) => ref.current(...args), []);
};
