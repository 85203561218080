import { isNil } from 'lodash';
import moment from 'moment';
import React from 'react';
import { constSelector, useRecoilValue } from 'recoil';
import styled from 'styled-components';

import {
  hasProxyState,
  historicalAsOfDateSelector,
  isHistoricalSubject,
  isReportState,
  isReturnsBasedBlockState,
  type StudioRequestSubject,
  useCachedLoadableValue,
} from 'venn-state';
import { FootnoteSymbols, getBaseFee, getComparisonLabelForBlockLegend, hasFeesExcluded, Numbers } from 'venn-utils';
import { formatDateByFrequency } from '../../../../manage-data';
import { GetColor } from 'venn-ui-kit';

interface NameProps {
  id: string;
  subject: StudioRequestSubject;
}

const falseState = constSelector(false);

export const Name = ({ id, subject }: NameProps) => {
  const name = getComparisonLabelForBlockLegend(subject);
  const isReturnsBasedBlock = useRecoilValue(isReturnsBasedBlockState(id));
  const hasProxy = useCachedLoadableValue(isReturnsBasedBlock ? hasProxyState(subject.id) : falseState, false);
  const fee = getBaseFee(subject);
  const feesExcluded = hasFeesExcluded(subject);
  const isStudio = !useRecoilValue(isReportState);
  const historicalAsOfDate = useRecoilValue(historicalAsOfDateSelector(id));
  const showHistoricalAsOfDate = isHistoricalSubject(subject) && isStudio && !isNil(historicalAsOfDate);

  return (
    <Wrapper>
      <Container>{[name, hasProxy && <sup>{FootnoteSymbols.proxyUsage}</sup>]}</Container>
      {showHistoricalAsOfDate && (
        <HistoricalAsOfDate>
          {' '}
          (as of {formatDateByFrequency(moment(historicalAsOfDate).utc(true).valueOf(), 'DAILY')})
        </HistoricalAsOfDate>
      )}
      {!isReturnsBasedBlock || fee === 0 ? null : (
        <Fee>
          ({Numbers.safeFormatPercentage(fee)} Fee){feesExcluded && FootnoteSymbols.excludedInvestments}
        </Fee>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-width: 0;
  display: flex;
  align-content: center;
  gap: 4px;
`;

const Container = styled.span`
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Fee = styled.span`
  font-style: italic;
  font-weight: normal;
`;

const HistoricalAsOfDate = styled.span`
  font-weight: normal;
  color: ${GetColor.GreyScale.Grey80};
`;
