import { GetColor, Icon, ItemIcon, ItemType } from 'venn-ui-kit';
import React from 'react';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import {
  blockBenchmarkConfig,
  blockCustomizableMetricsMap,
  blockDoesNotSupportBenchmark,
  blockDoesNotSupportPrivateBenchmark,
  blockFonts,
  blockInfoGraphicType,
  blockLimitedRequestSubjects,
  blockSelectedPeerGroupId,
  blockSettings,
  type CustomFont,
  customizedBlock,
  getCustomFontSize,
  hasProxyState,
  isHistoricalSubject,
  isReportState,
  useCachedLoadableValue,
} from 'venn-state';
import { useRecoilValue } from 'recoil';
import {
  convertRequestSubjectToItemType,
  getUniqueKeyFromStudioRequestSubject,
  HistoricalPortfolioStudioHeaderTooltips,
  NameWithOptionalFee,
  PureNameWithOptionalFee,
  useAppPrintMode,
  useBlockId,
} from 'venn-components';
import {
  blockShowsInvestments,
  getRequestSubjectFromAnalysisSubject,
  hasDisabledBenchmark,
  hasDisabledIndividualBenchmark,
  hasDisabledRelativeToBenchmark,
  shouldForceHideBenchmark,
} from 'venn-utils';
import type { PortfolioCompare } from 'venn-api';

type SubjectsItemProps = {
  item: ItemType;
  children: React.ReactNode;
  className?: string;
};
type SubjectRowProps = {
  key?: React.Key;
  children: React.ReactNode;
};

export const SubjectFoundations = {
  Subjects: ({ children }) => {
    const blockId = useBlockId();
    const { inPrintModeOrReportLab } = useAppPrintMode();
    const blockSetting = useRecoilValue(blockSettings(blockId));
    const blockGraphicType = useRecoilValue(blockInfoGraphicType(blockId));
    const block = useRecoilValue(customizedBlock(blockId));
    const subjects = useRecoilValue(blockLimitedRequestSubjects(blockId));

    const { relativeToBenchmark, selectedMetrics } = block || {};

    const blockType = blockSetting?.customBlockType;

    const metric = selectedMetrics && selectedMetrics.length ? selectedMetrics[0] : '';
    const metricDefinitionsMap = useRecoilValue(blockCustomizableMetricsMap(blockId));
    const requiresBenchmark = metricDefinitionsMap[metric]?.needsBenchmark;

    const isBlockRelativeToBenchmark =
      relativeToBenchmark &&
      !hasDisabledBenchmark(blockType, blockGraphicType) &&
      !hasDisabledRelativeToBenchmark(blockType);

    const showBlockSubjects =
      (!inPrintModeOrReportLab || (inPrintModeOrReportLab && (isBlockRelativeToBenchmark || requiresBenchmark))) &&
      !isEmpty(subjects);
    const headerInfoFont = useRecoilValue(blockFonts.blockHeaderInfo(blockId));
    return (
      showBlockSubjects && (
        <Wrapper fontSize={headerInfoFont} data-testid="qa-block-subjects">
          {children}
        </Wrapper>
      )
    );
  },
  SubjectRow: ({ key, children }: SubjectRowProps) => {
    return <ItemPair key={key}>{children}</ItemPair>;
  },
  SubjectItem: ({ className, item, children }: SubjectsItemProps) => {
    return (
      <>
        <ItemIcon item={item} className={className} />
        {children}
      </>
    );
  },
  SubjectCustomIcon: ({ item, children }) => {
    return (
      <>
        {item}
        {children}
      </>
    );
  },
};

export const SubjectPrefabs = {
  CommonBenchmark: () => {
    const blockId = useBlockId();
    const blockSetting = useRecoilValue(blockSettings(blockId));
    const block = useRecoilValue(customizedBlock(blockId));
    const benchmarkConfig = useRecoilValue(blockBenchmarkConfig(blockId));

    const { selectedMetrics } = block || {};

    const blockType = blockSetting?.customBlockType;

    const metricDefinitionsMap = useRecoilValue(blockCustomizableMetricsMap(blockId));

    const forceHideBenchmarks = shouldForceHideBenchmark(blockType, metricDefinitionsMap, selectedMetrics);
    const hideBenchmark = useRecoilValue(blockDoesNotSupportBenchmark(blockId)) || forceHideBenchmarks;
    const commonBenchmark = benchmarkConfig.subject;
    return (
      commonBenchmark &&
      !hideBenchmark && (
        <SubjectFoundations.SubjectRow>
          <SubjectFoundations.SubjectItem item={ItemType.CommonBenchmark}>
            <NameWithOptionalFee subject={getRequestSubjectFromAnalysisSubject(commonBenchmark)} isHeader />
          </SubjectFoundations.SubjectItem>
          <SeparatorIcon type="pipe" />
        </SubjectFoundations.SubjectRow>
      )
    );
  },
  Subjects: () => {
    const blockId = useBlockId();
    const blockSetting = useRecoilValue(blockSettings(blockId));
    const block = useRecoilValue(customizedBlock(blockId));
    const benchmarkConfig = useRecoilValue(blockBenchmarkConfig(blockId));

    const { selectedMetrics } = block || {};

    const blockType = blockSetting?.customBlockType;

    const metricDefinitionsMap = useRecoilValue(blockCustomizableMetricsMap(blockId));

    const forceHideBenchmarks = shouldForceHideBenchmark(blockType, metricDefinitionsMap, selectedMetrics);
    const subjects = useRecoilValue(blockLimitedRequestSubjects(blockId));
    const isStudio = !useRecoilValue(isReportState);

    const hideBenchmark = useRecoilValue(blockDoesNotSupportBenchmark(blockId)) || forceHideBenchmarks;
    const hidePrivateBenchmark = useRecoilValue(blockDoesNotSupportPrivateBenchmark(blockId)) || forceHideBenchmarks;
    const hideAllSubjects = !isStudio && blockSetting.customBlockType === 'SCENARIO';
    const trimmedSubjects = hideAllSubjects ? [] : subjects;
    const showInvestments = blockShowsInvestments(blockType);
    const individualBenchmarks =
      benchmarkConfig.type === 'INDIVIDUAL' && !hasDisabledIndividualBenchmark(blockSetting?.customBlockType);
    return trimmedSubjects.map((subject) => {
      return (
        (subject.subjectType !== 'INVESTMENT' ||
          (subject.subjectType === 'INVESTMENT' && (showInvestments === undefined || showInvestments))) && (
          <SubjectFoundations.SubjectRow key={getUniqueKeyFromStudioRequestSubject(subject)}>
            {subject.portfolioComparisonType === 'SAVED' ? (
              <SubjectFoundations.SubjectCustomIcon item={<Icon type="clock-rotate-left" />}>
                <NameWithOptionalFee subject={subject} isHeader />
              </SubjectFoundations.SubjectCustomIcon>
            ) : (
              <SubjectFoundations.SubjectItem item={convertRequestSubjectToItemType(subject)}>
                <NameWithOptionalFee subject={subject} isHeader />
                {isHistoricalSubject(subject) && isStudio && (
                  <HistoricalPortfolioStudioHeaderTooltips blockId={blockId} subject={subject} />
                )}
              </SubjectFoundations.SubjectItem>
            )}
            {individualBenchmarks &&
            !hideBenchmark &&
            (!subject.private || (subject.private && !hidePrivateBenchmark)) ? (
              <>
                <SeparatorIcon type="pipe" />
                <SubjectFoundations.SubjectItem item={ItemType.CommonBenchmark}>
                  {subject.individualBenchmark?.name ? (
                    <BenchmarkName compare={subject.individualBenchmark} />
                  ) : (
                    'No Benchmark'
                  )}
                </SubjectFoundations.SubjectItem>
              </>
            ) : null}
          </SubjectFoundations.SubjectRow>
        )
      );
    });
  },
  PeerGroupInformation: () => {
    const blockId = useBlockId();
    const peerGroupInformation = useRecoilValue(blockSelectedPeerGroupId(blockId));
    return (
      peerGroupInformation && (
        <SubjectFoundations.SubjectRow key={peerGroupInformation.categoryName}>
          <SubjectFoundations.SubjectItem item={ItemType.PeerGroup} className="far">
            <Name>{peerGroupInformation.categoryName}</Name>
          </SubjectFoundations.SubjectItem>
        </SubjectFoundations.SubjectRow>
      )
    );
  },
};

const BenchmarkName = ({ compare }: { compare: PortfolioCompare }) => {
  const hasProxy = useCachedLoadableValue(hasProxyState((compare?.fundId ?? compare?.portfolioId?.toString())!), false);
  return (
    <PureNameWithOptionalFee
      name={compare.name}
      fee={0}
      hasProxy={hasProxy}
      supportsFees={false}
      feesExcluded={false}
    />
  );
};

const ItemPair = styled.div`
  display: flex;
  column-gap: 5px;
  overflow-x: clip;
  /* Prevent superscripts from being cut off */
  overflow-y: visible;
  white-space: nowrap;
  align-items: center;
  min-width: 0;
`;

const SeparatorIcon = styled(Icon)`
  color: ${GetColor.Grey};
`;
const Wrapper = styled.div<{ fontSize: CustomFont | undefined }>`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${({ fontSize }) => (fontSize ? '0.6em' : '20px')};
  row-gap: ${({ fontSize }) => (fontSize ? '0.3em' : '10px')};
  align-items: center;
  ${({ fontSize }) => `font-size: ${fontSize ? getCustomFontSize(fontSize) : '11px'};`}
  max-width: 100%;
  padding: 0.5em 20px;
  border-bottom: 1px solid ${GetColor.Grey};
`;

const Name = styled.span`
  overflow-x: clip;
  /* Prevent superscripts from being cut off */
  overflow-y: visible;
  text-overflow: ellipsis;
`;
