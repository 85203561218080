import React, { memo } from 'react';

import { Block } from '../../components/core';
import { COMMON_ERROR_WRAPPERS } from '../../../studio-blocks/components/error-wrappers/common';
import { PrivateSubjectsErrorWrapper } from '../../../studio-blocks/components/error-wrappers/PrivateSubjectsErrorWrapper';
import { PrivateTimeSeriesHeader } from './private-time-series-header';
import { PrivateTimeSeriesContent } from './private-time-series-content';
import { PrivateMetricsErrorWrapper } from '../../../studio-blocks/components/error-wrappers/PrivateMetricsErrorWrapper';

interface PrivateTimeSeriesBlockProps {
  id: string;
}

export const PrivateTimeSeriesBlock = memo(function InternalPrivateTimeSeriesBlock({
  id,
}: PrivateTimeSeriesBlockProps) {
  return (
    <Block.Root id={id}>
      <Block.Header>
        <PrivateTimeSeriesHeader id={id} />
      </Block.Header>
      <Block.Content
        id={id}
        errorWrappers={[...COMMON_ERROR_WRAPPERS, PrivateSubjectsErrorWrapper, PrivateMetricsErrorWrapper]}
      >
        <PrivateTimeSeriesContent id={id} />
      </Block.Content>
    </Block.Root>
  );
});
