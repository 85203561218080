import type { ColDef, ColGroupDef } from 'ag-grid-community';
import { capitalize, compact, isNil, uniqBy } from 'lodash';
import type { Analysis, AnalysisParams, AnalysisResponse, AnalysisTypeEnum, Message, Scenario } from 'venn-api';
import { analysis } from 'venn-api';

import type { AnalysisResult, StudioAnalysis, StudioRequestSubject } from 'venn-state';
import { convertStudioSubjectToApiSubject } from 'venn-state';
import {
  type CategoryMetric,
  type ComputedDateRange,
  type CustomBlockTypeEnum,
  type CustomizableMetric,
  MAX_BOX_CHART_SUBJECTS,
  MAX_ROLLING_CORRELATION_SUBJECTS,
  MAX_SUBJECT_SIZE,
  requiresAnalysisRequest,
} from 'venn-utils';
import { getSubjectRange } from '../../analysis';
import type { DragStoppedEvent, ExcelStyle } from '../../data-grid';
import { BOLD_CLASS, ITALIC_CLASS, LEFT_ALIGN_CLASS, RIGHT_ALIGN_CLASS } from '../customAnalysisContants';
import type {
  AnalysesFetchingFunction,
  AnalysisFetchingFunction,
  AnalysisRequest,
  AnalysisResponseParser,
  ColumnParser,
} from '../types';
import { defaultColumnParser, metricsSettingsColumnParser, scenarioColumnParser } from './columnParsers';
import { parsePortfolioBreakdownExcel } from './excelParsers';
import {
  factorsParser,
  performanceSummaryParser,
  portfolioContributionParser,
  portfolioStrategyBreakdownParser,
  returnsDistributionParser,
  scenarioParser,
} from './parsers';

export const TRANSIENT_ERROR = -1982;

export const getAnalysesFetchingFunction = (customBlockType: CustomBlockTypeEnum): AnalysesFetchingFunction => {
  if (!requiresAnalysisRequest(customBlockType)) {
    return () => Promise.resolve([]);
  }

  switch (customBlockType) {
    case 'DISTRIBUTION':
      return fetchReturnsDistributionAnalysis;
    case 'CORRELATION':
      return fetchPairwiseAnalysis;
    case 'ROLLING_CORRELATION':
      return fetchRollingCorrelationAnalysis;
    case 'GROWTH_SIMULATION':
    case 'PUBLIC_PRIVATE_ASSET_GROWTH_BREAKDOWN':
    case 'PUBLIC_PRIVATE_ASSET_GROWTH_PERCENTILES':
      return fetchGrowthSimulationAnalysis;
    case 'PEER_GROUPS':
      return fetchPeerGroupAnalysis;
    default:
      return fetchAnalyses;
  }
};

function createFailedAnalysisResult(analysisParams: AnalysisParams[]) {
  return analysisParams.map(
    (a) =>
      ({
        analysisType: a.analysisType,

        message: {
          code: TRANSIENT_ERROR,
          type: 'ERROR',
          text: 'Error with the request, please try again.',
        },
      }) as Analysis,
  );
}

const fetchAnalysis: AnalysisFetchingFunction = async (req: AnalysisRequest) => {
  const {
    dateRange,
    analysesTypes,
    subject,
    benchmark,
    relative,
    frequency,
    rollingYears,
    selectedNotablePeriods,
    includeAllPredefinedPeriods,
    customTimeFrames,
    groupRootLevelInvestments,
    historicalPortfolioAsOfDate,
  } = req;

  const analysisParams: AnalysisParams[] = analysesTypes.map((type) => ({
    analysisType: type,
    fixedPeriods:
      type === 'PERFORMANCE_SUMMARY_HISTORICAL'
        ? ['YTD', 'FULL', 'YEAR_1', 'YEAR_3', 'YEAR_5', 'YEAR_10', 'YEAR_15', 'YEAR_20']
        : undefined,
    relative,
    rollingYears: getRollingYears(type, rollingYears),
    scenarios: req.scenarios ?? [],
    ...(type === 'NOTABLE_PERIODS'
      ? {
          selectedNotablePeriods,
          includeAllPredefinedPeriods,
          customTimeFrames,
        }
      : {}),
    historicalPortfolioAsOfDate,
  }));

  const apiSubject = convertStudioSubjectToApiSubject(subject, 'PRIMARY', true);
  const request = {
    analyses: analysisParams,
    subjects: compact([
      apiSubject,
      benchmark ? convertStudioSubjectToApiSubject(benchmark, 'BENCHMARK', false) : undefined,
    ]),
    start: dateRange.from,
    end: dateRange.to,
    frequency,
    groupRootLevelInvestments,
  };

  try {
    const { content } = await analysis(request);
    return getAnalysesWithSubjectErrors(content);
  } catch (e) {
    return createFailedAnalysisResult(analysisParams);
  }
};

const getAnalysesWithSubjectErrors = (response: AnalysisResponse): AnalysisResult => {
  const firstSubjectError = response?.subjectErrors?.find((e) => !!e);
  return response?.analyses.map((analysis) => ({
    ...analysis,
    globalError: analysis?.message ?? firstSubjectError,
  }));
};

export const fetchAnalyses: AnalysesFetchingFunction = (requests) =>
  Promise.all(requests.map((req) => fetchAnalysis(req)));

export const fetchGrowthSimulationAnalysis: AnalysesFetchingFunction = async (requests) => {
  if (requests.length === 0) {
    return [];
  }

  const {
    dateRange,
    analysesTypes,
    benchmark,
    frequency,
    forecastWindow,
    forecastConfidenceLevels,
    groupRootLevelInvestments,
    returnsBasedSubjectStartingNav,
    historicalPortfolioAsOfDate,
  } = requests[0];

  const analysisParams: AnalysisParams[] = analysesTypes.map((type) => ({
    analysisType: type,
    relative: false,
    forecastWindow,
    forecastConfidenceLevels,
    returnsBasedSubjectStartingNav,
    scenarios: [],
    historicalPortfolioAsOfDate,
  }));

  let apiSubjects = requests
    .filter(({ isBenchmark }) => !isBenchmark)
    .map(({ subject }, index) =>
      convertStudioSubjectToApiSubject(subject, index === 0 ? 'PRIMARY' : 'COMPARISON', true),
    );

  apiSubjects = apiSubjects.slice(0, MAX_BOX_CHART_SUBJECTS);

  const benchmarkSubject = benchmark ? convertStudioSubjectToApiSubject(benchmark, 'BENCHMARK', false) : undefined;

  const request = {
    analyses: analysisParams,
    subjects: compact([...apiSubjects, benchmarkSubject]),
    start: dateRange.from,
    end: dateRange.to,
    frequency,
    groupRootLevelInvestments,
  };

  try {
    const { content } = await analysis(request);
    return [content.analyses];
  } catch (e) {
    return [];
  }
};

export const fetchPeerGroupAnalysis: AnalysesFetchingFunction = async (requests) => {
  if (requests.length === 0) {
    return [];
  }

  const {
    dateRange,
    analysesTypes,
    benchmark,
    benchmarkType,
    frequency,
    relative,
    groupRootLevelInvestments,
    peerGroupIdentifier,
    historicalPortfolioAsOfDate,
  } = requests[0];

  const analysisParams: AnalysisParams[] = analysesTypes.map((type) => ({
    analysisType: type,
    relative,
    peerGroupIdentifier,
    scenarios: [],
    historicalPortfolioAsOfDate,
  }));

  const apiSubjects = requests
    .filter(({ isBenchmark }) => !isBenchmark)
    .map(({ subject }, index) =>
      convertStudioSubjectToApiSubject(subject, index === 0 ? 'PRIMARY' : 'COMPARISON', true),
    )
    .slice(0, MAX_SUBJECT_SIZE);

  const benchmarkSubject =
    benchmark && benchmarkType === 'COMMON'
      ? convertStudioSubjectToApiSubject(benchmark, 'BENCHMARK', false)
      : undefined;

  const request = {
    analyses: analysisParams,
    subjects: compact([...apiSubjects, benchmarkSubject]),
    start: dateRange.from,
    end: dateRange.to,
    frequency,
    groupRootLevelInvestments,
  };

  try {
    const { content } = await analysis(request);
    return [content.analyses];
  } catch (err) {
    const response: AnalysisResult[] = [
      [
        {
          globalError: {
            type: 'ERROR' as const,
            code: err?.content?.code,
            text: err?.content?.message,
          } as Message,
        } as unknown as StudioAnalysis,
      ],
    ];

    return response;
  }
};

export const fetchRollingCorrelationAnalysis: AnalysesFetchingFunction = async (requests) => {
  if (requests.length === 0) {
    return [];
  }

  const {
    dateRange,
    analysesTypes,
    relative,
    frequency,
    rollingYears,
    groupRootLevelInvestments,
    historicalPortfolioAsOfDate,
  } = requests[0];

  const analysisParams: AnalysisParams[] = analysesTypes.map((type) => ({
    analysisType: type,
    relative,
    scenarios: [],
    rollingYears: getRollingYears(type, rollingYears),
    historicalPortfolioAsOfDate,
  }));

  let apiSubjects = requests
    .filter(({ isBenchmark }) => !isBenchmark)
    .map(({ subject }, index) =>
      convertStudioSubjectToApiSubject(subject, index === 0 ? 'PRIMARY' : 'COMPARISON', true),
    );

  apiSubjects = apiSubjects.slice(0, MAX_ROLLING_CORRELATION_SUBJECTS);

  const benchmarkSubjects = uniqBy(compact(requests.map(({ benchmark }) => benchmark)), 'id').map((benchmark) =>
    convertStudioSubjectToApiSubject(benchmark, 'BENCHMARK', false),
  );

  const request = {
    analyses: analysisParams,
    subjects: [...apiSubjects, ...benchmarkSubjects],
    start: dateRange.from,
    end: dateRange.to,
    frequency,
    groupRootLevelInvestments,
  };

  try {
    if (dateRange.from && dateRange.to) {
      const { content } = await analysis(request);
      return [content.analyses];
    }
    return [];
  } catch (e) {
    return [createFailedAnalysisResult(analysisParams)];
  }
};

export const fetchReturnsDistributionAnalysis: AnalysesFetchingFunction = async (requests) => {
  if (requests.length === 0) {
    return [];
  }

  // The entire analysis uses the same date range, analysis types, relative setting, frequency, and benchmark type, so we can just pull those out of index 0.
  const {
    dateRange,
    analysesTypes,
    relative,
    frequency,
    benchmarkType,
    groupRootLevelInvestments,
    historicalPortfolioAsOfDate,
  } = requests[0];
  // Additionally, if the benchmark type is Common then every request must use that same common benchmark.
  const commonBenchmark = benchmarkType === 'COMMON' ? requests[0].benchmark : undefined;

  const apiSubjects = requests
    .filter(({ isBenchmark }) => !isBenchmark)
    .map(({ subject }, index) =>
      convertStudioSubjectToApiSubject(subject, index === 0 ? 'PRIMARY' : 'COMPARISON', true),
    );
  if (commonBenchmark) {
    apiSubjects.push(convertStudioSubjectToApiSubject(commonBenchmark, 'BENCHMARK', false));
  }

  const analysisParams: AnalysisParams[] = analysesTypes.map((type) => ({
    analysisType: type,
    // Distribution doesn't support analysis relative to an individual benchmark, only a common benchmark.
    relative: relative && !isNil(commonBenchmark),
    scenarios: [],
    historicalPortfolioAsOfDate,
  }));

  const request = {
    analyses: analysisParams,
    subjects: apiSubjects,
    start: dateRange.from,
    end: dateRange.to,
    frequency,
    groupRootLevelInvestments,
  };

  try {
    if (dateRange.from && dateRange.to) {
      const { content } = await analysis(request);
      return [content.analyses];
    }
    return [];
  } catch (e) {
    return [createFailedAnalysisResult(analysisParams)];
  }
};

export const fetchPairwiseAnalysis: AnalysesFetchingFunction = async (requests) => {
  if (requests.length === 0) {
    return [];
  }

  const { dateRange, analysesTypes, relative, frequency, groupRootLevelInvestments, historicalPortfolioAsOfDate } =
    requests[0];

  const analysisParams: AnalysisParams[] = analysesTypes.map((type) => ({
    analysisType: type,
    relative,
    scenarios: [],
    historicalPortfolioAsOfDate,
  }));

  // Remove duplicate benchmark
  const benchmarkSubjects = uniqBy(compact(requests.map(({ benchmark }) => benchmark)), 'id').map((benchmark) =>
    convertStudioSubjectToApiSubject(benchmark, 'BENCHMARK', false),
  );

  const apiSubjects = requests.map(({ subject }, index) =>
    convertStudioSubjectToApiSubject(subject, index === 0 ? 'PRIMARY' : 'COMPARISON', true),
  );

  const primarySubject = apiSubjects[0];

  const pairwiseCorrelationAnalysisRequest = {
    analyses: compact([analysisParams.find((params) => params.analysisType === 'PAIRWISE_CORRELATION')]),
    subjects: [...apiSubjects, ...benchmarkSubjects],
    start: dateRange.from,
    end: dateRange.to,
    frequency,
    groupRootLevelInvestments,
  };

  const portfolioCorrelationAnalysisRequest = {
    analyses: compact([analysisParams.find((params) => params.analysisType === 'CORRELATION')]),
    subjects: [primarySubject, ...benchmarkSubjects],
    start: dateRange.from,
    end: dateRange.to,
    frequency,
    groupRootLevelInvestments,
  };

  const residualPortfolioCorrelationAnalysisRequest = {
    analyses: [
      {
        ...compact([analysisParams.find((params) => params.analysisType === 'CORRELATION')])[0],
        residual: true,
      },
    ],
    subjects: [primarySubject, ...benchmarkSubjects],
    start: dateRange.from,
    end: dateRange.to,
    frequency,
    groupRootLevelInvestments,
  };

  const pairwiseDownsideCorrelationAnalysisRequest = {
    analyses: compact([analysisParams.find((params) => params.analysisType === 'PAIRWISE_DOWNSIDE_CORRELATION')]),
    subjects: [...apiSubjects, ...benchmarkSubjects],
    start: dateRange.from,
    end: dateRange.to,
    frequency,
    groupRootLevelInvestments,
  };

  const portfolioDownsideCorrelationAnalysisRequest = {
    analyses: compact([analysisParams.find((params) => params.analysisType === 'DOWNSIDE_CORRELATION')]),
    subjects: [primarySubject, ...benchmarkSubjects],
    start: dateRange.from,
    end: dateRange.to,
    frequency,
    groupRootLevelInvestments,
  };

  /**
   * This is the only fetcher where we create all the requests and filter them down to the user requested metrics.
   * With metric filtering this fails as we create invalid requests.  Here we check that the requests are valid and only
   * perform the requests for which metrics are selected.
   *
   * TODO(hesham): convert this to support filtering to the specific selected metrics and update the CorrelationBlock and related components
   * to not query a global array that depends on the specific order and all correlations being queried.  Knowing the blockId here or selected
   * metrics being limited to one and passed in is enough for that to work.  A reworking of the results to put the results in a map could work
   * as well but would be different from every fetch we have in Studio/Report Lab
   */
  const analyses = [
    pairwiseCorrelationAnalysisRequest,
    portfolioCorrelationAnalysisRequest,
    residualPortfolioCorrelationAnalysisRequest,
    pairwiseDownsideCorrelationAnalysisRequest,
    portfolioDownsideCorrelationAnalysisRequest,
  ].map((r) => analysis(r));

  try {
    if (dateRange.from && dateRange.to) {
      // this order is determined according to CorrelationResultMap in @CorrelationBlock.tsx
      const analysisResults = await Promise.all(analyses);
      return analysisResults.map((result) => result.content.analyses);
    }
    return [];
  } catch (e) {
    return [createFailedAnalysisResult(analysisParams)];
  }
};

const isRollingAnalysisType = (type: AnalysisTypeEnum) => type.includes('ROLLING_');
const isFactorAnalysisType = (type: AnalysisTypeEnum) => type.includes('FACTOR_');

const getRollingYears = (type: AnalysisTypeEnum, rollingYears?: number): number | undefined => {
  if (isRollingAnalysisType(type)) return rollingYears || 1;
  if (isFactorAnalysisType(type) || isRollingAnalysisType(type)) return rollingYears || 0.5;
  return undefined;
};

export const getCustomGridProps = (
  metrics: CustomizableMetric[],
  subjects: StudioRequestSubject[],
  factors?: CategoryMetric[],
  blockType?: CustomBlockTypeEnum,
  scenarios?: Scenario[],
): AnalysisResponseParser => {
  switch (blockType) {
    case 'SCENARIO':
      return {
        parser: scenarioParser(scenarios || []),
        columnParser: scenarioColumnParser as ColumnParser<unknown>,
      };
    case 'PERFORMANCE_SUMMARY':
      return {
        parser: performanceSummaryParser(metrics),
        columnParser: defaultColumnParser,
      };
    case 'PORTFOLIO_BREAKDOWN':
      return {
        parser: portfolioStrategyBreakdownParser,
        excelParser: parsePortfolioBreakdownExcel,
        columnParser: defaultColumnParser,
      };
    case 'PORTFOLIO_CONTRIBUTION':
      return {
        parser: portfolioContributionParser(metrics),
        isTree: true,
        hasFactors: true,
        metricsSettings: metrics,
        // Tree grid does not use columnParser
        columnParser: () => [],
      };
    case 'FACTOR_CONTRIBUTION':
      return {
        parser: factorsParser(metrics, factors),
        hasFactors: true,
        metricsSettings: metrics,
        columnParser: metricsSettingsColumnParser,
      };

    case 'DISTRIBUTION':
      return {
        parser: returnsDistributionParser(metrics),
        columnParser: defaultColumnParser,
      };
    case 'NOTABLE_PERIODS':
    case 'RETURNS_GRID':
      // Notable Periods has been migrated to a new architecture which no longer uses the customBlockUtils parser architecture.
      // Returns grid has been migrated to a new architecture which no longer uses the customBlockUtils parser architecture.
      return {
        parser: () => [],
        columnParser: () => [],
      };
    default:
      return {
        parser: () => [],
        columnParser: defaultColumnParser,
      };
  }
};

export const DATA_TYPE_EXCEL_STYLES: ExcelStyle[] = [
  {
    id: 'NUMERIC',
    numberFormat: {
      format: '0.00',
    },
  },
  {
    id: 'PERCENTAGE',
    numberFormat: {
      format: '0.00%',
    },
  },
  {
    id: 'header',
    font: {
      bold: true,
    },
  },
  {
    id: BOLD_CLASS,
    font: {
      bold: true,
    },
  },
  {
    id: ITALIC_CLASS,
    font: {
      italic: true,
    },
  },
  {
    id: LEFT_ALIGN_CLASS,
    alignment: {
      horizontal: 'Left',
    },
  },
  {
    id: RIGHT_ALIGN_CLASS,
    alignment: {
      horizontal: 'Right',
    },
  },
];

export const getAvailableRangeValue = (map: { [key in string | number]: ComputedDateRange }, subjectIds: string[]) =>
  subjectIds.map((id) => {
    const availableRange = map[id];
    return getSubjectRange(availableRange?.maxRange.from, availableRange?.maxRange.to, availableRange?.frequency);
  });

export const getMaxFrequencyValue = (map: { [key in string | number]: ComputedDateRange }, subjectIds: string[]) =>
  subjectIds.map((id) => {
    const availableRange = map[id];
    return capitalize(availableRange.frequency);
  });

export const getBenchmarkNameRangeMap = (
  map: { [key in string | number]: ComputedDateRange },
  requests: AnalysisRequest[],
) => {
  const benchmarkNameRangeMap = {};
  requests.forEach((request) => {
    if (request.benchmark?.id) {
      const range = map[request.benchmark.id];
      benchmarkNameRangeMap[request.benchmark.name] = {
        start: range.range.from,
        end: range.range.to,
        frequency: range.frequency,
      };
    }
  });
  return benchmarkNameRangeMap;
};

export const handleMetricsReorder = (
  e: DragStoppedEvent,
  onReorderMetrics: (newMetricsOrder: string[]) => void,
  columnDefs: (ColDef | ColGroupDef)[],
) => {
  const getChangedColumn = e.api.getColumnDefs()?.find((column, colIndex) => {
    if ('children' in column) {
      return (
        column.children.findIndex((metric, index) => {
          const defaultMetricsOrderArray = columnDefs[colIndex];
          if ('children' in defaultMetricsOrderArray) {
            return metric.headerName !== defaultMetricsOrderArray.children[index].headerName;
          }
          return false;
        }) !== -1
      );
    }
    return false;
  });

  if (getChangedColumn && 'children' in getChangedColumn) {
    const newMetricsOrder = getChangedColumn.children.map((c) => {
      if ('headerComponentParams' in c) {
        return c.headerComponentParams.metricKey;
      }
      return undefined;
    });
    onReorderMetrics(compact(newMetricsOrder));
  }
};
