import React, { useContext } from 'react';
import type { AnalysisSubject } from 'venn-utils';
import { convertSeriesToExcel, FundUtils, MANAGE_DATA_SECTION, useHasFF } from 'venn-utils';
import type { UseMetaDataReturn, UserData } from 'venn-components';
import {
  DeprecatedBulkManagementTable,
  BulkManagementTable,
  EmptyState,
  InvestmentInformation,
  UserContext,
} from 'venn-components';
import { TablePlaceholder } from 'venn-ui-kit';
import InvestmentDataEditor from './investment/InvestmentDataEditor';
import InvestmentPortfolios from './investment/InvestmentPortfolios';

interface InvestmentManageDataProps {
  subject?: AnalysisSubject;
  onFundUpdated: () => void;
  selectedSection: MANAGE_DATA_SECTION;
  useMetaDataReturn: UseMetaDataReturn;
  userData?: UserData;
  updateUserData: (newUserData: UserData) => void;
  /**
   * If true displays missing return message instead of the table in the {@link MANAGE_DATA_SECTION.DATA} section.
   */
  missingReturns?: boolean;
  /**
   * Callback assigned to the missing returns text button.
   */
  openUploadModal?: () => void;
}

const InvestmentManageData = ({
  subject,
  onFundUpdated,
  selectedSection,
  useMetaDataReturn,
  userData,
  updateUserData,
  missingReturns = false,
  openUploadModal,
}: InvestmentManageDataProps) => {
  const { hasPermission } = useContext(UserContext);
  const Table = useHasFF('portfolio_bulk_proxy_ff') ? BulkManagementTable : DeprecatedBulkManagementTable;

  if (subject?.type === 'portfolio') {
    return null;
  }

  const dataNoReturnsMessage = 'This investment does not have any performance data';

  const missingReturnsManageDataEmptyState = FundUtils.isPerformanceEditable(subject?.fund) ? (
    <EmptyState header={dataNoReturnsMessage} actionLabel="Upload now" onAction={openUploadModal} />
  ) : (
    <EmptyState header={dataNoReturnsMessage} />
  );

  return (
    <>
      {selectedSection === MANAGE_DATA_SECTION.INVESTMENT_INFORMATION && subject?.fund && (
        <InvestmentInformation
          fund={subject.fund}
          useMetaDataReturn={useMetaDataReturn}
          onFundUpdated={onFundUpdated}
          canEditForecasts={hasPermission('EDIT_FORECASTS')}
        />
      )}
      {selectedSection === MANAGE_DATA_SECTION.ANALYSIS_RANGE && subject && (
        <Table
          subject={subject}
          onFundUpdated={onFundUpdated}
          canEditForecasts={hasPermission('EDIT_FORECASTS')}
          canEditProxies={hasPermission('MANAGE_PROXY')}
        />
      )}
      {selectedSection === MANAGE_DATA_SECTION.DATA &&
        (missingReturns
          ? missingReturnsManageDataEmptyState
          : subject?.fund &&
            userData && (
              <InvestmentDataEditor
                fund={subject.fund}
                userData={userData}
                onFundUpdated={onFundUpdated}
                onReturnsUpdated={(updatedSeries) => {
                  updateUserData({
                    ...userData,
                    data: convertSeriesToExcel(subject.name, updatedSeries)!,
                  });
                  onFundUpdated();
                }}
              />
            ))}
      {selectedSection === MANAGE_DATA_SECTION.DATA && !userData && !missingReturns && (
        <TablePlaceholder columns={2} rows={10} />
      )}
      {selectedSection === MANAGE_DATA_SECTION.PORTFOLIOS && (
        <InvestmentPortfolios fundId={subject?.fund?.id} fund={subject?.fund} />
      )}
    </>
  );
};

export default InvestmentManageData;
