import React, { memo } from 'react';

import { COMMON_ERROR_WRAPPERS } from '../../../studio-blocks/components/error-wrappers/common';
import { PrivateSubjectsErrorWrapper } from '../../../studio-blocks/components/error-wrappers/PrivateSubjectsErrorWrapper';
import { PrivatePerformanceSummaryHeader } from './private-performance-summary-header';
import { Block } from '../../components/core';
import { PrivatePerformanceSummaryContent } from './private-performance-summary-content';
import { PrivateMetricsErrorWrapper } from '../../../studio-blocks/components/error-wrappers/PrivateMetricsErrorWrapper';

interface PrivatePerformanceSummaryBlockProps {
  id: string;
}

export const PrivatePerformanceSummaryBlock = memo(function InternalPrivatePerformanceSummaryBlock({
  id,
}: PrivatePerformanceSummaryBlockProps) {
  return (
    <Block.Root id={id}>
      <Block.Header>
        <PrivatePerformanceSummaryHeader id={id} />
      </Block.Header>
      <Block.Content
        id={id}
        errorWrappers={[...COMMON_ERROR_WRAPPERS, PrivateSubjectsErrorWrapper, PrivateMetricsErrorWrapper]}
      >
        <PrivatePerformanceSummaryContent id={id} />
      </Block.Content>
    </Block.Root>
  );
});
