import type { AnalysisSubject } from 'venn-utils';
import { createGetFundKey, useQuery, logExceptionIntoSentry, createGetPortfolioKey, VennQueryClient } from 'venn-utils';
import { getFund, getSpecificPortfolioV3, type Fund, type Portfolio } from 'venn-api';
import { useMemo } from 'react';

type QueryOptions = {
  suspense?: boolean;
};

export const prefetchBenchmarkQuery = (subject: AnalysisSubject) => {
  return VennQueryClient.getInstance().prefetchQuery(createQuery(subject));
};

type SubjectBenchmarkInfo =
  | {
      type: 'portfolio';
      benchmark: Portfolio;
      isLoading: boolean;
    }
  | {
      type: 'fund';
      benchmark: Fund;
      isLoading: boolean;
    }
  | {
      type: null;
      benchmark: undefined | null;
      isLoading: boolean;
    };

/**
 * Fetches the benchmark for the given subject.
 *
 * If the benchmark is a fund, the type will be 'fund'. If a portfolio, the type will be 'portfolio'.
 */
export const useBenchmarkQuery = (subject: AnalysisSubject, options?: QueryOptions): SubjectBenchmarkInfo => {
  const { data: benchmark, isLoading } = useQuery(createQuery(subject, options));

  return useMemo(
    () =>
      !benchmark
        ? { type: null, benchmark, isLoading }
        : isPortfolio(benchmark)
          ? { type: 'portfolio', benchmark, isLoading }
          : { type: 'fund', benchmark, isLoading },
    [benchmark, isLoading],
  );
};

function createQuery(subject: AnalysisSubject, { suspense = false }: QueryOptions = {}) {
  const benchmarkId = subject.activeBenchmarkId;
  return {
    staleTime: 5 * 60 * 1000,
    enabled: !!benchmarkId,
    suspense,
    queryKey:
      typeof benchmarkId === 'string'
        ? createGetFundKey(benchmarkId)
        : typeof benchmarkId === 'number'
          ? createGetPortfolioKey(benchmarkId)
          : ['disabled', benchmarkId],
    queryFn: async () => {
      try {
        const resultPromise =
          typeof benchmarkId === 'string'
            ? getFund(benchmarkId)
            : typeof benchmarkId === 'number'
              ? getSpecificPortfolioV3(benchmarkId)
              : Promise.resolve(null);

        return (await resultPromise)?.content ?? null;
      } catch (e) {
        logExceptionIntoSentry(e);
        return null;
      }
    },
  };
}

function isPortfolio(benchmark: Fund | Portfolio): benchmark is Portfolio {
  return typeof benchmark.id === 'number';
}
