import type { ErrorWrapper } from './errorWrapperTypes';
import { useRecoilValue } from 'recoil';
import { blockInfoGraphicType, blockMetrics, blockRequestSubjects, blockSettings } from 'venn-state';
import { usePrivateAnalysis } from '../../logic/usePrivateAnalysis';
import { usePrivatesBenchmarkMetricError } from '../../logic/usePrivatesBenchmarkMetricError';
import StyledEmptyState from '../StyledEmptyState';
import { isEmpty, isNil } from 'lodash';
import PrivatesErrorMessages from '../PrivatesErrorMessages';
import React from 'react';

export const PrivateMetricsErrorWrapper: ErrorWrapper = ({ blockId, children }) => {
  const blockSetting = useRecoilValue(blockSettings(blockId));
  const customBlockType = blockSetting.customBlockType;
  const infoGraphicType = useRecoilValue(blockInfoGraphicType(blockId));
  const subjects = useRecoilValue(blockRequestSubjects(blockId));
  const selectedMetrics = useRecoilValue(blockMetrics(blockId));

  const { data } = usePrivateAnalysis(blockId);

  usePrivatesBenchmarkMetricError();

  if (subjects.length === 0) {
    return (
      <StyledEmptyState
        header="Please select a Private Portfolio or Private Investment to analyze"
        selectedRefId={blockId}
      />
    );
  }

  if (selectedMetrics.length === 0) {
    return <StyledEmptyState header="Please select at least one metric to analyze" selectedRefId={blockId} />;
  }

  if (
    selectedMetrics.length !== 1 &&
    customBlockType === 'PRIVATE_PERFORMANCE_TIME_SERIES' &&
    infoGraphicType !== 'GRID'
  ) {
    return <StyledEmptyState header="Please select only one metric to analyze" selectedRefId={blockId} />;
  }
  if (isNil(data)) {
    return <StyledEmptyState header="Unknown error occurred. Please contact Venn Support" selectedRefId={blockId} />;
  }

  // detect critical errors in a cash flow chart
  // this uses legacy error handling, ultimately we'd like to deprecate .error and .errorMessages
  // only using the improved error handling using metric-specific and severity-sensitive "errors" dictionary
  if (customBlockType === 'PRIVATE_CASH_FLOW') {
    const firstSubjectErrorMessages = data?.cashFlows?.[0]?.errorMessages;
    const firstSubjectErrorMessage = data?.cashFlows?.[0]?.error;

    if (subjects.length === 1 || infoGraphicType === 'PRIVATE_CASH_FLOW') {
      if (!isEmpty(firstSubjectErrorMessages)) {
        return <PrivatesErrorMessages errorMessages={firstSubjectErrorMessages} />;
      }

      if (firstSubjectErrorMessage) {
        return <StyledEmptyState header={firstSubjectErrorMessage} selectedRefId={blockId} />;
      }
    }
  }

  return children;
};
