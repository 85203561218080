import React, { useEffect, useState, useCallback, useContext } from 'react';
import styled, { css } from 'styled-components';
import { isEmpty, noop } from 'lodash';
import { SelectTypes, CheckboxWrapper, GetColor, Icon, Button, Tooltip, TooltipPosition } from 'venn-ui-kit';

import { FiltersContext } from './FiltersProvider';
import QuickFilterBar from './QuickFilterBar';
import { ExpandHeaderCell, HeaderCell } from './shared';
import type { SearchFilter, SearchMenuItem, CustomMultiSelectProps } from '../types';
import { MenuListComponents } from './MenuListComponents';

const SelectMenuList = SelectTypes.components.MenuList;

const MultiSelectMenuList = (props: SelectTypes.MenuListProps<SearchMenuItem, true>) => {
  const { setSelectedFilters, selectedFilters } = useContext(FiltersContext);
  const [isChecked, setIsChecked] = useState(false);

  const { options } = props;
  const {
    columns,
    loading,
    onClear = noop,
    onSelectAllWithTags,
    handleOnSelected,
    onUnselectSelectAllWithTags,
    investmentsOnly,
    portfoliosOnly,
    maxSelections,
    smallScreen,
    menuTrailingContent,
    footer: customFooter,
    includeTags,
    includeBenchmarks,
    privateAssetSearchMode,
    shouldLimitSize,
  } = props.selectProps as unknown as CustomMultiSelectProps;

  const selectProps = props.selectProps as unknown as CustomMultiSelectProps;

  const hasSaved = (options as SearchMenuItem[]).some((item) => item.category === 'saved');
  const hasRecent = (options as SearchMenuItem[]).some((item) => item.category === 'recent');
  const header = hasRecent ? 'Recent ' : hasSaved ? 'Saved Benchmarks ' : 'Results ';

  const selectedValues = selectProps.selections.filter((selection: SearchMenuItem) =>
    isEmpty(selection.searchResult?.tagIds),
  );

  const isMaxSelectionReached = maxSelections ? selectedValues.length >= maxSelections : false;

  const onSelectFilter = useCallback(
    (filter: SearchFilter) => {
      setSelectedFilters((prev) => [...prev, filter]);
    },
    [setSelectedFilters],
  );

  const handleOnChange = () => {
    if (isChecked) {
      setIsChecked(false);
      onUnselectSelectAllWithTags(options as unknown as SearchMenuItem[]);
      return;
    }

    setIsChecked(true);
    onSelectAllWithTags(options as unknown as SearchMenuItem[]);
  };

  const handleOnClearAll = () => {
    setIsChecked(false);
    onClear();
  };

  useEffect(() => {
    setIsChecked((prevIsChecked) => {
      if (prevIsChecked) {
        return false;
      }
      return prevIsChecked;
    });
  }, [loading]);

  const footer = customFooter ? (
    customFooter({ ...props })
  ) : (
    <Footer>
      <Help>
        Press <Icon prefix="fal" type="arrow-square-up" /> and <Icon prefix="fal" type="arrow-square-down" /> to move
        between items in the list
      </Help>
      <Tooltip
        content={isMaxSelectionReached ? 'Maximum number of selections reached' : ''}
        position={TooltipPosition.Left}
      >
        <div>
          <Button
            disabled={isEmpty(selectedValues) || isMaxSelectionReached}
            dense
            noMargin
            onClick={!isMaxSelectionReached ? handleOnSelected : noop}
            dominant
            data-testid="qa-add-selections"
          >
            ADD {selectedValues.length} {selectedValues.length === 1 ? 'SELECTION' : 'SELECTIONS'}
          </Button>
        </div>
      </Tooltip>
    </Footer>
  );

  const content = isEmpty(options) ? (
    props.children
  ) : (
    <>
      <TopWrapper>
        <QuickFilterBar
          privateAssetSearchMode={privateAssetSearchMode}
          selectedFilters={selectedFilters}
          onSelectFilter={onSelectFilter}
          investmentsOnly={investmentsOnly}
          portfoliosOnly={portfoliosOnly}
          smallScreen={smallScreen}
          includeTags={includeTags}
          includeBenchmarks={includeBenchmarks}
        />
        <SelectionsWrapper>
          <>
            <Selected>
              <strong>{selectedValues.length ?? 0}</strong> Selected
            </Selected>
            <StyledButton type="button" onClick={handleOnClearAll} disabled={!selectedValues.length}>
              CLEAR ALL
            </StyledButton>
          </>
        </SelectionsWrapper>
      </TopWrapper>
      <TableWrapper className="qa-multi-select-table" shouldLimitSize={shouldLimitSize}>
        <StyledTable>
          <MenuListComponents.HeaderRow>
            <CheckboxCell>
              <CheckboxWrapper checked={isChecked} onChange={handleOnChange} />
            </CheckboxCell>
            <MenuListComponents.FirstHeaderCell>{header}</MenuListComponents.FirstHeaderCell>
            <HeaderCell />
            <ExpandHeaderCell />
            <MenuListComponents.HeaderColumns columns={columns} />
          </MenuListComponents.HeaderRow>
          <tbody>{props.children}</tbody>
        </StyledTable>
        {footer}
      </TableWrapper>
    </>
  );

  return (
    <SelectMenuList {...props}>
      {content}
      {menuTrailingContent ? menuTrailingContent(maxSelections ? maxSelections - selectedValues.length : 0) : null}
    </SelectMenuList>
  );
};

export default MultiSelectMenuList;

const TableWrapper = styled(MenuListComponents.TableWrapper)<{ shouldLimitSize: boolean }>`
  ${({ shouldLimitSize }) =>
    shouldLimitSize &&
    css`
      overflow-y: auto;
      max-height: 236px;
    `}
`;

const TopWrapper = styled.div`
  border-bottom: 1px solid ${GetColor.Grey};
  margin-bottom: 20px;
  padding: 20px 20px 0 20px;
`;

const SelectionsWrapper = styled.div`
  margin-bottom: 15px;
`;

const Selected = styled.span`
  font-size: 13px;
  color: ${GetColor.DarkGrey};
`;

const StyledButton = styled.button`
  padding-left: 10px;
  font-size: 13px;
  font-weight: bold;
  color: ${GetColor.Error};

  &:hover {
    color: ${GetColor.Error};
  }
`;

const StyledTable = styled(MenuListComponents.Table)`
  tbody {
    tr {
      td.no-padding {
        padding-right: 0;
      }
      td:nth-child(1),
      td:nth-child(2) {
        padding-right: 5px;
      }
    }
  }
  thead {
    tr {
      th:nth-child(1) {
        width: 0px;
      }
      th:nth-child(1),
      th:nth-child(2),
      th:nth-child(3) {
        padding-right: 0;
      }
    }
  }
`;
const CheckboxCell = styled.th`
  display: flex;
  width: 16px;
  padding-left: 8px;
  label {
    width: 18px;
    min-width: auto;
    min-height: auto;
  }
`;

const Footer = styled.div`
  border-top: 1px solid ${GetColor.Grey};
  padding: 10px 20px 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    min-width: 140px;
    padding: 0 3px;
  }
`;

const Help = styled.div`
  line-height: 27px;
  font-size: 14px;
  color: ${GetColor.MidGrey1};
`;
