import type { ColDef, IRowNode } from 'ag-grid-community';
import { type BulkManageRow, type BulkManageUpdateFn } from '../types';
import { bulkManageSortFunction } from './bulkManageSortFunction';
import { ProxyCellRenderer, type ProxyCellRendererParams } from './ProxyCellRenderer';

export const getProxyColDef = (
  canEditProxies: boolean,
  onUpdate: BulkManageUpdateFn,
  openBulkProxyModal: (row: IRowNode<BulkManageRow>) => void,
): ColDef<BulkManageRow, string | undefined> => {
  return {
    flex: 2,
    minWidth: 160,
    maxWidth: 300,
    cellRendererSelector: ({ node }) => {
      return node.group || !node.data?.investment
        ? undefined
        : {
            component: ProxyCellRenderer,
            params: {
              canEditProxies,
              onUpdate,
              openBulkProxyModal,
            } satisfies ProxyCellRendererParams,
          };
    },
    headerName: 'Proxy',
    sortable: true,
    field: 'fundProxyInfo.proxyName',
    comparator: bulkManageSortFunction(),
  };
};
