import React from 'react';
import { useRecoilValue } from 'recoil';
import type { StudioAnalysisRequest } from 'venn-state';
import { blockSettings, customizedBlock } from 'venn-state';
import type { ReturnsGridRows } from './useReturnsGridData';
import { useReturnsGridData } from './useReturnsGridData';
import type { ReturnsGridProps } from './returnsGridParserHelper';
import { returnsGridParserHelper } from './returnsGridParserHelper';
import { useReturnsGridColumnDefs } from './useReturnsGridColumnDefs';
import { useCommonGridProps } from '../../../logic/columnUtils';
import { DATA_TYPE_EXCEL_STYLES } from '../../../logic/customBlockUtils';
import { useIsBlockGridCompact } from '../../../logic/useIsBlockGridCompact';
import type { AnalysisGridProps } from '../../../types';
import { analysisGridDefaultColDefOverrides } from '../../grid/AnalysisGrid';
import type { ExportableAnalysisGridProps } from '../../grid/ExportableGrid';
import ExportableGrid from '../../grid/ExportableGrid';
import { StyledErrorState } from '../../StyledErrorState';
import StyledEmptyState from '../../StyledEmptyState';
import type { ColDef } from 'ag-grid-community';
import { extractMetricReturnsFrequency } from 'venn-utils';
import { useSelectedMetrics } from '../../../logic/useSelectedMetrics';
import { useBlockId } from '../../../contexts/BlockIdContext';

// TODO(VENN-19715): all of the print and export props would ideally be pulled from recoil rather than drilled. Probably selectedMetrics as well.
type ReturnsGridBlockProps = Pick<
  AnalysisGridProps,
  'analysesGroup' | 'selectedRefId' | 'requests' | 'inPrintMode' | 'selectedMetricIds'
>;

export const ReturnsGridBlock = ({ analysesGroup, ...props }: ReturnsGridBlockProps) => {
  const blockId = useBlockId();
  const blockSetting = useRecoilValue(blockSettings(blockId));
  const selectedBlock = useRecoilValue(customizedBlock(blockId));
  const selectedMetrics = useSelectedMetrics();

  const returnsGridProps = returnsGridParserHelper({
    metrics: selectedMetrics,
    selectedBlock,
    analysesGroup,
  });

  if (returnsGridProps === 'NO_METRIC') {
    return (
      <StyledErrorState
        selectedRefId={blockId}
        header="Calendar returns are only available with 1 metric selected"
        message="Please select only 1 metric"
      />
    );
  }

  if (returnsGridProps === 'NO_ANALYSIS') {
    return (
      <StyledEmptyState
        selectedRefId={blockId}
        header="Unable to run analysis"
        message="Please check the block inputs and configuration"
      />
    );
  }

  // Backend may disable certain frequencies, and in those circumstances, we are not allowed to show the data to the client.
  const disabledFrequencies = analysesGroup?.[0]?.[0]?.returnsGrids?.[0]?.disabledFrequencies;
  if (
    disabledFrequencies?.includes(
      extractMetricReturnsFrequency(blockSetting.relativeToBenchmark, returnsGridProps.selectedMetric),
    )
  ) {
    return (
      <StyledErrorState
        selectedRefId={blockId}
        header="Unable to run analysis."
        message="Try selecting a different data frequency to view analysis."
      />
    );
  }

  return (
    <ReturnsGridBlockInternal
      {...props}
      returnsGridProps={returnsGridProps}
      exportable={analysesGroup?.[0]?.[0]?.exportable?.[0] ?? false}
    />
  );
};

const returnsGridBlockDefaultColDefExtras: ColDef<ReturnsGridRows> = {
  ...analysisGridDefaultColDefOverrides,
  // We shouldn't need to wrap text because we have pretty simple cell contents of just years and percentages.
  wrapText: false,
};

const ReturnsGridBlockInternal = ({
  requests,
  returnsGridProps,
  ...otherProps
}: Omit<ExportableAnalysisGridProps, 'isCompact' | 'selectedRefId' | 'columnDefs'> & {
  requests: StudioAnalysisRequest[];
  returnsGridProps: ReturnsGridProps;
}) => {
  const selectedRefId = useBlockId();
  const commonGridProps = useCommonGridProps(returnsGridBlockDefaultColDefExtras);

  // Note: Pivoting row data was neither implemented or required by spec, but if it becomes needed, check out how other grids do it.
  const rowData = useReturnsGridData(returnsGridProps);
  const isCompact = useIsBlockGridCompact();
  const columnDefs = useReturnsGridColumnDefs(rowData, requests, returnsGridProps);

  return (
    <ExportableGrid
      {...otherProps}
      {...commonGridProps}
      selectedRefId={selectedRefId}
      rowData={rowData}
      columnDefs={columnDefs}
      excelStyles={DATA_TYPE_EXCEL_STYLES}
      isCompact={isCompact}
      tooltipShowDelay={1200}
    />
  );
};
