import type {
  AppUser,
  Fund,
  ProxyTypeEnum,
  FrequencyEnum,
  ComputedInvestmentResidual,
  DetailedProxyMetadata,
} from 'venn-api';
import type { FundToBulkProxy } from '../modals/pickers/types';

export interface CreateUpdateMetadata {
  owner?: AppUser;
  created?: number;
  updatedBy?: AppUser;
  updated?: number;
}

export type CommonRangeData =
  | {
      earliestStart: number;
      latestEnd: number;
      fullRange: number;
      overlap: {
        percentageWidth: number;
        percentageStart: number;
      };
    }
  | undefined;

/** TODO(VER-816): we could use discriminated union to clean up the types here around the |undefined usage. */
export interface BulkManageRow extends CreateUpdateMetadata {
  name: string;
  /** Stable and globally unique ID for ag-grid usage. */
  rowId: string;
  /**
   * A unique path identifying a row, used by ag-grid to represent the hierarchy tree data.
   * Does not need to be human readable.
   */
  fullPath: string[];
  investment: FundToBulkProxy | undefined;
  fundProxyInfo: DetailedProxyMetadata | undefined;
  allocation: number | undefined;
  dataSource?: string;
  rangeLoading?: boolean;
  startDate: number | undefined;
  endDate: number | undefined;
  frequency: FrequencyEnum | undefined;
  /** Is this row not part of the main data set? i.e. factor range or the benchmark */
  secondary?: boolean;
  isStrategy?: boolean;
  isBenchmark?: boolean;
  proxyStartDate?: number;
  proxyEndDate?: number;
  extrapolateStartDate?: number;
  extrapolateEndDate?: number;

  /**
   * Range data common to the entire range column.
   * Passing this in as data enables column defs to be static for performance.
   *
   * Undefined while loading range data.
   */
  commonRangeData: CommonRangeData;
}

export interface OldBulkManageRow extends CreateUpdateMetadata {
  selected?: boolean;
  name: string;
  investment?: Fund;
  investmentId?: string;
  investmentLoading?: boolean;
  allocation?: number;
  dataSource?: string;
  rangeLoading?: boolean;
  startDate?: number;
  endDate?: number;
  frequency?: FrequencyEnum;
  proxyStartDate?: number;
  proxyEndDate?: number;
  proxyType?: ProxyTypeEnum;
  secondary?: boolean; // Is this row not part of the main data set? i.e. factor range
  isIntegration?: boolean;
  isBenchmark?: boolean;
  isStrategy?: boolean;
  isLive?: boolean;
  investmentForecast?: ComputedInvestmentResidual;
  investmentForecastLoading?: boolean;
  extrapolateStartDate?: number;
  extrapolateEndDate?: number;
}

export enum BulkManageAction {
  FUND_MODIFIED,
  INVESTMENT_FORECAST_MODIFIED,
}

export type BulkManageUpdateFn = (action: BulkManageAction, row: BulkManageRow, investmentId?: string) => Promise<void>;
export type OldBulkManageUpdateFn = (
  action: BulkManageAction,
  row: OldBulkManageRow,
  investmentId?: string,
) => Promise<void>;
