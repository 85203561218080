import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import type { Portfolio } from 'venn-api';
import { getUploadedSeriesForFunds } from 'venn-api';
import {
  analyticsService,
  convertMultipleSeriesDataToExcel,
  Dates,
  getPortfolioNavs,
  getFundsAndBenchmarksFromPortfolio,
  logExceptionIntoSentry,
  VennQueryClient,
} from 'venn-utils';
import { onExportExcel } from '../../downloadable/helper';
import type { OptionType } from 'venn-ui-kit';
import {
  ButtonIcon,
  LoadingSize,
  NotificationType,
  Notifications,
  Spinner,
  TriggerableMenu,
  ZIndex,
} from 'venn-ui-kit';
import SplitButtonDropdownItem from '../../split-button-dropdown/SplitButtonDropdownItem';
import { compact } from 'lodash';

interface PortfolioDownloadButtonProps {
  portfolio: Portfolio;
}

const PortfolioDownloadButton: React.FC<React.PropsWithChildren<PortfolioDownloadButtonProps>> = ({ portfolio }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [returnsStatus, setReturnsStatus] = useState<
    | {
        hasReturns: true;
        checking: false;
      }
    | {
        hasReturns: false;
        checking: boolean;
      }
  >({
    hasReturns: false,
    checking: false,
  });

  const { downloadReturns, prefetchReturns } = useReturnsDownload(portfolio);
  const downloadNAVs = useNavDownload(portfolio);

  /**
   * Checking to see if returns can be downloaded happens on the backend, so we do a pre-fetch and if we get data
   * back then that means we can download the returns.
   */
  const checkForReturns = useCallback(async () => {
    if (returnsStatus.checking || returnsStatus.hasReturns) return;
    setReturnsStatus({ hasReturns: false, checking: true });
    const returns = await prefetchReturns();
    setReturnsStatus({ hasReturns: !!returns?.length, checking: false });
  }, [returnsStatus, prefetchReturns]);

  const downloadOptions: OptionType[] = useMemo(() => {
    if (returnsStatus.checking) {
      return [
        {
          disabled: true,
          children: <SplitButtonDropdownItem label={<Spinner size={LoadingSize.micro} />} />,
        },
      ];
    }

    const downloadAllocationsOption = {
      children: <SplitButtonDropdownItem label="Download Investment Allocations" onClick={downloadNAVs} />,
    };
    const downloadReturnsOption = returnsStatus.hasReturns && {
      children: (
        <SplitButtonDropdownItem
          label="Download Investment Returns"
          onClick={() => {
            setIsLoading(true);
            downloadReturns().finally(() => setIsLoading(false));
          }}
        />
      ),
    };

    return compact([downloadAllocationsOption, downloadReturnsOption]);
  }, [downloadNAVs, downloadReturns, returnsStatus]);

  return (
    <div onMouseEnter={checkForReturns} onFocus={checkForReturns}>
      <StyledTriggerableMenu onToggle={checkForReturns} options={downloadOptions} innerClassName="download-menu">
        <ButtonIcon
          {...(isLoading
            ? { disabled: true, customIcon: <Spinner size={LoadingSize.micro} /> }
            : { iconType: 'download' })}
          tooltip="Download this portfolio's allocations or returns."
          border
        />
      </StyledTriggerableMenu>
    </div>
  );
};

const StyledTriggerableMenu = styled(TriggerableMenu)`
  .download-menu {
    right: 0;
    left: auto;
    z-index: ${ZIndex.Cover};
  }
`;

export default PortfolioDownloadButton;

function useReturnsDownload(portfolio: Portfolio) {
  const fetchReturnsData = useCallback(
    async () =>
      VennQueryClient.getInstance().fetchQuery({
        queryFn: async () => {
          try {
            const funds = getFundsAndBenchmarksFromPortfolio(portfolio);
            const { content } = await getUploadedSeriesForFunds(funds);
            if (!content || content.length === 0) {
              return null;
            }
            return convertMultipleSeriesDataToExcel(content);
          } catch (e) {
            logExceptionIntoSentry(e);
            return null;
          }
        },
        staleTime: 60 * 1000,
      }),
    [portfolio],
  );

  const downloadReturns = useCallback(async () => {
    const returnsData = await fetchReturnsData();
    if (returnsData) {
      await onExportExcel(returnsData, `${portfolio.name} Returns ${Dates.toYYYYMMDD(Number(new Date()), '-')}`);
      analyticsService.dataExported({
        objectType: 'portfolio',
        subjectId: String(portfolio.id),
        outputDescription: 'returns',
        outputFileType: 'xlsx',
        relativeToBenchmark: false,
        userUploaded: true,
      });
    } else {
      Notifications.notify('Failed to download returns. Please try again.', NotificationType.ERROR);
    }
  }, [fetchReturnsData, portfolio.id, portfolio.name]);

  return {
    downloadReturns,
    prefetchReturns: fetchReturnsData,
  };
}

function useNavDownload(portfolio: Portfolio) {
  return useCallback(() => {
    if (portfolio) {
      analyticsService.dataExported({
        objectType: 'portfolio',
        outputDescription: 'navs',
        outputFileType: 'xlsx',
        subjectId: String(portfolio.id),
        relativeToBenchmark: false,
        userUploaded: true,
      });
      onExportExcel(getPortfolioNavs(portfolio), `${portfolio.name} NAVs ${Dates.toYYYYMMDD(Number(new Date()), '-')}`);
    }
  }, [portfolio]);
}
