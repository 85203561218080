import React, { memo } from 'react';

import { Block } from '../../components/core';
import { COMMON_ERROR_WRAPPERS } from '../../../studio-blocks/components/error-wrappers/common';
import { PrivateSubjectsErrorWrapper } from '../../../studio-blocks/components/error-wrappers/PrivateSubjectsErrorWrapper';
import { PrivateAssetSummaryHeader } from './private-asset-summary-header';
import { PrivateAssetSummaryContent } from './private-asset-summary-content';

interface PrivateAssetSummaryBlockProps {
  id: string;
}

export const PrivateAssetSummaryBlock = memo(function InternalPrivateAssetSummaryBlock({
  id,
}: PrivateAssetSummaryBlockProps) {
  return (
    <Block.Root id={id}>
      <Block.Header>
        <PrivateAssetSummaryHeader id={id} />
      </Block.Header>
      <Block.Content id={id} errorWrappers={[...COMMON_ERROR_WRAPPERS, PrivateSubjectsErrorWrapper]}>
        <PrivateAssetSummaryContent id={id} />
      </Block.Content>
    </Block.Root>
  );
});
