import React, { memo } from 'react';

import { useRecoilValue } from 'recoil';

import { blockInfoGraphicType, blockSettings, isReportState } from 'venn-state';

import { useAnalysis } from '../../../studio-blocks';

import { useBlockContext } from '../../contexts/block-context';
import { assertNotNil, logExceptionIntoSentry } from 'venn-utils';
import { PublicPrivateAssetGrowthPercentilesChart } from '../../../studio-blocks/components/charts/PrivateAssetGrowthPercentilesChart';
import { PublicPrivateAssetGrowthGridBlock } from '../../../studio-blocks/components/blocks/public-private/PublicPrivateAssetGrowthGridBlock';
import { GridWrapper } from '../../../studio-blocks/common';
import { useAppPrintMode } from '../../../print';
import { PublicPrivateAssetGrowthBreakdownBlock } from '../../../studio-blocks/components/blocks/public-private';

interface PrivateAssetGrowthSimulationContentProps {
  id: string;
}

export const PrivateAssetGrowthSimulationContent = ({ id }: PrivateAssetGrowthSimulationContentProps) => {
  const isReport = useRecoilValue(isReportState);
  const { inPrintModeOrReportLab } = useAppPrintMode();
  const { blockRef: downloadableContentRef } = useBlockContext();
  const infoGraphicType = useRecoilValue(blockInfoGraphicType(id));
  const { analyses } = useAnalysis(id);
  const data = assertNotNil(analyses?.[0]?.[0]?.growthSimulationPublicPrivateResponse);
  const exportable = !!analyses?.[0]?.[0]?.exportable[0];
  const inPrintMode = inPrintModeOrReportLab || isReport;
  const customBlockSettings = useRecoilValue(blockSettings(id));

  if (infoGraphicType === 'GRID') {
    return (
      <Grid
        data={data}
        exportable={exportable}
        isReport={isReport}
        downloadableContentRef={downloadableContentRef}
        inPrintMode={inPrintMode}
      />
    );
  }
  switch (customBlockSettings.customBlockType) {
    case 'PUBLIC_PRIVATE_ASSET_GROWTH_PERCENTILES':
      return (
        <PercentilesChart
          downloadableContentRef={downloadableContentRef}
          inPrintMode={inPrintMode}
          exportable={exportable}
          data={data}
        />
      );
    case 'PUBLIC_PRIVATE_ASSET_GROWTH_BREAKDOWN':
      return (
        <BreakdownChart
          downloadableContentRef={downloadableContentRef}
          inPrintMode={inPrintMode}
          exportable={exportable}
          data={data}
        />
      );
    default:
      logExceptionIntoSentry('Invalid custom block type for public-private asset growth');
      return null;
  }
  return null;
};

type GridProps = React.ComponentProps<typeof PublicPrivateAssetGrowthGridBlock> & { isReport: boolean };

const Grid = memo(function InternalGrid({
  data,
  isReport,
  inPrintMode,
  exportable,
  downloadableContentRef,
}: GridProps) {
  return (
    <GridWrapper isReport={isReport}>
      <PublicPrivateAssetGrowthGridBlock
        data={assertNotNil(data)}
        exportable={exportable}
        inPrintMode={inPrintMode}
        downloadableContentRef={downloadableContentRef}
      />
    </GridWrapper>
  );
});

type ChartProps = React.ComponentProps<typeof PublicPrivateAssetGrowthPercentilesChart>;

const PercentilesChart = memo(function InternalChart({
  downloadableContentRef,
  inPrintMode,
  data,
  exportable,
}: ChartProps) {
  return (
    <PublicPrivateAssetGrowthPercentilesChart
      downloadableContentRef={downloadableContentRef}
      inPrintMode={inPrintMode}
      exportable={exportable}
      data={data}
    />
  );
});

const BreakdownChart = memo(function InternalChart({
  downloadableContentRef,
  inPrintMode,
  data,
  exportable,
}: ChartProps) {
  return (
    <PublicPrivateAssetGrowthBreakdownBlock
      downloadableContentRef={downloadableContentRef}
      inPrintMode={inPrintMode}
      exportable={exportable}
      data={data}
    />
  );
});
