import React, { useCallback, useState } from 'react';
import { Icon, LoadingSize, Notifications, NotificationType, Spinner } from 'venn-ui-kit';
import { plural, assertNotNil } from 'venn-utils';
import { removeFundProxy, type DetailedProxyMetadata } from 'venn-api';
import type { FundToBulkProxy } from '../modals/pickers/types';
import ConfirmationModal from '../modals/confirmation/ConfirmationModal';
import { partition } from 'lodash';

export const DeleteProxiesConfirmationModal = ({
  fundsWithProxy,
  proxyDataByFund,
  onCancel,
  onStartingUpdate,
  onFinishedUpdate,
}: {
  fundsWithProxy: FundToBulkProxy[];
  proxyDataByFund: Record<string, DetailedProxyMetadata | undefined>;
  onCancel: () => void;
  onStartingUpdate?: (updatingFunds: string[]) => void;
  onFinishedUpdate?: (updatedFundIds: string[], failedFundIds: string[]) => void;
}) => {
  const [isProxiesUpdating, setIsProxiesUpdating] = useState(false);

  const deleteProxies = useCallback(async () => {
    if (isProxiesUpdating) {
      return;
    }
    setIsProxiesUpdating(true);
    onStartingUpdate?.(fundsWithProxy.map((fund) => fund.id));
    try {
      type Result = Success | Failure;
      type Success = {
        id: string;
        status: 'success';
      };
      type Failure = {
        id: string;
        status: 'error';
        error: unknown;
      };

      const results: Result[] = await Promise.all(
        fundsWithProxy.map((investment) =>
          removeFundProxy(investment.id).then(
            () => ({ id: investment.id, status: 'success' as const }),
            (error) => ({ id: investment.id, status: 'error' as const, error }),
          ),
        ),
      );

      const isSuccess = (result: Result): result is Success => result.status === 'success';
      const [fulfilledResults, failedResults] = partition(results, isSuccess);

      onFinishedUpdate?.(
        fulfilledResults.map((result) => result.id),
        failedResults.map((result) => result.id),
      );

      const numSuccess = fulfilledResults.length;
      const numFail = failedResults.length;
      numSuccess > 0 && Notifications.notify(`Removed ${numSuccess} proxies successfully.`, NotificationType.SUCCESS);
      numFail > 0 && Notifications.notify(`Failed to remove ${numFail} proxies.`, NotificationType.ERROR);
    } catch (e) {
      onFinishedUpdate?.(
        [],
        fundsWithProxy.map((fund) => fund.id),
      );
      Notifications.notify('Failed to remove proxies.', NotificationType.ERROR);
    } finally {
      setIsProxiesUpdating(false);
    }
  }, [fundsWithProxy, isProxiesUpdating, onFinishedUpdate, onStartingUpdate]);

  return (
    <ConfirmationModal
      destructive
      header={plural(fundsWithProxy.length, {
        1: 'Remove proxy across your workspace?',
        other: 'Remove {{count}} proxies across your workspace?',
      })}
      text={`Note: removing ${
        fundsWithProxy.length > 1
          ? 'proxies'
          : `the proxy "${assertNotNil(proxyDataByFund[fundsWithProxy[0].id]).proxyName}" from "${fundsWithProxy[0].name}"`
      } will affect all portfolios that include the same investment.`}
      cancelLabel={
        <>
          <Icon type="angle-left" /> BACK
        </>
      }
      onCancel={isProxiesUpdating ? undefined : onCancel}
      proceedLabel={
        isProxiesUpdating ? (
          <Spinner size={LoadingSize.micro} />
        ) : (
          plural(fundsWithProxy.length, { 1: 'REMOVE PROXY', other: 'REMOVE PROXIES' })
        )
      }
      onProceed={deleteProxies}
      proceedDisabled={isProxiesUpdating}
    />
  );
};
