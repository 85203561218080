import React, { memo } from 'react';

import { Block } from '../../components/core';
import { PrivateCashFlowHeader } from './private-cash-flow-header';
import { COMMON_ERROR_WRAPPERS } from '../../../studio-blocks/components/error-wrappers/common';
import { PrivateCashFlowContent } from './private-cash-flow-content';
import { PrivateMetricsErrorWrapper } from '../../../studio-blocks/components/error-wrappers/PrivateMetricsErrorWrapper';
import { PrivateSubjectsErrorWrapper } from '../../../studio-blocks/components/error-wrappers/PrivateSubjectsErrorWrapper';

interface PrivateCashFlowBlockProps {
  id: string;
}

export const PrivateCashFlowBlock = memo(function InternalPrivateCashFlowBlock({ id }: PrivateCashFlowBlockProps) {
  return (
    <Block.Root id={id}>
      <Block.Header>
        <PrivateCashFlowHeader id={id} />
      </Block.Header>
      <Block.Content
        id={id}
        errorWrappers={[...COMMON_ERROR_WRAPPERS, PrivateMetricsErrorWrapper, PrivateSubjectsErrorWrapper]}
      >
        <PrivateCashFlowContent id={id} />
      </Block.Content>
    </Block.Root>
  );
});
