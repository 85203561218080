import React from 'react';
import { useRecoilValue } from 'recoil';

import DeveloperFallbackComponent from '../../../../error-boundary/DeveloperFallbackComponent';
import { withErrorBoundary } from '../../../../error-boundary/CustomizableErrorBoundary';
import { usePrivateAnalysis } from '../../../../studio-blocks/logic/usePrivateAnalysis';

import { isPrivatesPerformanceBlock, type CustomBlockTypeEnum, type DATE_BEHAVIOR_ENUM } from 'venn-utils';
import { blockDateRange, blockPrivateDefaultAsOfDateBehavior } from 'venn-state';
import type { InfoGraphicTypeEnum } from 'venn-api';

import { EndDateRange } from './end-date-range';
import { DefaultDateRange } from './default-date-range';

interface PrivateDateRangeProps {
  id: string;
  dateBehavior: DATE_BEHAVIOR_ENUM;
  blockGraphicType?: InfoGraphicTypeEnum;
  blockType: CustomBlockTypeEnum;
}

export const PrivateDateRange = withErrorBoundary(
  DeveloperFallbackComponent,
  ({ id, dateBehavior, blockType, blockGraphicType }: PrivateDateRangeProps) => {
    const { data } = usePrivateAnalysis(id);
    const dateRange = useRecoilValue(blockDateRange(id));
    const startAsOfLastTransaction = useRecoilValue(blockPrivateDefaultAsOfDateBehavior(id));

    // do not show projection start date for grid format of cash flow block
    if (blockType === 'PRIVATE_CASH_FLOW' && blockGraphicType === 'GRID') {
      return null;
    }
    // do not show as-of-date for PME block headers, since it is already incorporated inside the charts
    if (!blockType || isPrivatesPerformanceBlock(blockType)) {
      return null;
    }

    if (dateBehavior === 'END_DATE_RANGE') {
      return (
        <EndDateRange
          dateRange={dateRange}
          to={startAsOfLastTransaction ? data?.cashFlows?.[0]?.projectionAsOfDate : undefined}
          frequency="QUARTERLY"
          endDateRangePrefix="Projection Start: "
        />
      );
    }

    return (
      <DefaultDateRange
        dateRange={dateRange}
        to={startAsOfLastTransaction ? data?.cashFlows?.[0]?.projectionAsOfDate : undefined}
        frequency="QUARTERLY"
      />
    );
  },
);
