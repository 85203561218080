import React, { memo } from 'react';

import { useRecoilValue } from 'recoil';

import { blockInfoGraphicType, blockRequestSubjects, isReportState } from 'venn-state';

import { usePrivateAnalysis } from '../../../studio-blocks';

import type { PrivatesAnalysisResponse } from 'venn-api';
import { useBlockContext } from '../../contexts/block-context';
import { GridWrapper } from '../../../studio-blocks/common';
import { PrivateAssetPerformanceChart } from '../../../studio-blocks/components/charts/PrivateAssetPerformanceChart';
import { useSubjectColors } from '../../../studio-blocks/logic/useSubjectColors';
import { getAnalyzedSubjectFromRequestSubject } from 'venn-utils';
import { PrivatePerformanceGrid } from '../../../studio-blocks/components/grid/PrivatePerformanceGrid';

interface PrivateTimeSeriesContentProps {
  id: string;
}

export const PrivateTimeSeriesContent = ({ id }: PrivateTimeSeriesContentProps) => {
  const isReport = useRecoilValue(isReportState);
  const { blockRef: downloadableContentRef } = useBlockContext();
  const infoGraphicType = useRecoilValue(blockInfoGraphicType(id));
  const subjects = useRecoilValue(blockRequestSubjects(id));
  const subjectColors = useSubjectColors(subjects.map(getAnalyzedSubjectFromRequestSubject));

  const { data, isExportable } = usePrivateAnalysis(id);

  if (infoGraphicType === 'LINE' || infoGraphicType === 'BAR') {
    return (
      <Chart
        subjectColors={subjectColors}
        data={data!}
        isExportable={isExportable}
        downloadableContentRef={downloadableContentRef}
      />
    );
  }
  if (infoGraphicType === 'GRID') {
    return <Grid data={data!} isExportable={isExportable} isReport={isReport} />;
  }
  return null;
};

type GridProps = {
  data: PrivatesAnalysisResponse;
  isExportable: boolean;
  isReport: boolean;
};

const Grid = memo(function InternalGrid({ data, isReport, isExportable }: GridProps) {
  return (
    <GridWrapper isReport={isReport}>
      <PrivatePerformanceGrid isExportable={isExportable} data={data} />
    </GridWrapper>
  );
});

type ChartProps = React.ComponentProps<typeof PrivateAssetPerformanceChart>;

const Chart = memo(function InternalGrid({ data, isExportable, downloadableContentRef, subjectColors }: ChartProps) {
  return (
    <PrivateAssetPerformanceChart
      subjectColors={subjectColors}
      data={data}
      isExportable={isExportable}
      downloadableContentRef={downloadableContentRef}
    />
  );
});
