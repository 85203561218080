import React from 'react';
import type { ColDef, ICellRendererParams } from 'ag-grid-community';
import { Icon } from 'venn-ui-kit';
import type { BulkManageRow } from '../types';
import { bulkManageSortFunction } from './bulkManageSortFunction';
import { DisabledCellClass } from './column-styles';
import { FundUtils } from 'venn-utils';

type DataSourceValue = string | undefined;
const IntegrationDataSourceRenderer = ({ value }: ICellRendererParams<BulkManageRow, DataSourceValue>) => {
  if (!value) {
    return null;
  }

  return (
    <>
      {value} <Icon type="exchange" prefix="fas" />
    </>
  );
};
export const dataSourceColDef: ColDef<BulkManageRow, DataSourceValue> = {
  cellRendererSelector: ({ data }) =>
    FundUtils.isUserIntegration(data?.investment?.investmentSource)
      ? { component: IntegrationDataSourceRenderer }
      : undefined,
  field: 'dataSource',
  headerName: 'Data Source',
  comparator: bulkManageSortFunction(),
  cellClassRules: {
    [DisabledCellClass]: ({ data }) => data?.investment?.live === false,
  },
};
