import React, { useCallback, useState, useRef } from 'react';
import { ExternalActivityListener, Icon, Label, Tooltip, TooltipPosition, ZIndex } from 'venn-ui-kit';
import { LABEL_COLUMN_WIDTH } from './compareConstants';
import styled from 'styled-components';
import type { AnalysisSubject } from 'venn-utils';
import type { ItemId } from 'venn-api';
import type { SearchMenuItem } from '../../search-menu';
import { MultiSelectSearch } from '../../search-menu';
import { compact } from 'lodash';

interface AddSubjectButtonProps {
  onSubjectSelect: (subject: AnalysisSubject[]) => void;
  disabled: boolean;
  maxSelections: number;
  excludedItems: ItemId[];
  isOptionDisabled: (option: SearchMenuItem) => boolean;
  optionDisabledTooltipContent: (option: SearchMenuItem) => string;
}

const AddSubjectButton: React.FunctionComponent<React.PropsWithChildren<AddSubjectButtonProps>> = ({
  onSubjectSelect,
  disabled,
  maxSelections,
  excludedItems,
  isOptionDisabled,
  optionDisabledTooltipContent,
}) => {
  const [open, setOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const onSelected = useCallback(
    (selections: SearchMenuItem[]) => {
      const selectedItems = compact(selections.map((s) => s.value));
      setOpen(false);
      onSubjectSelect(selectedItems);
    },
    [onSubjectSelect],
  );

  const onBlur = useCallback(() => setOpen(false), []);
  return (
    <LabelColumnContainer ref={menuRef}>
      {!open ? (
        <Tooltip
          position={TooltipPosition.Top}
          content={<Label inverted>{!disabled ? 'Add another item to compare.' : 'Maximum items reached.'}</Label>}
        >
          <AddComparisonButton className="qa-add-new-item" onClick={() => setOpen(true)} disabled={disabled}>
            <ContentWrapper>
              <PlusIcon prefix="fal" type="plus-circle" />
              Add Comparison
            </ContentWrapper>
          </AddComparisonButton>
        </Tooltip>
      ) : (
        <MenuWrapper>
          <ExternalActivityListener onExternalActivity={() => setOpen(false)} listeningEnabled={open}>
            <MultiSelectSearch
              shouldLimitSize={false}
              onSelected={onSelected}
              onBlur={onBlur}
              excludedItems={excludedItems}
              boundingRect={menuRef?.current?.getBoundingClientRect?.()}
              autofocus
              optionDisabledTooltipContent={optionDisabledTooltipContent}
              isOptionDisabled={isOptionDisabled}
              location="addComparison"
              maxSelections={maxSelections}
              privateAssetSearchMode="PUBLIC_ONLY"
            />
          </ExternalActivityListener>
        </MenuWrapper>
      )}
    </LabelColumnContainer>
  );
};

const LabelColumnContainer = styled.div`
  min-width: ${LABEL_COLUMN_WIDTH}px;
  width: ${LABEL_COLUMN_WIDTH}px;
  min-height: 60px;
`;

const AddComparisonButton = styled.button`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PlusIcon = styled(Icon)`
  font-size: 35px;
  padding-right: 10px;
`;

const MenuWrapper = styled.div`
  position: absolute;
  width: 700px;
  z-index: ${ZIndex.Front};
`;

export default AddSubjectButton;
