import { Tooltip, TooltipContent, TooltipPortal, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import React from 'react';
import styled from 'styled-components';

export const FillParentTooltip = ({
  children,
  maxWidth,
  content,
}: {
  children?: React.ReactNode;
  maxWidth: number;
  content: React.ReactNode;
}) => {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={100}>
        <TooltipTrigger className="block h-full w-full">
          <div className="absolute h-full w-full">{children}</div>
        </TooltipTrigger>

        <TooltipPortal>
          <TooltipContent className="text-white bg-venn-black">
            <div style={{ maxWidth }}>{content}</div>
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  );
};

/** Simple cell with tooltip indicating content is empty due to no returns */
function getNoReturnsTooltippedCell(cellContent: React.ReactNode) {
  return (
    <FillParentTooltip maxWidth={240} content={<div>This item does not have a return stream.</div>}>
      {cellContent}
    </FillParentTooltip>
  );
}

/* Internal cell padding to trigger tooltips to show on no text */
const RangeBarTooltipTrigger = styled.div`
  height: 16px;
  width: 100%;
`;

export const emptyRangeTooltipCell = getNoReturnsTooltippedCell(<RangeBarTooltipTrigger />);
export const notApplicableTooltipCell = getNoReturnsTooltippedCell('N/A');
