import React from 'react';
import { useRecoilValue } from 'recoil';
import { blockBenchmarkRelativeRawValue, blockBenchmarkType, blockInfoGraphicType, blockMetrics } from 'venn-state';
import { StyledErrorState } from '../StyledErrorState';
import isNil from 'lodash/isNil';
import { useRequests } from '../../logic/useAnalysis';
import type { ErrorWrapper } from './errorWrapperTypes';
import { useDebugValue } from 'venn-utils';

export const CorrelationErrorWrapper: ErrorWrapper = ({ blockId, children }) => {
  const selectedMetricIds = useRecoilValue(blockMetrics(blockId));
  const selectedInfoGraphicType = useRecoilValue(blockInfoGraphicType(blockId));
  const requests = useRequests(blockId);
  const benchmarkType = useRecoilValue(blockBenchmarkType(blockId));
  const isRelativeToBenchmark = useRecoilValue(blockBenchmarkRelativeRawValue(blockId));
  const forceError = useDebugValue('debugBlockErrorState') === 'Correlation';

  const configureMetricsAndSubjectsMessage = 'Configure metrics and subjects in the block settings panel.';

  if (selectedInfoGraphicType === 'CORRELATION') {
    if (forceError || selectedMetricIds.length === 0) {
      return (
        <StyledErrorState
          selectedRefId={blockId}
          header="Select a metric and at least one subject"
          message={configureMetricsAndSubjectsMessage}
        />
      );
    }

    const maybeSubject = requests[0]?.subject;
    const subjectIsFund = maybeSubject?.subjectType === 'INVESTMENT' || !isNil(maybeSubject?.strategy?.fund);
    if (selectedMetricIds[0] === 'portfolioCorrelation' && subjectIsFund) {
      return (
        <StyledErrorState
          selectedRefId={blockId}
          header="Portfolio Correlation is only available for portfolios"
          message="Please make sure a portfolio is the top subject selected"
        />
      );
    }

    if (benchmarkType === 'INDIVIDUAL' && isRelativeToBenchmark) {
      return (
        <StyledErrorState
          selectedRefId={blockId}
          header="Unable to run analysis"
          message="Please use common benchmark or disable relative to benchmark setting"
        />
      );
    }
  }

  return children;
};
