import { analysis, type RangeDebugResponse } from 'venn-api';
import type { ApiSubject } from 'venn-state';
import { createRangeDebugKey, useQuery } from 'venn-utils';

export const useRangeDebug = (subject: ApiSubject): RangeDebugResponse | null | undefined => {
  const { data } = useQuery(
    createRangeDebugKey(subject),
    async ({ signal }) => {
      if (subject.isPrivate) {
        return null;
      }

      const { content } = await analysis(
        {
          analyses: [
            {
              analysisType: 'RANGE_DEBUG',
              relative: false,
              scenarios: [],
              includeAllPredefinedPeriods: false,
            },
          ],
          subjects: [subject],
        },
        signal,
      );
      return content.analyses[0].rangeDebug[0];
    },
    {
      suspense: true,
    },
  );

  return data;
};
