import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import styled from 'styled-components';
import type { Fund, Portfolio } from 'venn-api';
import { forceZeroResidualForecastSelector, forecastPanelViewSelector, ForecastTab } from 'venn-state';
import { FORECASTS_FAQ_HREF, GetColor, Headline2, Icon, Link } from 'venn-ui-kit';
import { assertExhaustive, recursiveGetFundIds, useHasFF } from 'venn-utils';
import CustomizableErrorBoundary from '../../error-boundary/CustomizableErrorBoundary';
import SidePanelOverlay from '../../side-panel-overlay/SidePanelOverlay';
import { FactorForecastPanelFooter } from './components/FactorForecastPanelFooter';
import ForecastsErrorView from './components/ForecastsErrorView';
import { InvestmentForecastPanelFooter } from './components/InvestmentForecastPanelFooter';
import { ForecastPanelActionsContext } from './contexts/ForecastPanelActionsContext';
import { InvestmentForecastPanelFooterActionsProvider } from './contexts/InvestmentForecastPanelFooterActionsContext';
import ForecastPanelHeaderContent from './ForecastPanelHeaderContent';
import { useForecastPanelFooter } from './hooks/useForecastPanelFooter';
import ForecastPanelContent from './views/ForecastPanelContent';

export interface ForecastPanelProps {
  onResidualForecastUpdated?: (fundId?: string) => void;
  residualForecastsSortFirst?: string[];
  showForecastsMenu: boolean;
  toggleForecastsMenu: (shouldShow: boolean) => void;
  portfolio?: Portfolio;
  fund?: Fund;
  onClose?: () => void;
  isReadOnly: boolean;
}

export const ForecastPanel = ({
  onResidualForecastUpdated,
  showForecastsMenu,
  toggleForecastsMenu,
  portfolio,
  fund,
  onClose,
  isReadOnly,
}: ForecastPanelProps) => {
  const hasForceZeroFF = useHasFF('residual_forecast_zero_ff');
  const forceZeroResidualForecast = useRecoilValue(forceZeroResidualForecastSelector);
  const [performScroll, setPerformScroll] = useState(false);
  const { changeTab } = useContext(ForecastPanelActionsContext);
  /** When closing the modal disable it from showing in the DOM and trigger any onClose callbacks of the parent */
  const onCloseWrapper = () => {
    toggleForecastsMenu(false);
    resetForecastPanelView();
    onClose?.();
  };

  /** If an analysis subject is provided, extract the ids of the funds contained within. The contents of the modal
   * will be sorted/visualised in different order based on this data. */
  const fundIds = useMemo(
    () => (fund ? [fund.id] : portfolio ? recursiveGetFundIds(portfolio) : undefined),
    [fund, portfolio],
  );

  const resetForecastPanelView = useResetRecoilState(forecastPanelViewSelector);

  const scrollToForceResidualOverride = useCallback(() => {
    changeTab({ tabId: ForecastTab.InvestmentForecast });
    setPerformScroll(true);
  }, [changeTab]);
  useEffect(() => {
    performScroll && document.getElementById('residual-forecast-override-panel')?.scrollIntoView(true);
    setPerformScroll(false);
  }, [performScroll]);

  const footerState = useForecastPanelFooter();
  const footer = (() => {
    switch (footerState.footerToShow) {
      case 'investment override':
        return <InvestmentForecastPanelFooter />;
      case 'factor override':
        return (
          <FactorForecastPanelFooter
            forecast={footerState.forecast}
            onResidualForecastUpdated={onResidualForecastUpdated}
          />
        );
      case 'none':
        return undefined;
      default:
        return assertExhaustive(footerState, 'unexpected footer state');
    }
  })();
  return (
    <InvestmentForecastPanelFooterActionsProvider onResidualForecastUpdated={onResidualForecastUpdated}>
      <SidePanelOverlay.Root
        className="qa-advanced-filters-side-panel"
        isOpen={showForecastsMenu}
        side="right"
        scrollAlignRight
        handleClose={onCloseWrapper}
        width={1062}
        margin={0}
        content={
          <ForecastPanelContentContainer data-testid="qa-forecasts-panel">
            <Row>
              <Headline2>Forecasts</Headline2>
              <ForecastLink>
                <a target="_blank" rel="noopener noreferrer" href={FORECASTS_FAQ_HREF}>
                  <Icon type="up-right-from-square" /> Learn about Forecasts
                </a>
              </ForecastLink>
            </Row>
            {hasForceZeroFF && forceZeroResidualForecast && (
              <Attention onClick={scrollToForceResidualOverride}>
                <Icon type="circle-exclamation" />
                Attention: All residual forecasts have been set to 0% unless overridden (see below for more)
              </Attention>
            )}
            <ForecastPanelHeaderContent />
            <CustomizableErrorBoundary FallbackComponent={ForecastsErrorView}>
              <ForecastPanelContent
                onResidualForecastUpdated={onResidualForecastUpdated}
                residualForecastsSortFirst={fundIds}
                isReadOnly={isReadOnly}
              />
            </CustomizableErrorBoundary>
          </ForecastPanelContentContainer>
        }
        footer={footer}
      />
    </InvestmentForecastPanelFooterActionsProvider>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Attention = styled(Row)`
  margin-right: 29px;
  margin-bottom: 10px;
  font-size: 20px;
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  background: ${GetColor.HighlightLightBackground};

  &:hover {
    cursor: pointer;
  }
`;

const ForecastLink = styled(Link)`
  font-size: 14px;
  margin-left: 23px;
`;

const ForecastPanelContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
