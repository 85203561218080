import { css } from 'styled-components';
import { GetColor } from 'venn-ui-kit';

export const RangeCellClass = 'range-cell';
export const DisabledCellClass = 'disabled-cell';

export const columnStyles = css`
  .${RangeCellClass} {
    padding: 0;
    border-width: 0;
    border-right-width: 1px;
  }

  .${DisabledCellClass} {
    color: ${GetColor.HintGrey};
  }
`;
