import type { ColDef, ICellRendererParams, SortDirection } from 'ag-grid-community';
import type { CustomHeaderProps } from 'ag-grid-react';
import React, { useEffect } from 'react';
import styled, { css, useTheme } from 'styled-components';
import type { FrequencyEnum } from 'venn-api';
import { CellLoader, ColorUtils, Icon, Tooltip, GetColor } from 'venn-ui-kit';
import { assertNotNil, itemHasNoReturns } from 'venn-utils';
import DataDateRange from '../../manage-data/DataDateRange';
import ProxiedInvestmentTooltipContent from '../ProxiedInvestmentTooltipContent';
import { atStartOfDay, getDataColor, getRowRangeLogic } from '../analysisPeriodUtils';
import type { BulkManageRow } from '../types';
import { bulkManageSortFunction } from './bulkManageSortFunction';
import { RangeCellClass } from './column-styles';
import { FillParentTooltip, emptyRangeTooltipCell } from './tooltip-cells';
import type { DataRangeInfo } from '../../manage-data/utils';

type RangeRowValue = BulkManageRow;
type RangeColDef = ColDef<BulkManageRow, RangeRowValue>;

const analysisRangeComparator: RangeColDef['comparator'] = bulkManageSortFunction({
  valueGetter: (v) => (v?.endDate ?? 0) - (v?.startDate ?? 0),
});
const analysisRangeValueGetter = ({ data }) => data;

const analysisRangeAggFunc = ({ values, rowNode }) => ({
  ...assertNotNil(rowNode.data),
  rangeLoading: values.some((v) => v?.rangeLoading),
});

export const getRangeColumn = (dataRangeInfo: DataRangeInfo | undefined, rangeText: string): RangeColDef => {
  if (!dataRangeInfo) {
    return {
      headerComponentParams: {
        displayName: 'Available Analysis Period',
      },
      flex: 2,
    };
  }

  return {
    flex: 1,
    minWidth: 250,
    maxWidth: 500,
    // Once we have ag-grid v33, we should switch to using use innerHeaderComponent and innerHeaderComponentParams so we don't have to
    // implement our own sort etc
    headerComponent: AnalysisRangeHeader,
    headerComponentParams: {
      displayName: 'Available Analysis Period',
      rangeText,
      dataRangeInfo,
    } satisfies AnalysisRangeHeaderParams,
    cellClass: RangeCellClass,
    cellRenderer: RangeCellRenderer,
    comparator: analysisRangeComparator,
    valueGetter: analysisRangeValueGetter,
    aggFunc: analysisRangeAggFunc,
  };
};

export const overlapColor = ColorUtils.opacifyFrom(GetColor.DarkBlue, 0.15);

export const rangeBarBackground = ({
  zeroAllocation,
  frequency,
  color,
}: {
  zeroAllocation: boolean;
  frequency?: FrequencyEnum;
  color: string;
}) => css`
  background: ${zeroAllocation
    ? `repeating-linear-gradient(-45deg, ${color}, ${color} 2px, transparent 2px, transparent 4px)`
    : frequency === 'QUARTERLY' || frequency === 'YEARLY'
      ? `repeating-linear-gradient(to right, ${color} 0, ${color} 4px, transparent 4px,transparent 8px)`
      : color};
`;

const RangeBar = styled.div<{
  widthPercentage: number;
  leftMarginPercentage: number;
  frequency?: FrequencyEnum;
  zeroAllocation: boolean;
  color: string;
}>`
  ${rangeBarBackground}

  height: 8px;
  padding: 0;
  top: calc(50% - 4px);
  position: absolute;

  ${({ widthPercentage, leftMarginPercentage }) => css`
    width: ${widthPercentage * 100}%;
    margin-left: ${leftMarginPercentage * 100 ?? 0}%;
  `}
`;

type AnalysisRangeHeaderParams = {
  displayName: string;
  rangeText: string;
  dataRangeInfo?: DataRangeInfo;
};

const AnalysisRangeHeader = (props: CustomHeaderProps<BulkManageRow> & AnalysisRangeHeaderParams) => {
  const [sortDir, setSortDir] = React.useState<SortDirection | undefined>(undefined);

  useEffect(() => {
    const updateSortDirection = () => {
      setSortDir(props.column.getSort());
    };
    props.column.addEventListener('sortChanged', updateSortDirection);

    return () => {
      props.column.removeEventListener('sortChanged', updateSortDirection);
    };
  }, [props.column]);

  return (
    <AnalysisRangeHeaderContainer
      onClick={(ev) => {
        props.progressSort(ev.shiftKey);
      }}
    >
      <span>{props.displayName}</span>
      <RangeContainer>
        {props.rangeText}{' '}
        <Tooltip
          usePortal
          content={
            <div>
              Available Date Range:
              <br />
              <DataDateRange dataRangeInfo={props.dataRangeInfo} />
            </div>
          }
        >
          <Icon prefix="far" type="circle-info" />
        </Tooltip>{' '}
        <Icon
          style={{
            color: !sortDir ? 'transparent' : undefined,
          }}
          type={sortDir === 'asc' ? 'sort-up' : 'sort-down'}
        />
      </RangeContainer>
    </AnalysisRangeHeaderContainer>
  );
};

const AnalysisRangeHeaderContainer = styled.button`
  width: 100%;
  display: flex;
  justify-content: space-between;

  font-weight: bold;
  &,
  :hover {
    color: unset;
  }
`;

const RangeContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;

  color: ${GetColor.HintGrey};
`;

const OverlapBar = styled.div<{
  widthPercentage: number;
  leftMarginPercentage: number;
}>`
  background-color: ${overlapColor};
  height: 100%;
  width: ${({ widthPercentage }) => widthPercentage * 100}%;
  margin-left: ${({ leftMarginPercentage }) => leftMarginPercentage * 100 ?? 0}%;
  padding: 0;
  top: 0;
  position: absolute;
`;

function RangeCellRenderer({ data, value }: ICellRendererParams<BulkManageRow, RangeRowValue>) {
  const theme = useTheme();

  if (!value || !data || data.isStrategy) {
    return null;
  }

  if (value.rangeLoading || !data.commonRangeData) {
    return <CellLoader />;
  }

  if (itemHasNoReturns(value.startDate, value.endDate)) {
    return emptyRangeTooltipCell;
  }

  const { earliestStart, latestEnd, fullRange, overlap } = data.commonRangeData;

  if (value.isStrategy) {
    return overlap.percentageWidth > 0 ? (
      <OverlapBar widthPercentage={overlap.percentageWidth} leftMarginPercentage={overlap.percentageStart} />
    ) : null;
  }

  const zeroAllocation = value.allocation === 0;
  const { investmentColor, proxyColor, extrapolationColor } = getDataColor(theme, zeroAllocation);

  const frequency = assertNotNil(value.frequency);
  const startDate = assertNotNil(atStartOfDay(value.startDate));
  const endDate = assertNotNil(value.endDate);

  const { proxy, investment, extrapolation } = getRowRangeLogic({
    startDate,
    endDate,
    fullRange,
    earliestStart,
    latestEnd,
    proxyStartDate: atStartOfDay(value.proxyStartDate),
    proxyEndDate: value.proxyEndDate,
    extrapolateEndDate: value.extrapolateEndDate,
    extrapolateStartDate: atStartOfDay(value.extrapolateStartDate),
  });

  return (
    <>
      {overlap.percentageWidth > 0 ? (
        <OverlapBar widthPercentage={overlap.percentageWidth} leftMarginPercentage={overlap.percentageStart} />
      ) : null}
      <RangeBar
        widthPercentage={investment.percentageWidth}
        leftMarginPercentage={investment.percentageStart}
        zeroAllocation={zeroAllocation}
        frequency={frequency}
        color={investmentColor}
      >
        {proxy.percentageWidth > 0 && (
          <RangeBar
            widthPercentage={proxy.percentageWidth}
            leftMarginPercentage={proxy.percentageStart}
            color={proxyColor}
            zeroAllocation={zeroAllocation}
            frequency={frequency}
          />
        )}
        {extrapolation.percentageWidth > 0 && (
          <RangeBar
            widthPercentage={extrapolation.percentageWidth}
            leftMarginPercentage={extrapolation.percentageStart}
            color={extrapolationColor}
            zeroAllocation={zeroAllocation}
            frequency={frequency}
          />
        )}
        <FillParentTooltip maxWidth={400} content={<ProxiedInvestmentTooltipContent data={data} />} />
      </RangeBar>
    </>
  );
}
