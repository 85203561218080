import { GetColor } from '../../style/color';
import type { ThemeProvider } from '../../style/themes';

type SemanticColors = {
  /** The more intense, emphasis color, such as red. */
  primaryColor: ThemeProvider;
  /** Less intense, often used as background, such as White. */
  secondaryColor: ThemeProvider;
  /** Border color, often matching the primary color. */
  borderColor: ThemeProvider;
};

/** Final colors to be applied to the button. */
export type ConcreteColors = {
  color: ThemeProvider;
  backgroundColor: ThemeProvider;
  borderColor: ThemeProvider;
};

/** Color themes to be applied based on the button's variant. */
export type ColorThemes = {
  dominant: ConcreteColors;
  outline: ConcreteColors;
};

/** Color themes for the button's main variants and *destructive* colors. */
export const destructiveColorThemes = createThemes({
  primaryColor: GetColor.Error,
  secondaryColor: GetColor.White,
  borderColor: GetColor.Error,
});

/** Color themes for the button's main variants and *standard* colors. */
export const standardColorThemes = createThemes({
  primaryColor: GetColor.DarkBlue,
  secondaryColor: GetColor.White,
  borderColor: GetColor.DarkBlue,
});

function createThemes(colors: SemanticColors): ColorThemes {
  return {
    dominant: dominantColor(colors),
    outline: outlineColor(colors),
  };
}

/** Creates concrete colors in the *dominant* variant based on the provided semantic colors. */
function dominantColor(colorTrio: SemanticColors): ConcreteColors {
  // For dominant, we want to really make the button pop. So we make the background color the primary color, and the text color the secondary color.
  return {
    color: colorTrio.secondaryColor,
    backgroundColor: colorTrio.primaryColor,
    borderColor: colorTrio.borderColor,
  };
}

/** Creates concrete colors in the *outline* variant based on the provided semantic colors. */
function outlineColor(colorTrio: SemanticColors): ConcreteColors {
  return {
    color: colorTrio.primaryColor,
    backgroundColor: colorTrio.secondaryColor,
    borderColor: colorTrio.borderColor,
  };
}
