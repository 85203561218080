import React, { Suspense, useContext } from 'react';
import { FlexHeader } from '../../../../shared';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  blockRequestSubjects,
  blockSettings,
  historicalPortfolioAsOfDateSetting,
  historicalPortfolioDateRange,
  lastHistoricalAllocationDate,
} from 'venn-state';
import { CalendarDropdown, UserContext } from 'venn-components';
import { GetColor, Icon, ShimmerBlock, Tooltip } from 'venn-ui-kit';
import { isHistoricalSubjectAsOfDateParameterized } from 'venn-utils';
import styled from 'styled-components';
import moment from 'moment';
import { isNil } from 'lodash';

const AllocationAsOfDateInputControl = ({ selectedBlockId }: { selectedBlockId: string }) => {
  const { hasPermission } = useContext(UserContext);
  const historicalPortfolioRange = useRecoilValue(historicalPortfolioDateRange(selectedBlockId));
  const [historicalPortfolioAsOfSetting, sethistoricalPortfolioAsOfDateSetting] = useRecoilState(
    historicalPortfolioAsOfDateSetting(selectedBlockId),
  );
  const lastHistoricalAllocationDt = useRecoilValue(lastHistoricalAllocationDate(selectedBlockId));
  const readonly = !hasPermission('STUDIO_EDIT_DATES') || !hasPermission('STUDIO_EDIT');
  const start = !isNil(historicalPortfolioRange?.from) ? moment(historicalPortfolioRange.from).toDate() : undefined;
  const end = !isNil(historicalPortfolioRange?.to) ? moment(historicalPortfolioRange.to).toDate() : undefined;
  return (
    <CalendarDropdown
      start={start}
      end={end}
      value={historicalPortfolioAsOfSetting}
      lastUpdated={lastHistoricalAllocationDt}
      onChange={sethistoricalPortfolioAsOfDateSetting}
      disabled={readonly}
    />
  );
};
export const AllocationAsOfDateInput = ({ selectedBlockId }: { selectedBlockId: string }) => {
  const subjects = useRecoilValue(blockRequestSubjects(selectedBlockId));
  const blockSetting = useRecoilValue(blockSettings(selectedBlockId));
  const isHistoricProFormaOnlyBlock =
    subjects.length > 0 && isHistoricalSubjectAsOfDateParameterized(subjects, blockSetting.customBlockType);

  return (
    isHistoricProFormaOnlyBlock && (
      <div data-testid="qa-block-date-range">
        <FlexHeader style={{ marginBottom: '12px' }}>
          Allocation As of Date
          <Tooltip
            content={
              <TooltipContent>
                Add a historical portfolio with historical allocations in order to set an as of date.
              </TooltipContent>
            }
            usePortal
          >
            <InfoIcon type="info-circle" prefix="far" />
          </Tooltip>
        </FlexHeader>
        <Suspense fallback={<ShimmerBlock height={32} width="210px" />}>
          <AllocationAsOfDateInputControl selectedBlockId={selectedBlockId} />
        </Suspense>
      </div>
    )
  );
};

const InfoIcon = styled(Icon)`
  color: ${GetColor.HintGrey};
  margin-left: 4px;
  font-size: 14px;
`;

const TooltipContent = styled.div`
  width: 236px;
  padding: 5px;
`;
