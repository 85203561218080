import type { IRowNode } from 'ag-grid-community';
import { isNil } from 'lodash';

/* Convenience method to sort any row by a property while ensuring that excluded or secondary rows are always last. */
export function bulkManageSortFunction<R extends { secondary?: boolean }, V>({
  valueGetter = (v: unknown) => {
    if (typeof v === 'number') return v;
    if (isNil(v)) return v;
    return String(v);
  },
}: {
  valueGetter?: (v: V, r: R | undefined) => number | string | undefined | null;
} = {}) {
  const isSecondary = (r: R) => r.secondary ?? false;
  return (initialValueA: V, initialValueB: V, nodeA: IRowNode<R>, nodeB: IRowNode<R>, isDescending: boolean) => {
    const rowA = nodeA.data;
    const rowB = nodeB.data;

    const valueA = valueGetter?.(initialValueA, rowA);
    const valueB = valueGetter?.(initialValueB, rowB);

    if (!rowA || !rowB) {
      // Secondary and excluded rows should be sorted even after rows with no data
      if (rowA && !rowB && isSecondary(rowA)) {
        return isDescending ? -1 : 1;
      }
      if (rowB && !rowA && isSecondary(rowB)) {
        return isDescending ? 1 : -1;
      }

      // If neither row is secondary or excluded, then we can just compare values if we have them
      if (!isNil(valueA) && !isNil(valueB)) {
        return compare(valueA, valueB);
      }
      return 0;
    }

    const excludeComparisonResult = compareExclude(rowA, rowB, isSecondary);
    if (excludeComparisonResult !== undefined) {
      // Undo the reversal that descending sort causes in order to force excluded rows back to the bottom
      return excludeComparisonResult * (isDescending ? -1 : 1);
    }
    return compare(valueA, valueB);
  };
}

/* Ensures that rows marked as excluded are always after non-excluded rows. */
const compareExclude = <V>(a: V, b: V, exclude: (r: V) => boolean) => {
  const excludedA = exclude(a);
  const excludedB = exclude(b);

  if (excludedA && excludedB) return 0;
  if (excludedA) return 1;
  if (excludedB) return -1;
  return undefined;
};

const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
});
const compare = (a: unknown, b: unknown) => {
  if (a === b) {
    return 0;
  }

  const aNil = isNil(a);
  const bNil = isNil(b);
  if (aNil && bNil) return 0;
  if (aNil) return -1;
  if (bNil) return 1;

  if (typeof a === 'string' && typeof b === 'string') {
    return collator.compare(a, b);
  }

  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  }

  return 0;
};
