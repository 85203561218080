import React from 'react';
import { TooltipLink, FORECASTS_FAQ_HREF } from 'venn-ui-kit';

const LongTermForecastTooltip = () => {
  return (
    <TooltipLink
      positions={{
        top: -205,
        left: -260,
      }}
      text="Forecast metrics represent a forward looking view on performance. They are calculated using up to 3 years (minimum of 1 year) of daily or monthly data to estimate factor betas and the residual, which are then lined up with your factor forecasts."
      href={FORECASTS_FAQ_HREF}
      top
    />
  );
};

export default LongTermForecastTooltip;
