import React from 'react';
import type { FrequencyEnum, Portfolio } from 'venn-api';
import moment from 'moment';
import { itemHasNoReturns } from '../analysis/noReturnsUtils';
import { getAppTitleSimplifiedUtil } from '../utils';

export const fundLengthInMonths = (fundReturnFrequency: FrequencyEnum, startDate: number, endDate: number): number => {
  if (itemHasNoReturns(startDate, endDate)) {
    return 0;
  }

  const start = moment.utc(startDate);
  const end = moment.utc(endDate);

  switch (fundReturnFrequency) {
    case 'UNKNOWN':
      return end.diff(start, 'months');
    case 'DAILY':
      return end.diff(start.subtract(1, 'days'), 'months');
    case 'MONTHLY':
      return end.startOf('month').diff(start.startOf('month').subtract(1, 'months'), 'months');
    case 'QUARTERLY':
      return end.startOf('month').diff(start.startOf('month').subtract(3, 'months'), 'months');
    default:
      return end.startOf('month').diff(start.startOf('month').subtract(1, 'months'), 'months');
  }
};

interface RequiredPeriodShape {
  lengthInMonths: number;
  lengthInYears: number;
  label: string;
}

const FactorAnalysisRequiredPeriodDefault: RequiredPeriodShape = {
  lengthInMonths: 144,
  lengthInYears: 12,
  label: '12 years',
};
const FactorAnalysisRequiredPeriods: { [key in FrequencyEnum]: RequiredPeriodShape } = {
  DAILY: {
    lengthInMonths: 6,
    lengthInYears: 0.5,
    label: '6 months',
  },
  MONTHLY: {
    lengthInMonths: 12,
    lengthInYears: 1,
    label: '1 year',
  },
  QUARTERLY: {
    lengthInMonths: 36,
    lengthInYears: 3,
    label: '3 years',
  },
  YEARLY: FactorAnalysisRequiredPeriodDefault,
  WEEKLY: FactorAnalysisRequiredPeriodDefault,
  UNKNOWN: FactorAnalysisRequiredPeriodDefault,
};

export const FactorAnalysisRequiredPeriod = () => {
  return FactorAnalysisRequiredPeriods;
};

export const AdvancedAnalysisRequiredPeriods: { [key in FrequencyEnum]: RequiredPeriodShape } = {
  DAILY: {
    lengthInMonths: 6,
    lengthInYears: 0.5,
    label: '6 months',
  },
  MONTHLY: {
    lengthInMonths: 12,
    lengthInYears: 1,
    label: '1 year',
  },
  QUARTERLY: {
    lengthInMonths: 36,
    lengthInYears: 3,
    label: '3 years',
  },
  YEARLY: FactorAnalysisRequiredPeriodDefault,
  WEEKLY: FactorAnalysisRequiredPeriodDefault,
  UNKNOWN: FactorAnalysisRequiredPeriodDefault,
};

export const ScenarioAnalysisRequiredPeriods: { [key in FrequencyEnum]: RequiredPeriodShape } = {
  DAILY: {
    lengthInMonths: 12,
    lengthInYears: 1,
    label: '1 year',
  },
  MONTHLY: {
    lengthInMonths: 12,
    lengthInYears: 1,
    label: '1 year',
  },
  QUARTERLY: {
    lengthInMonths: 36,
    lengthInYears: 3,
    label: '3 years',
  },
  YEARLY: FactorAnalysisRequiredPeriodDefault,
  WEEKLY: FactorAnalysisRequiredPeriodDefault,
  UNKNOWN: FactorAnalysisRequiredPeriodDefault,
};

export const SchemeAnalysisRequiredPeriod = (isScenarioAnalysis?: boolean) => {
  return isScenarioAnalysis ? ScenarioAnalysisRequiredPeriods : AdvancedAnalysisRequiredPeriods;
};

export const getMissingReturnsMessage = (strategy: Portfolio, requiredPeriodColor: string): JSX.Element | undefined => {
  if (!strategy || !strategy.fund) {
    return undefined;
  }

  const start = strategy.fund.startRange;
  const end = strategy.fund.endRange;
  const fundReturnFrequency = strategy.fund.returnFrequency;

  /* Displayed for daily or monthly frequency, or no returns */
  let actionHint = 'Add a proxy or remove this investment to enable them.';

  let errorMessage: React.ReactNode;

  if (itemHasNoReturns(start, end)) {
    errorMessage = (
      <>
        Factor-based analyses cannot be run on investments with{' '}
        <span style={{ color: requiredPeriodColor }}>no returns</span>.
      </>
    );
  } else {
    const investmentLengthInMonths = fundLengthInMonths(fundReturnFrequency, start, end);

    const period = AdvancedAnalysisRequiredPeriods[fundReturnFrequency];

    const requiredMonthsLength = fundReturnFrequency === 'DAILY' ? 12 : period ? period.lengthInMonths : 0;

    if (
      !period ||
      (fundReturnFrequency !== 'QUARTERLY' &&
        fundReturnFrequency !== 'YEARLY' &&
        investmentLengthInMonths >= requiredMonthsLength)
    ) {
      return undefined;
    }

    // logic to address quarterly investments since they require action regardless of length
    if (fundReturnFrequency === 'QUARTERLY') {
      if (investmentLengthInMonths >= 12 * 3) {
        actionHint = 'Interpolate this quarterly investment to enable them.';
      } else {
        actionHint = 'Try using a Preqin index with interpolation or remove this investment to enable them.';
      }
    }

    if (['WEEKLY', 'YEARLY', 'UNKNOWN'].includes(fundReturnFrequency)) {
      actionHint = 'Please remove this investment to enable analysis.';
    }

    errorMessage = ['DAILY', 'MONTHLY', 'QUARTERLY'].includes(fundReturnFrequency) ? (
      <>
        Some factor-based analyses require at least <span style={{ color: requiredPeriodColor }}>1 year</span> of daily
        or monthly data.
      </>
    ) : (
      `${getAppTitleSimplifiedUtil()} does not support ${fundReturnFrequency.toLowerCase()} investments at this time.`
    );
  }

  return (
    <div>
      {errorMessage} {actionHint}
    </div>
  );
};
