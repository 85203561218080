import React, { useRef, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Headline3, Subtitle1, TablePlaceholder } from 'venn-ui-kit';
import type { AnalysisSubject } from 'venn-utils';
import {
  invalidate,
  logExhaustive,
  recursiveGetFunds,
  useHasFF,
  useMemoWithArgs,
  useModal,
  withSuspense,
} from 'venn-utils';
import EmptyState from '../empty-state/EmptyState';
import { useRangeAnalysisQuery } from '../hooks/useRangeAnalysisQuery';
import { PlaceholderWrapper } from '../manage-data';
import type { BulkManageRow } from './types';
import { BulkManageAction } from './types';
import { BulkProxySection } from './BulkProxySection';
import { BulkDataGrid } from './BulkDataGrid';
import type { IRowNode, SelectionChangedEvent } from 'ag-grid-community';
import { compact, isNil, uniq } from 'lodash';
import type { AgGridReact } from 'ag-grid-react';
import { getBulkManagementData } from './data';
import { prefetchBenchmarkQuery, useBenchmarkQuery } from '../hooks/useBenchmarkQuery';
import { useProxyDataQuery } from '../hooks/useProxyDataQuery';
import { withErrorBoundary } from '../error-boundary/CustomizableErrorBoundary';
import { SomethingWentWrongFallbackComponent } from '../error-boundary/SomethingWentWrongFallbackComponent';
import {
  getRangeColumn,
  startDateColDef,
  endDateColDef,
  frequencyColDef,
  dataSourceColDef,
  getProxyColDef,
} from './columns';
import { getRangeLogic } from './analysisPeriodUtils';
import type { FundToBulkProxy } from '../modals/pickers/types';

interface BulkManagementTableProps {
  subject: AnalysisSubject;
  onFundUpdated?: (fundId: string) => void;
  canEditForecasts: boolean;
  canEditProxies: boolean;
}

const loadingState = (
  <PlaceholderWrapper>
    <TablePlaceholder />
  </PlaceholderWrapper>
);

export const BulkManagementTable = withErrorBoundary(
  SomethingWentWrongFallbackComponent,
  withSuspense(loadingState, InternalBulkManagementTable),
);

function InternalBulkManagementTable({ subject, onFundUpdated, canEditProxies }: BulkManagementTableProps) {
  const [isPickerOpen, onPickerOpen, onPickerClose] = useModal();

  const portfolioFunds = useMemo(
    () => (subject.fund ? [subject.fund] : recursiveGetFunds(subject.portfolio)),
    [subject.fund, subject.portfolio],
  );

  const uniqFundIds = useMemo(() => {
    const newFundIds = portfolioFunds.map((f) => f.id);
    if (typeof subject.activeBenchmarkId === 'string') newFundIds.push(subject.activeBenchmarkId);
    // Uniq for correctness, sort for deterministic cache keys.
    return uniq(newFundIds).sort();
  }, [portfolioFunds, subject.activeBenchmarkId]);

  // Fetch range analysis, but don't suspend yet since we can still render the table without ranges, and
  // the range might change and we don't want to keep suspending.
  const { rangeAnalysis, analysisRequest: rangeAnalysisRequest } = useRangeAnalysisQuery(subject);
  // Prefetch benchmark before starting suspense based queries to prevent waterfall
  prefetchBenchmarkQuery(subject);
  const { data: proxyDataByFund = emptyProxyData } = useProxyDataQuery(uniqFundIds, {
    suspense: true,
  });
  const benchmarkInfo = useBenchmarkQuery(subject, {
    suspense: true,
  });

  /** Its useful to extract out the fund early, so we can explicitly declare it in the memoization dependency array. */
  const benchmarkFund = benchmarkInfo.type === 'fund' ? benchmarkInfo.benchmark : undefined;
  const allFunds = useMemo(() => {
    const tmpFunds = portfolioFunds;
    if (benchmarkFund) tmpFunds.push(benchmarkFund);
    return tmpFunds;
  }, [portfolioFunds, benchmarkFund]);

  const hasFullHistory = useHasFF('extend_full_history_ff');
  const gridRef = useRef<AgGridReact | null>(null);
  const [selectedFundIds, setSelectedFundIds] = useState<string[]>([]);

  const rangeData = useMemo(() => {
    if (!rangeAnalysis) return undefined;
    const primaryRangeAnalysis = !isNil(
      rangeAnalysisRequest?.subjects?.findIndex((s) => s.comparisonType === 'PRIMARY'),
    )
      ? rangeAnalysis?.rangeAnalyses[rangeAnalysisRequest?.subjects?.findIndex((s) => s.comparisonType === 'PRIMARY')]
      : null;
    return getRangeLogic(hasFullHistory, rangeAnalysis, primaryRangeAnalysis, {
      short: true,
    });
  }, [hasFullHistory, rangeAnalysis, rangeAnalysisRequest?.subjects]);

  const rowData = useMemo(
    () =>
      getBulkManagementData(
        subject,
        allFunds,
        proxyDataByFund,
        rangeAnalysis,
        rangeAnalysisRequest,
        benchmarkInfo.benchmark,
        rangeData,
      ),
    [subject, allFunds, proxyDataByFund, rangeAnalysis, rangeAnalysisRequest, benchmarkInfo.benchmark, rangeData],
  );

  const handleRowUpdate = useCallback(
    async (action: BulkManageAction, row: BulkManageRow) => {
      switch (action) {
        case BulkManageAction.FUND_MODIFIED:
          const investmentId = row.investment?.id;
          const subjectInvalidation = invalidate('investment', subject.id);
          const investmentInvalidation =
            investmentId && investmentId !== subject.id ? invalidate('investment', investmentId) : Promise.resolve();
          investmentId && onFundUpdated?.(investmentId);
          await Promise.allSettled([subjectInvalidation, investmentInvalidation]);
          break;
        case BulkManageAction.INVESTMENT_FORECAST_MODIFIED:
          break;
        default:
          logExhaustive(action);
      }
    },
    [subject.id, onFundUpdated],
  );

  const selectRow = useCallback(
    (row: IRowNode<BulkManageRow>) => {
      gridRef.current?.api.deselectAll();
      row.setSelected(true);
      onPickerOpen();
    },
    [onPickerOpen],
  );

  const dataRangeInfo = useMemo(
    () =>
      rangeAnalysis?.start
        ? {
            start: rangeAnalysis.start,
            end: rangeAnalysis.end,
            frequency: rangeAnalysis.frequency,
          }
        : undefined,
    [rangeAnalysis?.start, rangeAnalysis?.end, rangeAnalysis?.frequency],
  );

  // Column defs dependencies are as few and unchanging as possible to prevent expensive re-renders.
  const rangeColDef = useMemoWithArgs(getRangeColumn, [dataRangeInfo, rangeData?.overlap.length ?? '']);
  const proxyColDef = useMemoWithArgs(getProxyColDef, [canEditProxies, handleRowUpdate, selectRow]);
  const columnDefs = useMemo(
    () => [rangeColDef, startDateColDef, endDateColDef, frequencyColDef, dataSourceColDef, proxyColDef],
    [rangeColDef, proxyColDef],
  );

  const onBulkProxyChange = useCallback(async (fundIds: string[]) => {
    await invalidate('investments', fundIds);
  }, []);

  const onProxyFailedToChange = useCallback((funds: FundToBulkProxy[]) => {
    const fundIds = funds.map((f) => f.id);
    setSelectedFundIds(fundIds);

    const gridApi = gridRef.current?.api;
    if (gridApi) {
      const fundIdSet = new Set(fundIds);
      gridApi.deselectAll();
      gridApi.forEachNode((rowNode) => {
        if (fundIdSet.has(rowNode.data.investment?.id)) {
          rowNode.setSelected(true);
        }
      });
    }
  }, []);

  const onClearSelected = useCallback(() => {
    setSelectedFundIds([]);
    gridRef.current?.api.deselectAll();
  }, []);

  const onSelectionChanged = useCallback((event: SelectionChangedEvent<BulkManageRow, unknown>) => {
    const rows = event.api.getSelectedRows();
    setSelectedFundIds(compact(rows.map((row) => row.investment?.id)));
  }, []);

  if (uniqFundIds.length === 0) {
    return <EmptyState header="There are no investments in this portfolio." />;
  }

  return (
    <Wrapper>
      <HeaderContainer>
        <Headers>
          <Headline3>Portfolio Management</Headline3>
          <Subtitle1>
            Manage the investments in your portfolio by selecting one or more to apply proxies if needed.
          </Subtitle1>
        </Headers>
      </HeaderContainer>

      <BulkDataGrid
        gridRef={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        onSelectionChanged={onSelectionChanged}
      />

      {selectedFundIds.length > 0 && (
        <BulkProxySection
          selectedFundIds={selectedFundIds}
          funds={allFunds}
          proxyDataByFund={proxyDataByFund}
          onProxyChange={onBulkProxyChange}
          onProxyFailedToChange={onProxyFailedToChange}
          onClearSelected={onClearSelected}
          onPickerOpen={onPickerOpen}
          onPickerClose={onPickerClose}
          isPickerOpen={isPickerOpen}
        />
      )}
    </Wrapper>
  );
}

const emptyProxyData = {};

const Wrapper = styled.div`
  width: 100%;
  position: relative;

  .bulk-management-table-wrapper,
  & > div {
    width: 100%;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Headers = styled.div`
  flex: 1;
  margin-bottom: 10px;

  > h1 {
    margin-bottom: 0;
  }

  > h2 {
    margin-top: 4px;
    margin-bottom: 0;
  }
`;
