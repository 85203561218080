import React from 'react';
import { Block } from '../../core';
import type { CustomBlockTypeEnum } from 'venn-utils';

interface HoldingsCalculationInfoProps {
  blockType: CustomBlockTypeEnum;
}

export const HoldingsCalculationInfo = ({ blockType }: HoldingsCalculationInfoProps) => {
  const text = blockType === 'ASSET_EXPOSURE' ? 'Net Rescaled Values' : 'Long Rescaled Values';
  return <Block.MetadataItem iconType="info-circle">{text}</Block.MetadataItem>;
};
