import React from 'react';
import { useRecoilValue } from 'recoil';
import { blockDisplayHeader, customizedBlock, isReportState } from 'venn-state';
import { Block } from '../../components/core';
import { useAppPrintMode } from '../../../print';
import { DefaultSubjects } from '../../components/secondary/subjects/default-subjects';

interface PrivateAssetSummaryHeaderProps {
  id: string;
}

export const PrivateAssetSummaryHeader = ({ id }: PrivateAssetSummaryHeaderProps) => {
  const { inPrintModeOrReportLab } = useAppPrintMode();
  const { subHeader } = useRecoilValue(customizedBlock(id));
  const title = useRecoilValue(blockDisplayHeader(id));
  const isReport = useRecoilValue(isReportState);

  return (
    <>
      <Block.HeaderTopContainer inPrintMode={inPrintModeOrReportLab}>
        <Block.Title>
          <Block.Headline id={id} isReport={isReport}>
            {title}
          </Block.Headline>
          {subHeader && <Block.SubHeadline id={id}>{subHeader}</Block.SubHeadline>}
        </Block.Title>
      </Block.HeaderTopContainer>

      <DefaultSubjects id={id} relativeToBenchmark={false} />
    </>
  );
};
