import type { FrequencyEnum, SimpleFund } from 'venn-api';
import { lowestFrequencyOf } from '../frequency';

type PortfolioLike<ChildT> = {
  id: number;
  children?: ChildT[];
  fund?: SimpleFund;
};
export type WithStrategies<T> = T & { strategyIdPath: number[]; lowestFrequency?: FrequencyEnum };

/**
 * Flattens a portfolio tree into a list of nodes, each with a 'strategyIdPath` property.
 *
 * The root node will have an empty `strategyIdPath`.
 *
 * By default, the root is not included in the `strategyIdPath` of any node.
 */
export const flattenNodeWithStrategyPath = <P extends PortfolioLike<P>>(
  root: P | undefined,
  options: {
    includeRoot?: boolean;
  } = {},
): WithStrategies<P>[] => {
  if (!root) {
    return [];
  }

  return innerFlatten(root, []);

  function innerFlatten(curr: P, strategyIdPath: number[]): WithStrategies<P>[] {
    const newPath = options.includeRoot || curr !== root ? [...strategyIdPath, curr.id] : [];
    const flattenedChildren = (curr.children ?? []).flatMap((node) => innerFlatten(node, newPath));
    const lowestFrequency = lowestFrequencyOf([
      curr.fund?.returnFrequency,
      ...flattenedChildren.map((child) => child.lowestFrequency ?? child.fund?.returnFrequency ?? 'UNKNOWN'),
    ]);
    return [{ ...curr, strategyIdPath, lowestFrequency }, ...flattenedChildren];
  }
};
