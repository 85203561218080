import React from 'react';

import { useRecoilValue } from 'recoil';

import { blockDisplayHeader, blockSettings, customizedBlock, isReportState } from 'venn-state';

import { Block } from '../../components/core';

import { PrivateAssetGrowthDateRange } from '../../components/secondary/date-ranges';

import { useAppPrintMode } from '../../../print';
import { DefaultSubjects } from '../../components/secondary/subjects/default-subjects';
import { getDateBehavior } from 'venn-utils';
import { BenchmarkType } from '../../components/secondary/benchmark-type';
import { DataModificationsButton } from '../../components/secondary/buttons/data-modifications-button';
import { useAnalysisBlockDataModifications } from '../../../modals';

interface PrivateAssetGrowthSimulationHeaderProps {
  id: string;
}

export const PrivateAssetGrowthSimulationHeader = ({ id }: PrivateAssetGrowthSimulationHeaderProps) => {
  const { inPrintModeOrReportLab } = useAppPrintMode();

  const title = useRecoilValue(blockDisplayHeader(id));
  const isReport = useRecoilValue(isReportState);
  const { selectedMetrics, subHeader } = useRecoilValue(customizedBlock(id));
  const blockSetting = useRecoilValue(blockSettings(id));
  const dateBehavior = getDateBehavior(blockSetting.customBlockType);

  return (
    <>
      <Block.HeaderTopContainer inPrintMode={inPrintModeOrReportLab}>
        <Block.Title>
          <Block.Headline id={id} isReport={isReport}>
            {title}
          </Block.Headline>
          {subHeader && <Block.SubHeadline id={id}>{subHeader}</Block.SubHeadline>}
        </Block.Title>

        <Block.Metadata id={id}>
          <PrivateAssetGrowthDateRange id={id} dateBehavior={dateBehavior} />
          <DataModificationsButton modifications={useAnalysisBlockDataModifications(id)?.modifications} />
          <BenchmarkType id={id} />
        </Block.Metadata>
      </Block.HeaderTopContainer>

      <DefaultSubjects id={id} metric={selectedMetrics[0] || ''} relativeToBenchmark={false} />
    </>
  );
};
