import type { FC } from 'react';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { GetColor, Button, Tooltip, KeyCodes } from 'venn-ui-kit';

export interface ModalFooterProps {
  /** Old behaviour of this component was making various buttons dense / flat by default
   * Design preferred to get rid of this behaviour in new components and simplify the button styles.
   * Keeping the old option for backwards compatibility */
  simplifiedButtonStyles?: boolean;
  submitOnEnter?: boolean; // Allows to fire onPrimaryClick on Enter

  className?: string;

  primaryLabel?: string | JSX.Element;
  primaryClassName?: string;
  primaryDisabled?: boolean;
  onPrimaryClick?: () => void;
  primaryTooltip?: string;

  secondaryLabel?: string | JSX.Element;
  secondaryClassName?: string;
  secondaryDisabled?: boolean;
  onSecondaryClick?: () => void;
  secondaryDestructive?: boolean;

  cancelClassName?: string;
  onCancel?: () => void;
  cancelLabel?: string | JSX.Element;
  destructive?: boolean;

  leftChildren?: React.ReactNode;
  rightChildren?: React.ReactNode;
  inverted?: boolean;
}

/** Excludes the id prefix ('#'). */
export const MODAL_FOOTER_PRIMARY_BTN_ID = 'modal-footer-button-primary';

export const ModalFooter: FC<React.PropsWithChildren<ModalFooterProps>> = ({
  simplifiedButtonStyles = false,
  submitOnEnter,
  destructive = false,
  primaryLabel,
  primaryClassName,
  primaryDisabled,
  onPrimaryClick,
  primaryTooltip,
  secondaryLabel,
  secondaryClassName,
  secondaryDisabled,
  secondaryDestructive,
  onSecondaryClick,
  cancelClassName,
  onCancel,
  cancelLabel,
  className,
  leftChildren,
  rightChildren,
  inverted,
}) => {
  // Keep this in the ref for `submitOnEnter` to work:
  // `onKeyDown` is assigned to the event listener on mount, and
  // the ref is needed to pick up the changes in `primaryDisabled`
  const primaryDisabledRef = useRef<boolean>(!!primaryDisabled);
  useEffect(() => {
    if (!!primaryDisabled !== primaryDisabledRef.current) {
      primaryDisabledRef.current = !!primaryDisabled;
    }
  }, [primaryDisabled]);

  const onKeydown = (event: KeyboardEvent) => {
    if (event.keyCode === KeyCodes.Enter && !primaryDisabledRef.current) {
      event.stopPropagation();
      event.preventDefault();
      onPrimaryClick?.();
    }
  };

  useEffect(() => {
    if (submitOnEnter) {
      document.addEventListener('keydown', onKeydown);
    }
    return () => {
      if (submitOnEnter) {
        document.removeEventListener('keydown', onKeydown);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const primaryButton =
    !!primaryLabel && !!onPrimaryClick ? (
      <Tooltip content={primaryTooltip} id="modal-footer-tooltip-primary">
        <Button
          refreshedStyling={simplifiedButtonStyles}
          dense={!simplifiedButtonStyles}
          dominant
          destructive={destructive}
          disabled={primaryDisabled}
          onClick={onPrimaryClick}
          className={primaryClassName}
          data-testid="qa-primary-button"
          id={MODAL_FOOTER_PRIMARY_BTN_ID}
          aria-label={primaryLabel}
        >
          {primaryLabel}
        </Button>
      </Tooltip>
    ) : null;

  const secondaryButton =
    !!secondaryLabel && !!onSecondaryClick ? (
      <Button
        refreshedStyling={simplifiedButtonStyles}
        dense={!simplifiedButtonStyles}
        destructive={secondaryDestructive}
        disabled={secondaryDisabled}
        onClick={onSecondaryClick}
        className={secondaryClassName}
        data-testid="qa-secondary-button"
      >
        {secondaryLabel}
      </Button>
    ) : null;

  const cancelButton = onCancel ? (
    <CancelButton
      refreshedStyling={simplifiedButtonStyles}
      flat={!simplifiedButtonStyles}
      onClick={onCancel}
      className={cancelClassName}
      data-testid="qa-close-button"
      type="button"
    >
      {cancelLabel ?? 'Cancel'}
    </CancelButton>
  ) : null;

  return (
    <FooterWrapper className={className} inverted={inverted} id="modal-footer-wrapper" data-testid="modal-footer">
      <Right id="modal-footer-right">
        {primaryButton}
        {secondaryButton}
        {rightChildren && <div>{rightChildren}</div>}
      </Right>
      <Left>
        {cancelButton}
        {leftChildren && <div>{leftChildren}</div>}
      </Left>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.footer<{ inverted?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  padding: 20px 20px 20px 0;
  margin-top: 24px;
  background-color: ${(props) => (props.inverted ? 'transparent' : GetColor.PaleGrey)};
  height: 50px;
  border-top: solid 1px ${GetColor.DarkGrey};
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  & > div,
  button {
    margin-right: 10px;
    margin-left: 0;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  // TODO: see if we can delete this selector for divs
  & > div > div,
  button {
    margin-right: 0;
    margin-left: 10px;
  }
`;

const CancelButton = styled(Button)`
  font-size: 14px;
  text-transform: none;
  letter-spacing: normal;
`;

export default ModalFooter;
