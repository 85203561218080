import type { ReactNode } from 'react';
import React, { Component } from 'react';
import { logExceptionIntoSentry, type AnyObject } from 'venn-utils';
import { SomethingWentWrongFallbackComponent } from './SomethingWentWrongFallbackComponent';
import type { FallbackComponent } from './FallbackComponent';

interface Props {
  FallbackComponent?: FallbackComponent;
  onError?: (error: Error, componentStack: string) => void;
  children?: ReactNode;
}

export interface ErrorInfo {
  [index: string]: unknown;

  componentStack: string;
}

export interface State {
  error?: Error | null;
  info?: ErrorInfo | null;
  retryIntervalId: NodeJS.Timeout | null;
}

class CustomizableErrorBoundary extends Component<Props, State> {
  static defaultProps = {
    FallbackComponent: SomethingWentWrongFallbackComponent,
  };

  state: State = {
    error: null,
    info: null,
    retryIntervalId: null,
  };

  componentDidCatch(error: Error, info: ErrorInfo): void {
    const { onError } = this.props;

    if (onError) {
      try {
        onError.call(this, error, info ? info.componentStack : '');
      } catch (ignoredError) {} // eslint-disable-line no-empty
    }

    if (this.state.retryIntervalId === null) {
      // Don't logto sentry during retries
      logExceptionIntoSentry(error, info);
    }

    this.setState({ error, info });
  }

  componentWillUnmount(): void {
    const { retryIntervalId } = this.state;

    if (retryIntervalId !== null) {
      clearInterval(retryIntervalId);
    }
  }

  onRetry = () => {
    this.setState({ error: null, info: null });
  };

  onRetryRepeatedly = (intervalMs: number) => {
    this.clearRetryInterval();

    const iid = setInterval(this.onRetry, intervalMs);
    this.setState({ retryIntervalId: iid });
  };

  clearRetryInterval = () => {
    if (this.state.retryIntervalId !== null) {
      clearInterval(this.state.retryIntervalId);
      this.setState({ retryIntervalId: null });
    }
  };

  render() {
    const { children, FallbackComponent } = this.props;
    const { error, info } = this.state;

    if (error !== null && FallbackComponent) {
      return (
        <FallbackComponent
          info={info?.componentStack ?? ''}
          error={error}
          onRetry={this.onRetry}
          onRetryRepeatedly={this.onRetryRepeatedly}
          onClearRetryInterval={this.clearRetryInterval}
          isRetrying={this.state.retryIntervalId !== null}
        />
      );
    }

    return children;
  }
}

export default CustomizableErrorBoundary;

/**
 * HOC to wrap components with an error boundary
 * @param Child the component to wrap
 * @param fallback the fallback state in case of an error
 */
export const withErrorBoundary = <P extends JSX.IntrinsicAttributes & AnyObject>(
  fallback: FallbackComponent,
  Child: React.ComponentType<React.PropsWithChildren<P>>,
) => {
  // noinspection UnnecessaryLocalVariableJS
  const ErrorBoundaryHOC = (props: P) => {
    return (
      <CustomizableErrorBoundary FallbackComponent={fallback}>
        <Child {...props} />
      </CustomizableErrorBoundary>
    );
  };
  return ErrorBoundaryHOC;
};
