import { isNil, negate } from 'lodash';
import type { AnalysisRequest, Message, RangeAnalysisResponse, RangeDebugResponse, FrequencyEnum } from 'venn-api';
import { analysis } from 'venn-api';
import type { AnalysisSubject, UseQueryOptions } from 'venn-utils';
import { VennQueryClient, createRangeAnalysisKey, getAnalysisRequest, useQuery } from 'venn-utils';

type QueryOptions = {
  enabled?: boolean;
  suspense?: boolean;
};

export interface RangeDebugResult {
  response: RangeDebugResponse | null;
  subjectError: Message | null;
}

export interface RangeDebugGroup {
  primary: RangeDebugResult;
  secondary?: RangeDebugResult;
  benchmark?: RangeDebugResult;
  master?: RangeDebugResult;
  maxStartTime: number;
  maxEndTime: number;
  maxFrequency: FrequencyEnum;
}

export interface UseRangeAnalysisReturn {
  loading: boolean;
  rangeAnalysis?: RangeAnalysisResponse;
  analysisRequest?: Partial<AnalysisRequest>;
}

export const prefetchRangeQuery = (subject: AnalysisSubject | undefined) => {
  return VennQueryClient.getInstance().prefetchQuery(createQuery(subject));
};

export const useRangeAnalysisQuery = (
  subject: AnalysisSubject | undefined,
  options: QueryOptions = {},
): UseRangeAnalysisReturn => {
  const { data, isLoading } = useQuery(createQuery(subject, options));

  return {
    loading: isLoading,
    rangeAnalysis: data?.analysisResponse,
    analysisRequest: data?.analysisRequest,
  };
};

function createQuery(
  subject: AnalysisSubject | undefined,
  { suspense = false, enabled = true }: QueryOptions = {},
): UseQueryOptions<{
  analysisRequest: Partial<AnalysisRequest> | undefined;
  analysisResponse: RangeAnalysisResponse | undefined;
} | null> {
  return {
    staleTime: 5 * 60 * 1000,
    queryKey: createRangeAnalysisKey(subject),
    suspense,
    enabled,
    queryFn: async ({ signal }) => {
      if (!subject || subject.private) {
        return null;
      }

      const analysisRequest = getAnalysisRequest(['RANGE_ANALYSIS'], subject, {}, false, true);
      const response = await analysis(analysisRequest, signal);
      const analysisResponse = response.content.analyses.map((a) => a.rangeAnalysis).find(negate(isNil));
      return { analysisRequest, analysisResponse };
    },
  };
}
