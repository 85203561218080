import { useContext } from 'react';
import { hasUnmatchedFunds, historicalPortfolioHasDuplicatedInvestments } from '../../../review/helpers';
import { MultiPortfolioReviewContext } from '../../common/MultiPortfolioReviewContext';

const NEEDS_MAPPING_TOOLTIP_MESSAGE =
  'Some portfolios contain investments that are not mapped to investments in the Data Library. Please ensure all investments are mapped in order to continue.';
const HAS_DUPLICATE_ALLOCATIONS_TOOLTIP_MESSAGE =
  'Ensure that for any given strategy, only a single instance of an investment is included.';

export const useFooter = ({ isHistorical }: { isHistorical: boolean }) => {
  const {
    actions: { onBackButtonClick, completeUpload },
    data: {
      existingParsedData,
      newParsedData,
      savePortfoliosMutationState: { isIdle },
    },
  } = useContext(MultiPortfolioReviewContext);
  const includedParsedData = [...existingParsedData, ...newParsedData];
  const anyPortfolioHasUnmatchedFunds = includedParsedData.some((parsedResult) =>
    hasUnmatchedFunds(parsedResult.parsedPortfolio, parsedResult.excludedInvestments),
  );
  const anyPortfolioHasDuplicateInvestments =
    isHistorical &&
    includedParsedData.some((parsedResult) =>
      historicalPortfolioHasDuplicatedInvestments(parsedResult.parsedPortfolio, parsedResult.excludedInvestments),
    );

  return {
    onCancel: onBackButtonClick,
    onContinue: completeUpload,
    disabled: !isIdle || anyPortfolioHasUnmatchedFunds || anyPortfolioHasDuplicateInvestments,
    primaryTooltip: anyPortfolioHasUnmatchedFunds
      ? NEEDS_MAPPING_TOOLTIP_MESSAGE
      : anyPortfolioHasDuplicateInvestments
        ? HAS_DUPLICATE_ALLOCATIONS_TOOLTIP_MESSAGE
        : undefined,
  };
};
