import React, { useCallback, useState } from 'react';
import { type ProxyTypeEnum, type DetailedProxyMetadata } from 'venn-api';
import { Icon, Tooltip } from 'venn-ui-kit';
import { useHasFF } from 'venn-utils';
import SliderToggle from '../../../../slider-toggle/SliderToggle';
import { typeSupportsInterpolate } from '../utils';
import type { FundToBulkProxy } from '../../types';
import { isNil } from 'lodash';

export type ExtrapolationToggleProps = {
  toggleState: ToggleState;
  /** Extrapolation can be toggled but we still shouldn't extrapolate; this boolean represents whether we should perform extrapolation. */
  shouldExtrapolate: boolean;
  onToggle: () => void;
};

type ToggleState =
  | {
      state: 'hidden';
      reason: string;
      toggled?: undefined;
    }
  | {
      state: 'disabled';
      toggled: boolean;
      reason: string;
    }
  | {
      state: 'enabled';
      toggled: boolean;
      reason?: undefined;
    };

export const ExtrapolationToggle = ({ toggleState, onToggle }: ExtrapolationToggleProps) => {
  if (toggleState.state === 'hidden') return null;
  return (
    <div className="flex flex-col gap-2 font-bold text-[14px] leading-6" data-testid="qa-proxy-extrapolation-toggle">
      <div className="flex gap-0.5 items-center">
        Extrapolation
        <Tooltip
          usePortal
          data-testid="qa-extrapolation-tooltip"
          content="Enable extrapolation to extend performance forward."
        >
          <Icon className="ml-1 text-venn-grey-80" type="circle-info" prefix="far" />
        </Tooltip>
      </div>
      <SliderToggle
        tooltipMessage={toggleState.reason}
        disabled={toggleState.state === 'disabled'}
        variant="new"
        toggled={toggleState.toggled}
        onToggle={onToggle}
      />
    </div>
  );
};

export function useExtrapolateToggle(
  investments: FundToBulkProxy[],
  selectedProxyType: ProxyTypeEnum | undefined,
  proxyDataByFund: Record<string, DetailedProxyMetadata | undefined>,
): ExtrapolationToggleProps {
  const hasExtrapolationFf = useHasFF('extrapolation_ff');

  const [toggled, setToggled] = useState(() => {
    return !!investments.length && investments.every((investment) => proxyDataByFund[investment.id]?.extrapolate);
  });

  const shouldExtrapolate = hasExtrapolationFf && (selectedProxyType === 'EXTRAPOLATE' || toggled);

  const toggleState: ToggleState = (() => {
    if (!hasExtrapolationFf) {
      return { state: 'hidden', reason: 'User does not have access to extrapolation.' };
    }
    if (isNil(selectedProxyType)) {
      return { state: 'disabled', reason: 'Select a proxy type to enable extrapolation.', toggled: false };
    }
    if (selectedProxyType === 'EXTRAPOLATE') {
      return { state: 'hidden', reason: 'Extrapolation is already selected as the proxy type' };
    }
    if (selectedProxyType === 'SUBSTITUTE') {
      return {
        state: 'hidden',
        reason: 'Substitute is selected as the proxy type and supercedes extrapolation.',
      };
    }
    if (
      typeSupportsInterpolate(selectedProxyType) ||
      investments.some((investment) => {
        const frequency = proxyDataByFund[investment.id]?.unproxiedFrequency ?? investment.returnFrequency;
        return frequency !== 'QUARTERLY';
      })
    ) {
      return { state: 'enabled', toggled };
    }

    return {
      state: 'disabled',
      toggled: false,
      reason: "Quarterly investments can't be extrapolated without interpolation",
    };
  })();

  return {
    toggleState,
    shouldExtrapolate,
    onToggle: useCallback(() => {
      if (toggleState.state === 'enabled') {
        setToggled((v) => !v);
      }
    }, [toggleState.state]),
  };
}
