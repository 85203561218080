import type { PortfolioRange, InvestmentRange, FrequencyEnum as Frequency, FrequencyEnum } from 'venn-api';
import moment from 'moment';
import { SchemeAnalysisRequiredPeriod, Dates } from 'venn-utils';
import { maxBy, isNil, compact } from 'lodash';

export interface ConstrainingInvestment {
  range: InvestmentRange;
  type?: 'Comparison' | 'Benchmark';
}

export function processErrorMessage(message: string): string {
  if (message && message.length > 0 && message.endsWith('.')) {
    return message.substring(0, message.length - 1);
  }
  return message;
}

/** Baseline and benchmark should come before any other investments, if they are constraining analysis. */
export function mapRangeToConstrainingInvestments(portfolioRange: PortfolioRange): ConstrainingInvestment[] {
  const result: ConstrainingInvestment[] = [];

  if (portfolioRange.secondary) {
    result.push({ range: portfolioRange.secondary, type: 'Comparison' });
  }

  if (portfolioRange.benchmark) {
    result.push({ range: portfolioRange.benchmark, type: 'Benchmark' });
  }

  result.push(...portfolioRange.investments.map((range) => ({ range })));

  return result;
}

export function getInvestmentsConstrainingStart(portfolioRange?: PortfolioRange): ConstrainingInvestment[] | undefined {
  if (!portfolioRange || !portfolioRange.investments || portfolioRange.investments.length === 0) {
    return undefined;
  }

  const allInvestments = mapRangeToConstrainingInvestments(portfolioRange);
  if (allInvestments.length === 0) {
    return undefined;
  }

  let latestStart: number = allInvestments[0].range.start;
  let filteredInvestments: ConstrainingInvestment[] = [allInvestments[0]];

  for (let i = 1; i < allInvestments.length; i++) {
    const investment = allInvestments[i].range;
    if (investment.start > latestStart) {
      latestStart = investment.start;
      filteredInvestments = [allInvestments[i]];
    } else if (investment.start === latestStart) {
      filteredInvestments.push(allInvestments[i]);
    }
  }

  return filteredInvestments;
}

export function getInvestmentsConstrainingEnd(portfolioRange?: PortfolioRange): ConstrainingInvestment[] | undefined {
  if (!portfolioRange || !portfolioRange.investments || portfolioRange.investments.length === 0) {
    return undefined;
  }

  const allInvestments = mapRangeToConstrainingInvestments(portfolioRange);
  if (allInvestments.length === 0) {
    return undefined;
  }

  let earliestEnd: number = allInvestments[0].range.end;
  let filteredInvestments: ConstrainingInvestment[] = [allInvestments[0]];

  for (let i = 1; i < allInvestments.length; i++) {
    const investment = allInvestments[i].range;
    if (investment.end < earliestEnd) {
      earliestEnd = investment.end;
      filteredInvestments = [allInvestments[i]];
    } else if (investment.end === earliestEnd) {
      filteredInvestments.push(allInvestments[i]);
    }
  }

  return filteredInvestments;
}

const FrequencyPriority = new Map<Frequency, number>([
  ['DAILY', 1],
  ['WEEKLY', 1000000],
  ['MONTHLY', 30],
  ['QUARTERLY', 90],
  ['YEARLY', 365],
  ['UNKNOWN', 1000000],
]);

export function getPrimaryPortfolioStartAndEnd(portfolioRange: PortfolioRange): [number, number] {
  return [
    Math.max(...compact(portfolioRange.investments.map(({ start }) => start))),
    Math.min(...compact(portfolioRange.investments.map(({ end }) => end))),
  ];
}

export function getRangeStartAndEnd(portfolioRange: PortfolioRange): [number, number] {
  const [primaryStart, primaryEnd] = getPrimaryPortfolioStartAndEnd(portfolioRange);
  return [
    Math.max(
      ...compact([
        primaryStart,
        portfolioRange.benchmark?.start,
        portfolioRange.secondary?.start,
        portfolioRange.factors?.[0]?.start,
      ]),
    ),
    Math.min(
      ...compact([
        primaryEnd,
        portfolioRange.benchmark?.end,
        portfolioRange.secondary?.end,
        portfolioRange.factors?.[0]?.end,
      ]),
    ),
  ];
}

export function getRangeFrequency(portfolioRange: PortfolioRange): FrequencyEnum {
  return (
    maxBy(
      compact([
        ...portfolioRange.investments.map(({ frequency }) => frequency),
        portfolioRange.benchmark?.frequency,
        portfolioRange.secondary?.frequency,
      ]),
      (frequency: FrequencyEnum) => FrequencyPriority.get(frequency),
    ) ?? 'UNKNOWN'
  );
}

export function getMaxFrequency(frequencies: FrequencyEnum[]): FrequencyEnum {
  return maxBy(frequencies, (frequency: FrequencyEnum) => FrequencyPriority.get(frequency)) ?? 'UNKNOWN';
}

export function getPortfolioFrequency(portfolioRange?: PortfolioRange): Frequency | undefined {
  if (!portfolioRange) return undefined;
  return maxBy(
    portfolioRange.investments.map(({ frequency }) => frequency),
    (frequency: FrequencyEnum) => FrequencyPriority.get(frequency),
  );
}

export function getRequiredReturnYearsForDrawdown(frequency: Frequency): number {
  return SchemeAnalysisRequiredPeriod()?.[frequency]?.lengthInYears || 12;
}

export function getPeriodLength(start: number, end: number): string {
  const units: moment.unitOfTime.Diff[] = ['years', 'months', 'days'];
  let lengthInUnit = 0;
  let unit: moment.unitOfTime.Diff | string | undefined;
  for (const diff of units) {
    lengthInUnit = moment(end).diff(start, diff);
    unit = diff;
    if (lengthInUnit > 0) {
      break;
    }
  }

  if (unit && lengthInUnit === 1) {
    unit = unit.substr(0, unit.length - 1);
  }

  return `${lengthInUnit} ${unit}`;
}

export function isPeriodSet(start?: number, end?: number, isFactorTrend?: boolean): boolean {
  if (isFactorTrend) {
    return !isNil(end);
  }
  return !isNil(start) && !isNil(end);
}

function isPeriodValid(start?: number, end?: number): boolean {
  return !isNil(start) && !isNil(end) && start <= end;
}

export const getPeriodFormatterForInsufficientReturns =
  (frequency: FrequencyEnum, availableStart?: number, availableEnd?: number, noOverlap?: boolean) =>
  (start?: number, end?: number, formatDateFrequency: FrequencyEnum = frequency): string => {
    const [dateStart, dateEnd, period] = isPeriodValid(start, end)
      ? [start, end, 'initial']
      : isPeriodValid(availableStart, availableEnd)
        ? [availableStart, availableEnd, 'full']
        : noOverlap
          ? [undefined, undefined, 'not available']
          : [undefined, undefined, 'not set'];
    return period === 'initial' || period === 'full'
      ? `${Dates.toDDMMMYYYY(moment.utc(dateStart).valueOf(), formatDateFrequency)} - ${Dates.toDDMMMYYYY(
          moment.utc(dateEnd).valueOf(),
          formatDateFrequency,
        )}${period === 'full' ? ' (entire available period)' : ''}`
      : period;
  };
