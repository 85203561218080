import type { Portfolio, SimpleFund, SearchFund, Fund, InvestmentFactorForecast } from 'venn-api';
import immutableAssign from 'immutable-assign';
import { get, cloneDeep, sumBy, isNil, uniqBy } from 'lodash';
import { logMessageToSentry } from '../error-logging';
import type { AnyDuringEslintMigration } from '../type/any';
import { traversePortfolio } from './portfolioUtils';

export * from './allocation-panel-state-utils';
export * from './allocationFormatUtils';
export * from './portfolioUtils';
export * from './flattening';

/**
 * Consider using traversePortfolio for better performance if you don't actually need a flattened array output.
 * For example, if you can short-circuit your algorithm, traversePortfolio should be much faster.
 */
export const flattenNode = <T extends { children?: T[] }>(portfolio: T | undefined): T[] => {
  return Array.from(traversePortfolio(portfolio));
};

export const countFunds = (portfolio?: Portfolio): number => {
  const fundIds = new Set<string>();
  for (const node of traversePortfolio(portfolio)) {
    if (node.fund) {
      fundIds.add(node.fund.id);
    }
  }
  return fundIds.size;
};

export const countForecastableFunds = (
  portfolio: Portfolio | undefined,
  investmentForecasts: { [key: string]: InvestmentFactorForecast } | undefined,
): number => {
  if (isNil(investmentForecasts)) {
    return 0;
  }

  const fundIds = new Set<string>();
  for (const node of traversePortfolio(portfolio)) {
    if (node.fund && investmentForecasts[node.fund.id]) {
      fundIds.add(node.fund.id);
    }
  }
  return fundIds.size;
};

export const hasMultipleFunds = (portfolio?: Portfolio): boolean => {
  let count = 0;
  for (const node of traversePortfolio(portfolio)) {
    if (node.fund) {
      if (++count > 1) {
        return true;
      }
    }
  }
  return false;
};

export const getDisabledOptimizationMessage = (
  portfolio?: Portfolio,
  investmentForecasts?: { [key: string]: InvestmentFactorForecast },
): string | undefined => {
  const fundsCount = countForecastableFunds(portfolio, investmentForecasts);

  if (fundsCount >= 2) {
    return undefined;
  }
  return fundsCount === 1
    ? 'Add another investment with at least 1 year of returns to enable Optimization'
    : 'Add at least two investments with 1+ years of returns to enable Optimization';
};

export function getPortfolioLevelSelected(current?: Portfolio, selected?: number, level = 0): number | undefined {
  if (!current) {
    return undefined;
  }
  if (current.id === selected) {
    return level;
  }

  const currentChildren = current.children;
  if (currentChildren) {
    for (const child of currentChildren) {
      const childLevel = getPortfolioLevelSelected(child, selected, level + 1);
      if (childLevel !== undefined) {
        return childLevel;
      }
    }
  }
  return undefined;
}

export function selectStrategy(strategyId: number, portfolio: Portfolio | null): Portfolio | null {
  if (isNil(portfolio) || portfolio.id === strategyId) {
    return portfolio;
  }
  for (const child of portfolio.children || []) {
    const found: Portfolio | null = selectStrategy(strategyId, child);
    if (found) {
      return found;
    }
  }
  return null;
}

const buildPathRecursive = (root: Portfolio, nodeId?: number, path = ''): string | null => {
  if (!nodeId) {
    return path;
  }

  if (root.id === nodeId) {
    return path;
  }

  if (!root.children) {
    return null;
  }

  for (let i = 0; i < root.children.length; i++) {
    const innerPath = buildPathRecursive(root.children[i], nodeId, `${path}.children.${i}`);
    if (innerPath) {
      return innerPath;
    }
  }

  return null;
};

const buildPath = (root: Portfolio, nodeId?: number) => {
  const path = buildPathRecursive(root, nodeId);

  if (path && path.length) {
    return path.substring(1, path.length);
  }

  return path;
};

export const updateNode = (
  portfolio: Portfolio,
  nodeId: number | undefined,
  modifyFn: (node: Portfolio) => Portfolio,
) =>
  immutableAssign(
    portfolio,
    (root) => {
      const path = buildPath(portfolio, nodeId);
      if (path) {
        return get(root, path);
      }
      return root;
    },
    (node) => modifyFn(cloneDeep(node)),
  );

export const findParent = (node: Portfolio, target?: Portfolio): Portfolio | undefined => {
  if (!node || !target || node.fund || !node.children || node.children.length === 0) {
    return undefined;
  }
  for (const child of node.children) {
    if (child.id === target.id) {
      return node;
    }
  }
  for (const child of node.children) {
    const parent = findParent(child, target);
    if (parent !== undefined) {
      return parent;
    }
  }
  return undefined;
};

const recursiveUpdatePortfolio = (portfolio: Portfolio, modifyFn: (node: Portfolio) => Portfolio) => {
  const updatedPortfolio = cloneDeep(portfolio);
  const recursiveModifyFn = (node: Portfolio, fn: (node: Portfolio) => Portfolio) => {
    const modified = fn(node);
    if (modified.children) {
      modified.children.forEach((child) => recursiveModifyFn(child, fn));
    }
  };
  recursiveModifyFn(updatedPortfolio, modifyFn);
  return updatedPortfolio;
};

export const recursiveUpdatePortfolioName = (portfolio: Portfolio) =>
  recursiveUpdatePortfolio(portfolio, (node) => {
    // We should not allow user to save empty name
    if (node && node.name === '') {
      node.name = '(Untitled strategy)';
    }
    return node;
  });

export const recursiveUpdateAllocation = (portfolio: Portfolio, total: number) => {
  if (!portfolio.allocation) {
    return portfolio;
  }
  const changedAllocation = portfolio.allocation;
  return recursiveUpdatePortfolio(portfolio, (node) => {
    if (node && node.allocation) {
      node.allocation = (node.allocation * total) / changedAllocation;
    }
    return node;
  });
};

export const recursiveClearBenchmarks = (portfolio: Portfolio) => {
  return recursiveUpdatePortfolio(portfolio, (node) => {
    node.compare = undefined;
    return node;
  });
};

/** Removes duplicate funds before returning. */
export const recursiveGetFunds = (portfolio?: Portfolio): SimpleFund[] => {
  const funds: SimpleFund[] = [];
  for (const node of traversePortfolio(portfolio)) {
    if (node.fund) {
      funds.push(node.fund);
    }
  }
  return uniqBy(funds, 'id');
};

/** Removes duplicate ids before returning. */
export const recursiveGetFundIds = (portfolio?: Portfolio): string[] => {
  const funds: string[] = [];
  for (const node of traversePortfolio(portfolio)) {
    const id = node.fund?.id;
    if (id) {
      funds.push(id);
    }
  }
  return [...new Set(funds)];
};

export const getFundsAndBenchmarksFromPortfolio = (portfolio: Portfolio): string[] => {
  if (!portfolio) {
    return [];
  }
  const funds = recursiveGetFundIds(portfolio);

  portfolio.compare?.forEach((item) => {
    if (item.fundId) {
      funds.push(item.fundId);
    }
  });

  // Remove deuplicated ids
  return [...new Set(funds)];
};

export interface NewFundDraft {
  id: string;
  name: string;
  allocation?: number;
  startRange?: number;
  endRange?: number;
  capitalCommitment?: number;
}

const getNewFunds = (investments: NewFundDraft[], draftId: number): Partial<Portfolio>[] =>
  investments.map((investment: NewFundDraft, index: number) => {
    const fund: Partial<SimpleFund> = {
      id: investment.id,
      name: investment.name,
      startRange: investment.startRange,
      endRange: investment.endRange,
    };
    return {
      allocation: investment.allocation || 0,
      children: [],
      compare: [],
      id: draftId + index,
      fund: fund as SimpleFund,
      name: investment.name,
    };
  });

interface FundWithNavs {
  id: string;
  name: string;
  allocation: number;
  portfolioNodeId?: number;
}

export type PortfolioWithLevel = Portfolio & {
  level: number;
};

/**
 * Returns a flat array of pairs of [portfolio node, corresponding comparison node] with levels of indentation.
 * Either 'portfolio node' or 'corresponding comparison node' can be undefined.
 */
export const getFlattenedNodesWithCompareAndLevel = (
  portfolio: Portfolio | undefined,
  compare: Portfolio | undefined,
  allCompareNodes: Map<number, Portfolio>,
  allGhostChildren: Map<number, Portfolio[]>,
  level = 0,
): [PortfolioWithLevel | undefined, PortfolioWithLevel | undefined][] => {
  if (!portfolio && !compare) {
    return [];
  }

  const portfolioWithLevel: PortfolioWithLevel | undefined = isNil(portfolio)
    ? undefined
    : {
        ...portfolio,
        level,
      };
  const compareWithLevel: PortfolioWithLevel | undefined = isNil(compare)
    ? undefined
    : {
        ...compare,
        level,
      };

  if (isNil(portfolioWithLevel)) {
    return (compareWithLevel?.children ?? []).reduce(
      (list: [PortfolioWithLevel | undefined, PortfolioWithLevel | undefined][], node: Portfolio) => [
        ...list,
        ...getFlattenedNodesWithCompareAndLevel(undefined, node, allCompareNodes, allGhostChildren, level + 1),
      ],
      [[undefined, compareWithLevel]],
    );
  }

  return (allGhostChildren.get(portfolioWithLevel.id) ?? []).reduce(
    (list: [PortfolioWithLevel | undefined, PortfolioWithLevel | undefined][], node: Portfolio) => [
      ...list,
      ...getFlattenedNodesWithCompareAndLevel(undefined, node, allCompareNodes, allGhostChildren, level + 1),
    ],
    portfolioWithLevel.children.reduce(
      (list: [PortfolioWithLevel | undefined, PortfolioWithLevel | undefined][], node: Portfolio) => [
        ...list,
        ...getFlattenedNodesWithCompareAndLevel(
          node,
          allCompareNodes.get(node.id),
          allCompareNodes,
          allGhostChildren,
          level + 1,
        ),
      ],
      [[portfolioWithLevel, compareWithLevel]],
    ),
  );
};

const updatePortfolioNavs = (fund: FundWithNavs, portfolio: Portfolio) => {
  if (!fund || !portfolio) {
    return false;
  }
  if (fund.portfolioNodeId === portfolio.id) {
    portfolio.allocation = fund.allocation;
    return true;
  }
  let foundFirstOne = false;
  portfolio.children &&
    portfolio.children.forEach((child) => {
      if (!foundFirstOne) {
        foundFirstOne = updatePortfolioNavs(fund, child);
      }
    });
  return foundFirstOne;
};

const updateNavs = (investments: FundWithNavs[], portfolio: Portfolio, selectedStrategyId?: number): Portfolio => {
  let newPortfolio = cloneDeep(portfolio);
  if (!investments || investments.length === 0) {
    return newPortfolio;
  }
  newPortfolio = updateNode(newPortfolio, selectedStrategyId || newPortfolio.id, (node: Portfolio) => {
    const updatedNode = { ...node };
    investments.forEach((investment) => {
      updatePortfolioNavs(investment, updatedNode);
    });
    return updatedNode;
  });
  return newPortfolio;
};

export const createPortfolioFromFunds = (investments: NewFundDraft[], addToNewStrategyName?: string): Portfolio => {
  const draftId = Date.now();
  const newFunds = getNewFunds(investments, draftId);
  const portfolioChildren = addToNewStrategyName
    ? [
        {
          allocation: 0,
          compare: [],
          children: newFunds,
          name: addToNewStrategyName,
        },
      ]
    : newFunds;
  const newPortfolio = {
    name: 'New Portfolio',
    id: draftId - 1,
    allocation: 0,
    demo: false,
    locked: false,
    master: false,
    children: portfolioChildren,
    draft: true,
    compare: [],
    tags: [],
    historical: false,
    closingAllocationsTs: [],
  };

  return newPortfolio as Portfolio;
};

export const recursiveUpdateAllocations = (node: Portfolio): Portfolio => {
  if (!node || node.fund) {
    return node;
  }
  if (!node.children || node.children.length === 0) {
    return {
      ...node,
      allocation: 0,
    };
  }
  const newChildren = node.children.map((child: Portfolio) => {
    return recursiveUpdateAllocations(child);
  });
  return {
    ...node,
    children: newChildren,
    allocation: sumBy(newChildren, (child: Portfolio) => child.allocation || 0),
  };
};

export type InvestmentMixedType = SimpleFund | FundWithNavs | SearchFund | NewFundDraft;

export const addInvestmentsToPortfolio = (
  investments: InvestmentMixedType[],
  portfolio: Portfolio,
  selectedStrategyId?: number,
  addToNewStrategyName?: string,
): { updatedPortfolio: Portfolio; newFunds?: Portfolio[] } => {
  const draftId = Date.now();
  // update existing funds nav (SimpleFund doesn't have portfolioNodeId)
  const existingFunds: FundWithNavs[] = investments.filter(
    (investment: InvestmentMixedType) => (investment as FundWithNavs).portfolioNodeId,
  ) as FundWithNavs[];

  // will do nothing if the investments passed don't have navs
  // update for the right strategy id (!)
  const updatedPortfolio = updateNavs(existingFunds, portfolio, selectedStrategyId);

  const strategy = selectStrategy(selectedStrategyId || portfolio.id, portfolio) || portfolio;
  if (strategy.fund) {
    // If the selected strategy is a fund, we do NOT want to add any children to it(!)
    // We should never get here in the first place (the functionality should be disabled for funds).
    // But let's suppose there's no harm in updating the NAV if it was matched to this fund, so let's update this one.
    // But ignore the navs that we haven't been able to map, ignore the investments that we might have just uploaded.
    return { updatedPortfolio };
  }

  // add new funds (with nav or without - if without, then set the allocation to 0)
  const newFunds: NewFundDraft[] = investments.filter(
    (investment: InvestmentMixedType) => !(investment as FundWithNavs).portfolioNodeId && investment.id,
  );
  const newFundPortfolios: Partial<Portfolio>[] = getNewFunds(newFunds, draftId);

  let newPortfolio: Portfolio = cloneDeep(updatedPortfolio);
  if (!selectedStrategyId && addToNewStrategyName) {
    newPortfolio.children = [
      ...newPortfolio.children,
      {
        allocation: 0,
        compare: [],
        children: newFundPortfolios,
        name: addToNewStrategyName,
      } as AnyDuringEslintMigration,
    ];
    return { updatedPortfolio: recursiveUpdateAllocations(newPortfolio), newFunds: newFundPortfolios as Portfolio[] };
  }

  const actuallyAddedFunds: Portfolio[] = [];
  // Update the children of the right strategy(!) with new funds (from upload, or non-matched navs)
  newPortfolio = updateNode(newPortfolio, selectedStrategyId || newPortfolio.id, (node: Portfolio) => {
    const newChildren = [...node.children];
    // @ts-expect-error: TODO fix strictFunctionTypes
    newFundPortfolios.forEach((fund: Portfolio) => {
      // Only add a fund to a strategy if it's not already in there.
      if (
        newChildren.find((existing: Portfolio) => existing.fund && existing.fund.id === fund.fund?.id) === undefined
      ) {
        newChildren.push(fund);
        actuallyAddedFunds.push(fund);
      }
    });
    return {
      ...node,
      children: newChildren,
    };
  });
  return { updatedPortfolio: recursiveUpdateAllocations(newPortfolio), newFunds: actuallyAddedFunds };
};

export const searchPortfolioNodeId = (fundId?: string, portfolio?: Portfolio): number | undefined => {
  if (!fundId || !portfolio) {
    return undefined;
  }
  if (portfolio.fund && portfolio.fund.id === fundId) {
    return portfolio.id;
  }
  if (!portfolio.children) {
    return undefined;
  }
  let id: number | undefined;
  portfolio.children.forEach((item) => {
    if (id) {
      return;
    }
    const nodeId = searchPortfolioNodeId(fundId, item);
    id = nodeId || id;
  });
  return id;
};

export function updateAllFunds(portfolio: Portfolio, allFunds: Map<string, Fund>): Portfolio {
  const updateIfMatchingFund = (node: Portfolio): Portfolio => {
    if (node.fund && allFunds.has(node.fund.id)) {
      const updatedFund = allFunds.get(node.fund.id);

      if (updatedFund === undefined) {
        logMessageToSentry('Error on update fund');
        return node;
      }

      return {
        ...node,
        name: updatedFund.name,
        fund: updatedFund,
      };
    }
    return {
      ...node,
      children: (node.children || []).map((child: Portfolio) => updateIfMatchingFund(child)),
    };
  };
  return updateIfMatchingFund(portfolio);
}

export function searchPortfolio(
  node: Portfolio,
  parent: Portfolio | undefined,
  rootOfSelectedSubtreeId: number,
  isInSubtree: boolean,
  fundCallback: (node: Portfolio, parent?: Portfolio, rootOfSelectedSubtreeId?: number, isInSubtree?: boolean) => void,
  strategyCallback: (
    node: Portfolio,
    parent?: Portfolio,
    rootOfSelectedSubtreeId?: number,
    isInSubtree?: boolean,
  ) => void,
): void {
  if (node == null) {
    return;
  }
  // If fund
  if (node.fund != null) {
    fundCallback(node, parent, rootOfSelectedSubtreeId, isInSubtree);
    return;
  }
  // Else, if strategy
  strategyCallback(node, parent, rootOfSelectedSubtreeId, isInSubtree);
  node.children.forEach((child) =>
    searchPortfolio(
      child,
      node,
      rootOfSelectedSubtreeId,
      isInSubtree || child.id === rootOfSelectedSubtreeId,
      fundCallback,
      strategyCallback,
    ),
  );
}

export const mapPortfolioToParentNodes = (portfolio: Portfolio): Map<number, number | undefined> => {
  const mappedNodes: Map<number, number | undefined> = new Map<number, number | undefined>();
  const setForChildren = (node: Portfolio, parentId?: number) => {
    mappedNodes.set(node.id, parentId);
    if (node.fund) {
      return;
    }
    node.children.forEach((child) => {
      setForChildren(child, node.id);
    });
  };
  setForChildren(portfolio);
  return mappedNodes;
};

export const mapPortfolioIdsToNewPortfolioIds = (prevPortfolio: Portfolio, createdPortfolio: Portfolio) => {
  const mappedNodes: Map<number, number | undefined> = new Map<number, number>();
  const setForChildren = (prev: Portfolio, created: Portfolio) => {
    mappedNodes.set(prev.id, created.id);
    if (prev.fund) {
      return;
    }
    prev.children.forEach((prevChild, idx) => {
      setForChildren(prevChild, created.children[idx]);
    });
  };
  setForChildren(prevPortfolio, createdPortfolio);
  return mappedNodes;
};
