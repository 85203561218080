import type { FC } from 'react';
import React from 'react';
import moment from 'moment';
import { getAppTitle } from 'venn-ui-kit';

export interface CopyrightProps {
  className?: string;
  lineBreak?: boolean;
  year?: number | string;
}

const Copyright: FC<React.PropsWithChildren<CopyrightProps>> = ({
  className = '',
  lineBreak = false,
  year = moment.utc().format('YYYY'),
}) => (
  <div className={className}>
    {`© ${year} Two Sigma Investments, LP. All rights reserved. `}
    {lineBreak && <br />}
    {`Two Sigma, the Two Sigma logo, ${getAppTitle()} and the ${getAppTitle()} logo are trademarks of Two Sigma Investments, LP.`}
  </div>
);

export default Copyright;
