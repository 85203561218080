import { getFundReturnsRange, type FundReturnsRange, type DetailedProxyMetadata, type SimpleFund } from 'venn-api';
import { createFundReturnsRangeKey, useQuery } from 'venn-utils';
import { getReturnsRangeFromError } from './utils';
import { useMemo } from 'react';
import type { FundToProxy } from '../types';

export function useInvestmentsReturnsRanges(
  investments: (FundToProxy | SimpleFund)[],
  proxyDataByFund: Record<string, DetailedProxyMetadata | undefined>,
): FundReturnsRange[] {
  const unproxiedReturnsRanges: FundReturnsRange[] = useMemo(() => {
    return investments.map((investment) => {
      const proxyData = proxyDataByFund[investment.id];
      return {
        start: proxyData ? proxyData.unproxiedStartDate : investment.startRange,
        end: proxyData ? proxyData.unproxiedEndDate : investment.endRange,
        frequency: proxyData ? proxyData.unproxiedFrequency : investment.returnFrequency,
      };
    });
  }, [investments, proxyDataByFund]);

  return unproxiedReturnsRanges;
}

/** Returns data as null if the proxyId is undefined. */
export function useProxyReturnsRange(selectedProxyId: string | undefined) {
  return useQuery(createFundReturnsRangeKey(selectedProxyId ?? '-'), async ({ signal }) =>
    selectedProxyId ? fetchFundReturnsRange(selectedProxyId, signal) : null,
  );
}

function fetchFundReturnsRange(id: string, signal: AbortSignal | undefined) {
  return getFundReturnsRange(true, id, signal)
    .then((res) => res.content)
    .catch((e) => getReturnsRangeFromError(e) ?? null);
}
