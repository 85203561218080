import React from 'react';
import { useRecoilValue } from 'recoil';
import { blockInfoGraphicType, blockSettings, customizedBlock, isReportState, PRINT_CHART_HEIGHT } from 'venn-state';
import HoldingsPieChart from '../../../studio-blocks/components/charts/HoldingsPieChart';
import { useAppPrintMode } from '../../../print';
import { useBlockContext } from '../../contexts/block-context';
import MapChart from '../../../studio-blocks/components/charts/map-chart/MapChart';
import { useHoldings } from '../../../studio-blocks';
import { assertNotNil, type CustomBlockTypeEnum } from 'venn-utils';
import HoldingsTreeGrid from '../../../studio-blocks/components/grid/HoldingsTreeGrid';
import HoldingsDataGrid from '../../../studio-blocks/components/grid/HoldingsDataGrid';
import { HoldingsBarChart } from '../../../studio-blocks/components/charts/HoldingsBarChart';

interface HoldingsProps {
  id: string;
}

export const HoldingsContent = ({ id }: HoldingsProps) => {
  const infoGraphicType = useRecoilValue(blockInfoGraphicType(id));
  const { inPrintMode } = useAppPrintMode();
  const isReport = useRecoilValue(isReportState);
  const selectedBlock = useRecoilValue(customizedBlock(id));
  const { blockRef: downloadableContentRef } = useBlockContext();
  const { data } = useHoldings(id);

  const holdingsData = assertNotNil(data);
  const height = inPrintMode && !isReport ? PRINT_CHART_HEIGHT : undefined;
  const treeDataBlocks: CustomBlockTypeEnum[] = ['SECTOR_EXPOSURE', 'GEOGRAPHY_EXPOSURE'];
  const blockSetting = useRecoilValue(blockSettings(id));
  const type = blockSetting.customBlockType;
  const isTreeDataBlock = treeDataBlocks.includes(type);

  if (infoGraphicType === 'PIE') {
    return (
      <HoldingsPieChart
        height={height}
        selectedBlock={selectedBlock}
        selectedRefId={id}
        downloadableContentRef={downloadableContentRef}
        inPrintMode={inPrintMode}
      />
    );
  }

  if (infoGraphicType === 'MAP') {
    return (
      <MapChart
        height={height}
        holdingsData={holdingsData}
        selectedRefId={id}
        selectedBlock={selectedBlock}
        downloadableContentRef={downloadableContentRef}
      />
    );
  }

  if (isTreeDataBlock && infoGraphicType === 'GRID') {
    return <HoldingsTreeGrid selectedRefId={id} isBarChart={false} inPrintMode={isReport} />;
  }

  if (!isTreeDataBlock && infoGraphicType === 'GRID') {
    return <HoldingsDataGrid selectedRefId={id} isBarChart={false} inPrintMode={isReport} />;
  }
  if (infoGraphicType === 'BAR') {
    return <HoldingsBarChart selectedRefId={id} />;
  }

  return null;
};
