import React, { memo } from 'react';

import { usePrivateAnalysis, DATA_TYPE_EXCEL_STYLES } from '../../../studio-blocks';

import { useIsBlockGridCompact } from '../../../studio-blocks/logic/useIsBlockGridCompact';
import { useCommonGridProps } from '../../../studio-blocks/logic/columnUtils';
import ExportableGrid from '../../../studio-blocks/components/grid/ExportableGrid';
import { usePrivateAssetSummaryAnalysis } from '../../../studio-blocks/logic/usePrivateAssetSummaryAnalysis';
import usePrivateAssetsSummaryGrid from '../../../studio-blocks/logic/usePrivateAssetsSummaryGrid';

interface PrivateAssetSummaryContentProps {
  id: string;
}

export const PrivateAssetSummaryContent = ({ id }: PrivateAssetSummaryContentProps) => {
  const { isExportable } = usePrivateAnalysis(id);
  return <Grid isExportable={isExportable} id={id} />;
};

type GridProps = {
  isExportable: boolean;
  id: string;
};

const Grid = memo(function InternalGrid({ isExportable, id }: GridProps) {
  const privateAssetSummaryResponse = usePrivateAssetSummaryAnalysis();
  const { columnDefs, rowData } = usePrivateAssetsSummaryGrid(privateAssetSummaryResponse);
  const isCompact = useIsBlockGridCompact();

  const commonGridProps = useCommonGridProps();

  return (
    <ExportableGrid
      {...commonGridProps}
      exportable={isExportable}
      selectedRefId={id}
      rowData={rowData}
      columnDefs={columnDefs}
      excelStyles={DATA_TYPE_EXCEL_STYLES}
      isCompact={isCompact}
      tooltipShowDelay={1200}
    />
  );
});
