import type { FunctionComponent, ReactNode } from 'react';
import React from 'react';
import styled, { css } from 'styled-components';
import { noop } from 'lodash';
import { GetColor } from '../../style/color';
import { ColorUtils } from '../../style/colorUtils';
import type { IconProps } from '../icon/Icon';
import { Icon, IconPosition } from '../icon/Icon';
import type { FontAwesomePrefixes } from 'venn-utils';
import { destructiveColorThemes, type ConcreteColors, standardColorThemes } from './ButtonColors';

export interface StyledIconProps {
  /** Position of the icon - defaults to left */
  iconPosition?: IconPosition;
}

export type ButtonProps = StyledIconProps &
  Pick<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    'id' | 'className' | 'disabled' | 'type' | 'style' | 'tabIndex' | 'onClick'
  > & {
    /** Inside text of the button, beside the icon */
    children?: ReactNode;
    /** Should the button be filled with color (background and border) */
    dominant?: boolean;
    /** Should the button be for destructive actions */
    destructive?: boolean;
    /** Should the button be dense with a smaller height */
    dense?: boolean;
    /** Should the button be flat, no border or background */
    flat?: boolean;
    /** @see {@link Icon} type. */
    icon?: string;
    /** Remove margins from the button * */
    noMargin?: boolean;
    /** Text before Icon */
    prefix?: string;
    /** [Font Awesome](https://fontawesome.com/) prefixes */
    iconPrefix?: FontAwesomePrefixes;
    /** Appears as hovered when in the selected state */
    selected?: boolean;
    /** Keeps the button in it's active style */
    active?: boolean;
    /**
     * If this button is actually a link, the link to navigate to.
     * If present, the <button> will be changed to use <a> instead but will have the same styling.
     */
    link?: string;
    /**
     * If this button is actually a link, use target to specify where to open the linked document
     */
    target?: string;
    /**
     * If this button is actually a link, use rel to specify the relationship between the current one and the linked document
     */
    rel?: string;
    /**
     * Test ID for locating button in unit and E2E tests
     */
    'data-testid'?: string;
    /**
     * Whether new styling that will be part of the new design system should be used.
     * Historically we've had buttons take shades of teal, refreshed styling takes shades of blue.
     */
    refreshedStyling?: boolean;
  };

// The height of the icon must be 13px to match the height of the Roboto text
const StyledIcon = styled(Icon)<StyledIconProps & IconProps>`
  height: 13px;
  margin-right: ${(props: StyledIconProps) => (props.iconPosition === IconPosition.Left ? '10px' : '0')};
  margin-left: ${(props: StyledIconProps) => (props.iconPosition === IconPosition.Right ? '10px' : '0')};
`;

type Theming = { colorTheme: ConcreteColors };

export const dominantCSS = css`
  border-color: ${GetColor.Primary.Main};
  background-color: ${GetColor.Primary.Main};
  color: ${GetColor.Black};

  &:hover {
    border-color: ${GetColor.Primary.Dark};
    background-color: ${GetColor.Primary.Dark};
    color: ${GetColor.White};
  }

  &:active {
    background-color: ${ColorUtils.opacifyDarkFrom(GetColor.Primary.Dark, 0.1)};
    border-color: ${ColorUtils.opacifyDarkFrom(GetColor.Primary.Dark, 0.1)};
    color: ${GetColor.White};
  }

  &:disabled {
    color: ${GetColor.MidGrey2};
    border-color: ${GetColor.Grey};
    background-color: ${GetColor.Grey};
  }
`;

export const refreshedDominantCSS = css`
  border-color: ${GetColor.DarkBlue};
  background-color: ${GetColor.DarkBlue};
  color: ${GetColor.White};

  &:hover {
    border-color: ${GetColor.DeepDarkBlue};
    background-color: ${GetColor.DeepDarkBlue};
    color: ${GetColor.White};
  }

  &:active {
    background-color: ${ColorUtils.opacifyDarkFrom(GetColor.DeepDarkBlue, 0.1)};
    border-color: ${ColorUtils.opacifyDarkFrom(GetColor.DeepDarkBlue, 0.1)};
    color: ${GetColor.White};
  }

  &:disabled {
    color: ${GetColor.MidGrey2};
    border-color: ${GetColor.Grey};
    background-color: ${GetColor.Grey};
  }
`;

export const destructiveCSS = css`
  border-color: ${GetColor.Error};
  background-color: ${GetColor.Alert};
  color: ${GetColor.Black};

  &:hover,
  &:active,
  &:focus {
    border-color: ${GetColor.Error};
    color: ${GetColor.Black};
    background-color: ${ColorUtils.opacifyDarkFrom(GetColor.Alert, 0.1)};
  }
`;

const refreshedDestructiveCSS = css<Theming>`
  color: ${(props) => props.colorTheme.color(props)};
  background-color: ${(props) => props.colorTheme.backgroundColor(props)};
  border-color: ${(props) => props.colorTheme.borderColor(props)};

  &:hover,
  &:active,
  &:focus {
    color: ${(props) => props.colorTheme.color(props)};
    border-color: ${(props) => props.colorTheme.borderColor(props)};
    background-color: ${(props) => ColorUtils.mixFrom(props.colorTheme.backgroundColor, props.colorTheme.color, 0.05)};
  }
`;

const activeCSS = css`
  background-color: ${ColorUtils.opacifyFrom(GetColor.Primary.Dark, 0.05)};
  color: ${GetColor.Primary.Dark};
`;

const refreshedActiveCSS = css`
  background-color: ${ColorUtils.opacifyFrom(GetColor.DarkBlue, 0.05)};
  color: ${GetColor.DarkBlue};
`;

const flatCSS = css`
  border: none;
  background-color: transparent;
  color: ${GetColor.Primary.Dark};
  min-height: 35px;

  &:hover {
    color: ${GetColor.Primary.Main};
    background-color: transparent;
  }

  &:disabled {
    background-color: transparent;
    color: ${GetColor.Grey};
    cursor: not-allowed;
    pointer-events: none;
  }
`;

const refreshedFlatCSS = css`
  border: none;
  background-color: transparent;
  color: ${GetColor.DarkBlue};
  min-height: 35px;

  &:hover {
    color: ${GetColor.DeepDarkBlue};
    background-color: transparent;
  }

  &:disabled {
    background-color: transparent;
    color: ${GetColor.Grey};
    cursor: not-allowed;
    pointer-events: none;
  }
`;

const StyledButton = styled.button<ButtonProps & Theming>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 88px;
  min-height: 40px;
  ${(props) => !props.noMargin && 'margin-right: 20px;'}
  padding: 9px 15px;
  border: 1px solid ${(props) => (props.refreshedStyling ? GetColor.DarkBlue : GetColor.Primary.Dark)};
  border-radius: 2px;
  background: ${GetColor.White};
  color: ${(props) => (props.refreshedStyling ? GetColor.DarkBlue : GetColor.Primary.Dark)};
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  flex-direction: ${(props) => (props.iconPosition === IconPosition.Right ? 'row-reverse' : 'row')};
  transition: all 0.2s ease-out;
  line-height: normal;

  &:hover {
    border-color: ${(props) => (props.refreshedStyling ? GetColor.DarkBlue : GetColor.Primary.Dark)};
    background-color: ${(props) =>
      props.refreshedStyling
        ? ColorUtils.opacifyFrom(GetColor.DarkBlue, 0.05)
        : ColorUtils.opacifyFrom(GetColor.Primary.Dark, 0.1)};
    color: ${(props) => (props.refreshedStyling ? GetColor.DarkBlue : GetColor.Primary.Dark)};
  }

  ${(props) =>
    props.selected &&
    css`
      border-color: ${props.refreshedStyling ? GetColor.DarkBlue : GetColor.Primary.Dark};
      background-color: ${props.refreshedStyling
        ? ColorUtils.opacifyFrom(GetColor.DarkBlue, 0.05)
        : ColorUtils.opacifyFrom(GetColor.Primary.Dark, 0.1)};
      color: ${GetColor.Primary.Dark};
    `}

  &:focus {
    border-color: ${(props) => (props.refreshedStyling ? GetColor.DeepDarkBlue : GetColor.HighlightDark)};
  }

  &:disabled {
    border-color: ${GetColor.MidGrey1};
    color: ${GetColor.MidGrey1};
    cursor: not-allowed;
    pointer-events: none;
  }

  &:last-child {
    margin-right: 0;
  }

  &:active {
    ${activeCSS}
  }

  ${(props) => (props.dominant ? (props.refreshedStyling ? refreshedDominantCSS : dominantCSS) : '')};

  ${(props) => (props.destructive ? (props.refreshedStyling ? refreshedDestructiveCSS : destructiveCSS) : '')}

  ${(props) => (props.active ? (props.refreshedStyling ? refreshedActiveCSS : activeCSS) : '')}

  ${(props) => (props.dense ? 'min-height: 27px; padding: 6px 15px;' : '')}

  ${(props) => (props.flat ? (props.refreshedStyling ? refreshedFlatCSS : flatCSS) : '')}
`;

/**
 * General purpose button.
 *
 * Any props not listed, will be passed directly to button.
 */
export const Button: FunctionComponent<ButtonProps> = ({
  children = null,
  prefix,
  dominant = false,
  flat = false,
  dense = false,
  icon = null,
  disabled = false,
  iconPosition = IconPosition.Left,
  onClick = noop,
  iconPrefix,
  link,
  ...restProps
}) => {
  const selectedThemes = restProps.destructive ? destructiveColorThemes : standardColorThemes;
  const selectedTheme = dominant ? selectedThemes.dominant : selectedThemes.outline;
  return (
    <StyledButton
      colorTheme={selectedTheme}
      onClick={onClick}
      dominant={dominant}
      dense={dense}
      flat={flat}
      iconPosition={iconPosition}
      disabled={disabled}
      role={link ? 'link' : 'button'}
      {...(link ? { as: 'a', href: link } : {})}
      {...restProps}
    >
      {prefix || ''}
      {icon && <StyledIcon type={icon} iconPosition={iconPosition} prefix={iconPrefix} />}
      <div>{children}</div>
    </StyledButton>
  );
};

export default Button;
