import React, { memo } from 'react';

import { useRecoilValue } from 'recoil';

import { blockInfoGraphicType, isReportState } from 'venn-state';

import { usePrivateAnalysis, METADATA_GREY_BG_CLASS, DATA_TYPE_EXCEL_STYLES } from '../../../studio-blocks';

import type { PrivatesAnalysisResponse } from 'venn-api';
import { useBlockContext } from '../../contexts/block-context';
import CashFlowChart from '../../../studio-blocks/components/charts/CashFlowChart';
import usePrivatesDataGrid from '../../../studio-blocks/logic/usePrivatesDataGrid';
import { useIsBlockGridCompact } from '../../../studio-blocks/logic/useIsBlockGridCompact';
import { useCommonGridProps } from '../../../studio-blocks/logic/columnUtils';
import type { ColDef } from 'ag-grid-community';
import { useCashFlowGridMetricErrors } from '../../../studio-blocks/components/charts/useCashFlowBlockMetricErrors';
import { GridWrapper } from '../../../studio-blocks/common';
import ExportableGrid from '../../../studio-blocks/components/grid/ExportableGrid';

interface PrivateCashFlowContentProps {
  id: string;
}

export const PrivateCashFlowContent = ({ id }: PrivateCashFlowContentProps) => {
  const isReport = useRecoilValue(isReportState);
  const { blockRef: downloadableContentRef } = useBlockContext();
  const infoGraphicType = useRecoilValue(blockInfoGraphicType(id));

  const { data, isExportable } = usePrivateAnalysis(id);

  if (infoGraphicType === 'PRIVATE_CASH_FLOW') {
    return <Chart data={data!} isExportable={isExportable} downloadableContentRef={downloadableContentRef} />;
  }
  if (infoGraphicType === 'GRID') {
    return <Grid data={data!} isExportable={isExportable} isReport={isReport} id={id} />;
  }
  return null;
};

type GridProps = {
  data: PrivatesAnalysisResponse;
  isExportable: boolean;
  isReport: boolean;
  id: string;
};

const Grid = memo(function InternalGrid({ data, isReport, isExportable, id }: GridProps) {
  const gridOverrides: ColDef = {
    cellClassRules: {
      [METADATA_GREY_BG_CLASS]: ({ data }) => data.isMetadata,
    },
  };
  const { rowData, columnDefs } = usePrivatesDataGrid(data);
  const isCompact = useIsBlockGridCompact();

  const commonGridProps = useCommonGridProps(gridOverrides);

  useCashFlowGridMetricErrors(data, id);

  return (
    <GridWrapper isReport={isReport}>
      <ExportableGrid
        {...commonGridProps}
        exportable={isExportable}
        selectedRefId={id}
        rowData={rowData}
        columnDefs={columnDefs}
        excelStyles={DATA_TYPE_EXCEL_STYLES}
        isCompact={isCompact}
        tooltipShowDelay={1200}
        suppressRowVirtualisation
      />
    </GridWrapper>
  );
});

type ChartProps = React.ComponentProps<typeof CashFlowChart>;

const Chart = memo(function InternalGrid({ data, isExportable, downloadableContentRef }: ChartProps) {
  return <CashFlowChart data={data} isExportable={isExportable} downloadableContentRef={downloadableContentRef} />;
});
