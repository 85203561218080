import type { FrequencyEnum } from 'venn-api';
import { FREQUENCY_DATEPICKER_MODES, FrequencyTypes } from './types';
import { maxBy, minBy } from 'lodash';

export const getDateMode = (frequencyId: FrequencyTypes | FrequencyEnum | undefined) =>
  FREQUENCY_DATEPICKER_MODES[frequencyId || FrequencyTypes.MONTHLY];

export const getFrequencyType = (frequency?: FrequencyTypes | FrequencyEnum) =>
  typeof frequency === 'string' ? FrequencyTypes[frequency] : frequency;

const frequencyTypeToReturnFrequencyEnum: { [k in FrequencyTypes]: FrequencyEnum } = {
  [FrequencyTypes.DAILY]: 'DAILY',
  [FrequencyTypes.MONTHLY]: 'MONTHLY',
  [FrequencyTypes.QUARTERLY]: 'QUARTERLY',
  [FrequencyTypes.YEARLY]: 'YEARLY',
};

export const toReturnFrequency = (frequencyType: FrequencyTypes): FrequencyEnum => {
  return frequencyTypeToReturnFrequencyEnum[frequencyType];
};

const frequencyToSortRank = {
  [FrequencyTypes.DAILY]: 1,
  [FrequencyTypes.MONTHLY]: 30,
  [FrequencyTypes.QUARTERLY]: 90,
  [FrequencyTypes.YEARLY]: 365,
  DAILY: 1,
  WEEKLY: 7,
  MONTHLY: 30,
  QUARTERLY: 90,
  YEARLY: 365,
  UNKNOWN: undefined,
} as const satisfies Record<FrequencyTypes | FrequencyEnum, number | undefined>;

type MaybeFrequencyEnum = FrequencyEnum | undefined | null;
type MaybeFrequencyType = FrequencyTypes | undefined | null;
/**
 * Returns the lowest frequency of the given array of frequencies.
 * For example, returns 'YEARLY' if the array contains 'DAILY' and 'YEARLY'.
 *
 * Returns 'UNKNOWN' if the array contains only 'UNKNOWN'.
 * Returns undefined if the array is empty or contains only nil values.
 */
export function lowestFrequencyOf(frequencies: MaybeFrequencyEnum[]): FrequencyEnum | undefined;
export function lowestFrequencyOf(frequencies: MaybeFrequencyType[]): FrequencyTypes | undefined;
export function lowestFrequencyOf(
  frequencies: MaybeFrequencyEnum[] | MaybeFrequencyType[],
): FrequencyTypes | FrequencyEnum | undefined {
  return (
    maxBy<MaybeFrequencyEnum | MaybeFrequencyType>(
      frequencies,
      (frequency) => (frequency && frequencyToSortRank[frequency]) || Number.NEGATIVE_INFINITY,
    ) ?? undefined
  );
}

/**
 * Returns the highest frequency of the given array of frequencies.
 * For example, returns 'DAILY' if the array contains 'DAILY' and 'YEARLY'.
 *
 * Returns 'UNKNOWN' if the array contains only 'UNKNOWN'.
 * Returns undefined if the array is empty.
 */
export function highestFrequencyOf(frequencies: MaybeFrequencyEnum[]): FrequencyEnum | undefined;
export function highestFrequencyOf(frequencies: MaybeFrequencyType[]): FrequencyTypes | undefined;
export function highestFrequencyOf(
  frequencies: MaybeFrequencyEnum[] | MaybeFrequencyType[],
): FrequencyTypes | FrequencyEnum | undefined {
  return (
    minBy<MaybeFrequencyEnum | MaybeFrequencyType>(
      frequencies,
      (frequency) => (frequency && frequencyToSortRank[frequency]) || Number.POSITIVE_INFINITY,
    ) ?? undefined
  );
}
