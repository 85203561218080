import React, { useState, useCallback, useMemo } from 'react';
import { intersectionBy } from 'lodash';
import {
  getProxyOptionsForFund,
  type ProxyOption,
  type ItemId,
  type ProxyTypeEnum,
  type ProxyCategoryMapping,
} from 'venn-api';
import { Notifications, NotificationType, RadioGroup, ShimmerBlock } from 'venn-ui-kit';
import type { AnalysisSubject } from 'venn-utils';
import {
  createProxyOptionsKey,
  getFormattedProxyType,
  logExceptionIntoSentry,
  useQueries,
  withSuspense,
} from 'venn-utils';

import { getCategoryProxyTypeMetadata, sortCategoryOptions, type SelectedProxy } from './utils';
import { SearchMenuBar, type SearchMenuItem } from '../../../search-menu';
import type { FundToBulkProxy } from '../types';

interface BulkCategoryOptionProps {
  investments: FundToBulkProxy[];
  proxyType?: ProxyTypeEnum;
  initialProxy: SelectedProxy | null;
  numLags?: number;
  onSelect: (proxyCategory: ProxyCategoryMapping | null, _numLags: number) => void;
}

interface RadioOption {
  element: JSX.Element;
  value: string;
  index: number;
  disabled?: boolean;
}

const ADDITIONAL_OPTIONS = 2; // the radio group has two additional options we're manually including: search input and a text category

const createCustomProxyOption = (searchItem: SearchMenuItem | null): ProxyCategoryMapping | null => {
  if (!searchItem) return null;

  return {
    category: null!,
    indexId: searchItem.searchResult?.fundId ?? '',
    displayName: 'Custom',
    indexName: searchItem.label,
  };
};

const TextCategory = ({ proxyType }: { proxyType?: ProxyTypeEnum }) => {
  if (!proxyType) {
    return null;
  }

  const proxyTypeMetadata = getCategoryProxyTypeMetadata(proxyType);
  const lowerCaseProxyTypeName = getFormattedProxyType(proxyType).toLowerCase();

  return (
    <div className="border border-solid border-venn-grey-30 border-b-0 p-4 flex flex-col">
      <span className="font-bold leading-normal">Or select a category below</span>
      <span className="text-venn-grey-80">
        Categories represent public indices that can be used for {lowerCaseProxyTypeName}.{' '}
        <a
          className="font-bold text-venn-dark-blue"
          target="_blank"
          rel="noopener noreferrer"
          href={proxyTypeMetadata.helpLink}
        >
          Learn more
        </a>
      </span>
    </div>
  );
};

const RadioCategory = ({ label, description }: { label: string; description: string }) => {
  return (
    <div className="flex flex-col ml-0.5">
      <span className="leading-normal">{label}</span>
      <span className="text-[12px] text-venn-grey-80">{description}</span>
    </div>
  );
};

const ShimmerFallback = () => {
  return (
    <div className="mt-3">
      {Array.from({ length: 8 }, (_, index) => (
        <ShimmerBlock
          height={70}
          key={`shimmer-${index}`}
          className="w-full border border-solid border-venn-grey-30 mb-0"
        />
      ))}
    </div>
  );
};

// TODO(VENN-28194): Ensure numLags is passed down properly
const InternalBulkCategoryOptions = ({
  investments,
  proxyType,
  numLags = 0,
  initialProxy,
  onSelect,
}: BulkCategoryOptionProps) => {
  const sortedProxyOptions = useProxyOptions(investments, proxyType, numLags);
  const options: RadioOption[] = useMemo(
    () =>
      sortedProxyOptions.map((option, index) => ({
        element: <RadioCategory label={option.category.displayName} description={option.category.indexName} />,
        value: option.category.indexId,
        index,
        disabled: !option.proxiedCumulativeReturn,
      })),
    [sortedProxyOptions],
  );

  const isCategoryProxy = useIsCategoryProxy(initialProxy, options);

  const [selectedIndex, setSelectedIndex] = useState<number | null>(() =>
    !!initialProxy && isCategoryProxy
      ? options.findIndex((option) => option.value === initialProxy.id) + ADDITIONAL_OPTIONS // +2 for the search and text options
      : null,
  );
  const [currentSearchValue, setCurrentSearchValue] = useState<SearchMenuItem | null>(() =>
    !!initialProxy && !isCategoryProxy
      ? ({
          category: 'investment',
          label: initialProxy.name,
          value: { id: initialProxy.id, name: initialProxy.name } as AnalysisSubject,
        } as SearchMenuItem)
      : null,
  );

  const onSearchSelected = useCallback(
    (option: SearchMenuItem) => {
      const customProxyOption = createCustomProxyOption(option);
      if (!customProxyOption) {
        return;
      }

      setSelectedIndex(0);
      setCurrentSearchValue(option);
      onSelect(customProxyOption, numLags);
    },
    [onSelect, numLags],
  );

  const combinedOptions = useMemo(() => {
    const allCategoryIds = sortedProxyOptions.map(
      (option): ItemId => ({
        id: option.category.indexId,
        type: 'FUND',
      }),
    );

    const searchOption = {
      element: (
        <SearchMenuBar
          className="ml-0.5"
          disabled={!!selectedIndex && selectedIndex !== 0 && !currentSearchValue}
          investmentsOnly
          autofocus={false}
          proxyable
          onSelected={onSearchSelected}
          displayResultsAsBlock
          value={currentSearchValue}
          isClearable
          clearQueryOnBlur
          closeOnSelect
          privateAssetSearchMode="PUBLIC_ONLY"
          excludedItems={allCategoryIds}
          location="bulk-proxy-picker"
          shortPlaceholder
        />
      ),
      value: 'custom',
      index: 0,
    };

    const textOption = {
      element: <TextCategory proxyType={proxyType} />,
      value: 'text',
      index: 1,
      showRadio: false,
    };
    return [searchOption, textOption, ...options];
  }, [selectedIndex, currentSearchValue, onSearchSelected, sortedProxyOptions, options, proxyType]);

  const onCategoryChange = useCallback(
    (value) => {
      const selectedOptionIndex = combinedOptions.findIndex((option) => option.value === value);
      setSelectedIndex(selectedOptionIndex);

      if (value === 'custom') {
        const customProxyOption = createCustomProxyOption(currentSearchValue);
        onSelect(customProxyOption, numLags);
      } else {
        const selectedOption = sortedProxyOptions.find((option) => option.category.indexId === value);
        selectedOption && onSelect(selectedOption.category, numLags);
      }
    },
    [combinedOptions, currentSearchValue, sortedProxyOptions, onSelect, numLags],
  );

  return (
    <div className="flex flex-col mt-3">
      <RadioGroup
        groupName="bulk-proxy-group"
        options={combinedOptions}
        onChange={onCategoryChange}
        className="border border-solid border-venn-grey-30 border-b-0 p-4 hover:bg-venn-grey-10 last:border-b mr-0"
        defaultOption={combinedOptions[selectedIndex ?? 0].value}
      />
    </div>
  );
};

const useProxyOptions = (
  investments: FundToBulkProxy[],
  proxyType?: ProxyTypeEnum,
  numLags?: number,
): ProxyOption[] => {
  const queries = useMemo(() => {
    if (!investments.length || !proxyType) {
      return [];
    }

    return investments.map(({ id }) => ({
      queryKey: createProxyOptionsKey(id, proxyType, numLags ?? 0),
      queryFn: () => getProxyOptionsForFund(id, proxyType, numLags ?? 0).then((response) => response.content),
      onError: (error) => {
        Notifications.notify('Unable to load suggested proxy categories', NotificationType.ERROR);
        logExceptionIntoSentry(error);
      },
      suspense: true,
    }));
  }, [investments, proxyType, numLags]);

  const results = useQueries({ queries });

  const sortedProxyOptions = useMemo(() => {
    const proxyOptions = results.reduce<ProxyOption[]>((acc, result) => {
      if (result.data) {
        if (acc.length === 0) {
          return result.data;
        }
        return intersectionBy(acc, result.data, 'category.indexId');
      }

      return acc;
    }, []);

    return proxyOptions.sort(sortCategoryOptions);
  }, [results]);

  return sortedProxyOptions;
};

const useIsCategoryProxy = (initialProxy: SelectedProxy | null, options: RadioOption[]): boolean => {
  return options.some((option) => option.value === initialProxy?.id);
};

export const BulkCategoryOptions = withSuspense(<ShimmerFallback />, React.memo(InternalBulkCategoryOptions));
