import React from 'react';
import styled, { css, type FlattenSimpleInterpolation } from 'styled-components';
import { noop } from 'lodash';
import backgroundImage from '../toggle/background';
import type { Theme, TooltipPosition } from 'venn-ui-kit';
import { ColorUtils, GetColor, Tooltip, Shimmer } from 'venn-ui-kit';

const sliderHeight = 30;
const sliderWidth = 80;

const Container = styled.div<ToggleProps>`
  display: flex;
  align-items: center;
  color: ${(props) => props.colors.container.color(props)};
  font-weight: bold;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  user-select: none;
`;

const LoadingShimmer = styled.div`
  ${Shimmer}
  width: ${sliderWidth}px;
  height: ${sliderHeight}px;
  border-radius: 16px;
`;

const Wrapper = styled.div`
  position: relative;
`;

const Toggle = styled.div<ToggleProps>`
  width: ${sliderWidth}px;
  height: ${sliderHeight}px;
  box-shadow: 0 0 0 1px ${(props) => props.colors.toggle.boxShadowColor(props)};
  border-radius: 16px;
  box-sizing: content-box;
  font-size: 11px;
  letter-spacing: 1px;
  transition-duration: 0.3s;

  background: ${(props) => props.colors.toggle.background(props)};

  &:hover {
    color: ${(props) => props.colors.toggle.hoverColor(props)};
  }

  ${(props) =>
    props.disabled &&
    css`
      box-shadow: 0 0 0 1px ${GetColor.Grey};
      cursor: not-allowed;
      pointer-events: none;
    `}
`;

const Indicator = styled.div<ToggleProps>`
  display: block;
  width: ${sliderHeight}px;
  height: ${sliderHeight}px;
  transition-duration: 0.3s;
  border-radius: ${sliderHeight / 2}px;
  background-color: ${(props) => props.colors.indicator.backgroundColor(props)};
  box-shadow: ${(props) => {
    const color = props.colors.indicator.boxShadowColor(props);
    return color ? `0 0 0 1px ${color}` : 'none';
  }};

  ${({ toggled }) =>
    toggled
      ? `
    transform: translateX(${sliderWidth - sliderHeight}px);
  `
      : ''};
`;

const TextIndicator = styled.span<{ toggled: boolean }>`
  position: absolute;
  top: 1px;
  line-height: ${sliderHeight}px;
  left: ${(props) => (props.toggled ? '20px' : '42px')};
`;

type Variants = 'new' | 'old';
interface SliderToggleProps {
  tooltipPosition?: TooltipPosition;
  tooltipMessage?: string;
  tooltipMaxWidth?: number;
  disabled?: boolean;
  toggled?: boolean;
  variant?: Variants;
  onToggle?(): unknown;
  loading?: boolean;
}

interface ToggleProps {
  disabled: boolean;
  toggled: boolean;
  colors: ToggleColorTheme;
  theme: Theme;
}

type ToggleColorTheme = {
  container: {
    color: (props: ToggleProps) => string;
  };
  toggle: {
    boxShadowColor: (props: ToggleProps) => string;
    background: (props: ToggleProps) => string | FlattenSimpleInterpolation;
    hoverColor: (props: ToggleProps) => string;
  };
  indicator: {
    backgroundColor: (props: ToggleProps) => string;
    boxShadowColor: (props: ToggleProps) => string | null;
  };
};

const colorThemes: Record<Variants, ToggleColorTheme> = {
  new: {
    container: {
      color: (props) =>
        (props.disabled ? GetColor.GreyScale.Grey40 : props.toggled ? GetColor.White : GetColor.DarkBlue)(props),
    },
    toggle: {
      boxShadowColor: GetColor.DarkBlue,
      background: (props) =>
        (props.disabled
          ? GetColor.GreyScale.Grey10
          : props.toggled
            ? GetColor.DarkBlue
            : ColorUtils.opacifyFrom(GetColor.DarkBlue, 0.1))(props),
      hoverColor: (props) =>
        (props.toggled ? ColorUtils.opacifyFrom(GetColor.DarkBlue, 0.15) : GetColor.PaleBlue)(props),
    },
    indicator: {
      backgroundColor: GetColor.White,
      boxShadowColor: (props) => (props.disabled ? GetColor.Grey : GetColor.DarkBlue)(props),
    },
  },
  old: {
    container: {
      color: (props) => (props.disabled ? GetColor.White : GetColor.Primary.Dark)(props),
    },
    toggle: {
      boxShadowColor: GetColor.Primary.Dark,
      background: (props) =>
        props.disabled
          ? `${backgroundImage} 0 0/2px 2px`
          : (props.toggled ? ColorUtils.opacifyFrom(GetColor.Primary.Dark, 0.1) : GetColor.White)(props),
      hoverColor: GetColor.Primary.Main,
    },
    indicator: {
      backgroundColor: GetColor.White,
      boxShadowColor: (props) => (props.disabled ? null : GetColor.Primary.Dark(props)),
    },
  },
};

export const SliderToggle = ({
  tooltipPosition,
  tooltipMessage,
  tooltipMaxWidth = 500,
  disabled = false,
  toggled = false,
  variant = 'old',
  onToggle = noop,
  loading = false,
}: SliderToggleProps) => {
  const toggleProps = { disabled, toggled, colors: colorThemes[variant] };
  const toggle = (
    <Toggle {...toggleProps} className="qa-slider-toggle">
      <Indicator {...toggleProps} />
      <TextIndicator toggled={toggled}>{toggled ? 'ON' : 'OFF'}</TextIndicator>
    </Toggle>
  );

  if (loading) {
    return (
      <Container {...toggleProps}>
        <LoadingShimmer duration={5} />
      </Container>
    );
  }

  return (
    <Container
      {...toggleProps}
      onClick={disabled ? noop : onToggle}
      className="qa-toggle"
      role="switch"
      aria-checked={toggled}
    >
      {!tooltipMessage ? (
        <Wrapper>{toggle}</Wrapper>
      ) : (
        <Tooltip maxWidth={tooltipMaxWidth} position={tooltipPosition} content={tooltipMessage || ''}>
          <Wrapper>{toggle}</Wrapper>
        </Tooltip>
      )}
    </Container>
  );
};

export default SliderToggle;
