import React from 'react';
import { useRecoilValue } from 'recoil';
import { useBlockId } from '../../contexts/BlockIdContext';
import type { PublicPrivateAssetGrowthBlockProps } from '../blocks/public-private';
import { blockExportMetadata, customizedBlock } from 'venn-state';
import { DEFAULT_USE_LOGARITHMIC_SCALE } from 'venn-utils';
import { usePublicPrivateGrowthPercentileChartData } from '../blocks/public-private/logic/usePublicPrivateGrowthPercentileChartData';
import { usePercentileChartExportDataFunction } from '../blocks/public-private/logic/usePercentileChartExportDataFunction';
import useExportUpdate from '../../logic/useExportUpdate';
import GrowthSimulationLineChart from './GrowthSimulationLineChart';
import styled from 'styled-components';
import { CurrencyEnum } from 'venn-api';
import { DownloadableContentBlock } from '../../../content-block';
import { EmptyHeaderSpace } from '../../../content-block/DownloadableContentBlock';
import { WATERMARK_POSITION_TOP } from '../../customAnalysisContants';

export const PublicPrivateAssetGrowthPercentilesChart = ({
  data,
  inPrintMode,
  downloadableContentRef,
  exportable,
}: PublicPrivateAssetGrowthBlockProps) => {
  const blockId = useBlockId();
  const selectedBlock = useRecoilValue(customizedBlock(blockId));
  const exportMetaData = useRecoilValue(blockExportMetadata(blockId));
  const useLogarithmicScale = selectedBlock?.logarithmicScale ?? DEFAULT_USE_LOGARITHMIC_SCALE;
  const { subjectsSeries, subjectInfos, fundingFailuresSeries } = usePublicPrivateGrowthPercentileChartData(data);

  const excelDataFn = usePercentileChartExportDataFunction({
    subjectsSeries: [...subjectsSeries],
    subjectInfos,
    exportable,
  });
  useExportUpdate({
    exportMetaData,
    excelDataFn,
    selectedRefId: blockId,
  });

  const simulationRange = data[0]?.simulationRange;

  const chartComponent = (
    <ChartWrapper data-testid="qa-public-private-growth-simulation-chart">
      <GrowthSimulationLineChart
        series={subjectsSeries}
        additionalSeries={fundingFailuresSeries}
        start={undefined}
        end={simulationRange?.end}
        frequency={'QUARTERLY' as const}
        yAxisUnitFormat="ratio"
        inPrintMode={inPrintMode}
        useLogarithmicScale={useLogarithmicScale}
        currency={CurrencyEnum.USD} // only USD supported for Private Asset Lab
        subjectInfo={subjectInfos}
      />
    </ChartWrapper>
  );

  if (inPrintMode) {
    return chartComponent;
  }

  return (
    <DownloadableContentBlock
      header={<EmptyHeaderSpace />}
      noBorder
      downloadable={{
        png: true,
        options: {
          fileName: 'Asset Growth - Percentiles',
          watermark: {
            top: WATERMARK_POSITION_TOP,
            right: 20,
          },
        },
        tracking: {
          description: 'GROWTH_SIMULATION_PUBLIC_PRIVATE',
          relativeToBenchmark: false,
          userUploaded: false,
          subjectType: subjectInfos.length
            ? subjectInfos?.[0]?.subject.subjectType
              ? subjectInfos?.[0]?.subject.subjectType === 'PORTFOLIO'
                ? 'portfolio'
                : 'investment'
              : undefined
            : undefined,
          subjectId: subjectInfos.length ? subjectInfos[0].subject.id : undefined,
        },
        disabled: (subjectsSeries?.length ?? 0) === 0,
        target: downloadableContentRef,
      }}
      floatingOptions
    >
      {chartComponent}
    </DownloadableContentBlock>
  );
};

const ChartWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  > div:first-child {
    flex: 1;
  }
`;
