import React from 'react';

import { Dates, type DATE_BEHAVIOR_ENUM } from 'venn-utils';
import type { HoldingsDataResponse } from 'venn-api';

import { Block } from '../../core';

interface AsOfDateProps {
  holdingsData: HoldingsDataResponse;
  dateBehavior: DATE_BEHAVIOR_ENUM;
}

export const AsOfDate = ({ holdingsData, dateBehavior }: AsOfDateProps) => {
  if (!holdingsData || dateBehavior !== 'SERVER_SENT') {
    return null;
  }
  const text = holdingsData.asOfDate ? `As of ${Dates.toDDMMMYYYY(holdingsData.asOfDate)}` : 'Most Recent Available';
  return <Block.MetadataItem iconType="calendar">{text}</Block.MetadataItem>;
};
