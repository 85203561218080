import {
  DATEPICKER_FREQUENCY_FORMATS,
  DISPLAY_FREQUENCY_FORMATS,
  FrequencyTypes,
  fundLengthInMonths,
} from '../frequency';
import type { FrequencyEnum, LibrarySearchEntity } from 'venn-api';
import type { Moment } from 'moment';
import moment from 'moment';
import type { DateRange, Granularity } from 'venn-ui-kit';
import isNil from 'lodash/isNil';

export const getDateFormat = (frequencyId?: FrequencyTypes | FrequencyEnum): string =>
  DATEPICKER_FREQUENCY_FORMATS[frequencyId || FrequencyTypes.MONTHLY];

export const getDateFormatForDisplay = (frequencyId?: FrequencyTypes | FrequencyEnum): string =>
  DISPLAY_FREQUENCY_FORMATS[frequencyId || FrequencyTypes.MONTHLY];

export const getFormattedFrequency = (frequency: FrequencyEnum): string => {
  switch (frequency) {
    case 'DAILY':
      return 'daily';
    case 'MONTHLY':
      return 'monthly';
    case 'QUARTERLY':
      return 'quarterly';
    case 'YEARLY':
      return 'yearly';
    default:
      return 'unknown';
  }
};

export function toEndOfPeriod(timestamp: number | string, granularity: Granularity): number;
export function toEndOfPeriod(timestamp: number | string | undefined, granularity: Granularity): number | undefined;
export function toEndOfPeriod(timestamp: moment.MomentInput, granularity: Granularity) {
  return timestamp !== undefined ? moment.utc(timestamp).endOf(granularity).valueOf() : undefined;
}

function lastBusinessDayOfMonth(dateMoment: Moment): Moment {
  const lastBusinessDayOfMonthMoment = dateMoment.endOf('month');

  if (lastBusinessDayOfMonthMoment.day() === 0) {
    lastBusinessDayOfMonthMoment.subtract(2, 'days');
  } else if (lastBusinessDayOfMonthMoment.day() === 6) {
    lastBusinessDayOfMonthMoment.subtract(1, 'day');
  }
  return lastBusinessDayOfMonthMoment;
}

export const containsLastWorkingDayOfMonth = (start: number, end: number): boolean => {
  const startMoment = moment.utc(start);
  const endMoment = moment.utc(end);

  const lastBusinessDayOfStartMonthEnd = lastBusinessDayOfMonth(moment.utc(startMoment));
  const lastBusinessDayOfStartMonthStart = moment.utc(lastBusinessDayOfStartMonthEnd).startOf('day');

  return (
    startMoment.valueOf() <= lastBusinessDayOfStartMonthEnd.valueOf() &&
    lastBusinessDayOfStartMonthStart.valueOf() <= endMoment.valueOf()
  );
};

export const isLessThanOneFactorTrendDataPoint = (
  start: number,
  end: number,
  frequency: FrequencyEnum,
): boolean | undefined => {
  const validFrequency = frequency === 'DAILY' || frequency === 'MONTHLY' || frequency === 'QUARTERLY';

  if (!validFrequency) {
    return undefined;
  }

  // For monthly and quarterly frequency, we can never have less than one data point, which is 1 month and 1 quarter,
  // respectively. The problem can only occur for daily frequency, for which 1 data point = 1 month.
  if (frequency === 'MONTHLY' || frequency === 'QUARTERLY') {
    return false;
  }

  return !containsLastWorkingDayOfMonth(start, end);
};

/**
 * Get the first day of negative returns in the drawdown.
 * This always occurs the day after the start of the drawdown.
 * See https://twosigma.atlassian.net/browse/VENN-9593
 */
export const getFirstNegativeDay = (start?: number) => {
  const negativeStart = moment.utc(start);
  negativeStart.add(1, 'days');
  return negativeStart;
};

// Using (end + 1) for `moment.diff` when the analyzed item is of monthly frequency because `moment.diff` is not
// precise for "months"/"years" units (especially the months of the different length). Adding 1ms is a hack that
// will help us show the right length in months that are shorter than average.
export const getPeriodLengthWithFrequency = (frequency: FrequencyEnum | undefined, start: number, end: number) => {
  // Special case to handle quarterly dates that have the same start and end which indicates that period is the entire quarterø
  if (frequency === 'QUARTERLY' && start === end) {
    return '3.0';
  }

  const months = moment.utc(end).diff(moment.utc(start), 'months', true);
  // Moment has some odd handling of months so we just round it for monthly/quarterly frequency ourselves
  return frequency === 'DAILY' ? months.toFixed(1) : Math.round(months).toFixed(1);
};

export const getCurrentTimeStamp = (): string => moment().format('YYYY-MM-DD hh:mm A');

export const hasNoOverlap = (dateRange?: DateRange, other?: DateRange | LibrarySearchEntity): boolean => {
  if (!other || isNil(dateRange?.from) || isNil(dateRange?.to)) {
    return false;
  }
  if ('startRange' in other) {
    return dateRange.from > other.endRange || dateRange.to < other.startRange;
  }

  if (other.from && dateRange.to < other.from) return true;
  if (other.to && dateRange.from > other.to) return true;
  return false;
};

/**
 * Measures if a date range falls outside a max range
 *
 * @param dateRange - Primary date range
 * @param maxRange - Maximum date range
 */
export const isOutsideRange = (dateRange: DateRange, maxRange: DateRange): boolean | null => {
  if (isNil(dateRange?.from) && isNil(dateRange?.to)) {
    return null;
  }

  if (dateRange?.from && maxRange?.from && dateRange.from < maxRange.from) return true;
  if (dateRange?.to && maxRange?.to && dateRange.to > maxRange.to) return true;

  return false;
};

/**
 * Returns a range representing the intersection of multiple date ranges, using their FROM and TO values only.
 *
 * If all from values are undefined, the result will use Number.MIN_SAFE_INTEGER.
 * If all to values are undefined, the result will use Number.MAX_SAFE_INTEGER.
 *
 * Returns undefined if:
 * * No ranges are provided.
 * * All ranges are undefined.
 * * All FROM and TO values are undefined.
 * * The intersection is empty.
 */
export function intersectRanges(...ranges: (DateRange | undefined)[]): { from: number; to: number } | undefined {
  if (ranges.length === 0 || ranges.every(isNil)) {
    return undefined;
  }

  const from = Math.max(...ranges.map(safeGetFrom));
  const to = Math.min(...ranges.map(safeGetTo));

  if (from === Number.MIN_SAFE_INTEGER && to === Number.MAX_SAFE_INTEGER) {
    return undefined;
  }
  if (from > to) {
    return undefined;
  }
  return { from, to };
}

/** Returns the lowest bound of a date range, or Number.MIN_SAFE_INTEGER if not defined. */
const safeGetFrom = (range: DateRange | undefined): number => safeReduceFromTo(Math.min, range);

/** Returns the highest bound of a date range, or Number.MAX_SAFE_INTEGER if not defined. */
const safeGetTo = (range: DateRange | undefined): number => safeReduceFromTo(Math.max, range);

/**
 * Runs the reducer against the from and to of the date range.
 * If either from or to is undefined, it will use Number.MIN_SAFE_INTEGER or Number.MAX_SAFE_INTEGER respectively.
 */
const safeReduceFromTo = (reducer: (from: number, to: number) => number, range: DateRange | undefined): number =>
  reducer(range?.from ?? Number.MIN_SAFE_INTEGER, range?.to ?? Number.MAX_SAFE_INTEGER);

/**
 * Returns a range with from and to fields properly ordered.
 *
 * If a nil range is provided, returns undefined.
 * If either from or to is undefined, returns the range as-is.
 */
export function orderFromTo(range: { from: number; to: number }): { from: number; to: number };
export function orderFromTo(range: DateRange): DateRange;
export function orderFromTo(range: undefined): undefined;
export function orderFromTo(range: DateRange | undefined): DateRange | undefined;
export function orderFromTo(range: DateRange | undefined): DateRange | undefined {
  return isNil(range?.from) || isNil(range?.to) || range.from <= range.to
    ? range
    : { from: range.to, to: range.from, period: range.period };
}

/**
 * Returns a string such as  "1 year 2 months" or 'less than 1 month'. Uses month-level granularity.
 */
export function getFormattedPeriod(
  fundFrequency: FrequencyEnum,
  fundStart: number,
  fundEnd: number,
  {
    short = false,
  }: {
    short?: boolean;
  } = {},
): string {
  const lengthInMonths = fundLengthInMonths(fundFrequency, fundStart, fundEnd);

  const lengthInYears = Math.floor(lengthInMonths / 12);
  const remainingMonths = lengthInMonths % 12;

  const yearStr = short ? 'yr' : 'year';
  const monthStr = short ? 'mo' : 'month';

  const yearsPart = lengthInYears === 0 ? '' : lengthInYears === 1 ? `1 ${yearStr}` : `${lengthInYears} ${yearStr}s`;
  const monthsPart =
    remainingMonths === 0 ? '' : remainingMonths === 1 ? `1 ${monthStr}` : `${remainingMonths} ${monthStr}s`;

  if (lengthInYears === 0 && remainingMonths === 0) {
    return `less than 1 ${monthStr}`;
  }

  return [yearsPart, monthsPart].filter((value) => !!value).join(' ');
}
