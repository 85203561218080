import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { compact, isEmpty } from 'lodash';
import { type SelectInstance } from 'react-select';

import { analyticsService, getCreatedByTrackingField } from 'venn-utils';
import type { SelectTypes } from 'venn-ui-kit';

import useMultiSelectSearch from './useMultiSelectSearch';
import { getDefaultColumns } from './components/Columns';
import FiltersProvider, { FiltersContext } from './components/FiltersProvider';
import type { CustomMultiSelectProps, GenericSearchMenuProps, SearchMenuItem } from './types';

import { StyledSelect } from './styled';
import ValueContainer from './components/ValueContainer';
import MultiSelectMenuList from './components/MultiSelectMenuList';
import Menu from './components/Menu';
import Option from './components/Option';
import { getItemType, placeholder } from './components/shared';
import { getOptionDisabledFunc, getOptionDisabledMessageFunc, itemEqualityCheck } from './utils';

const PaddingWrapper = styled.div`
  padding: 20px;
`;

export interface MultiSelectSearchProps extends GenericSearchMenuProps<true> {
  /**
   * Triggers when the user confirms to add their selection
   */
  onSelected?: (selected: SearchMenuItem[]) => void;
  /**
   * Triggers when the user adds or removes a selection
   */
  onSelectionsChange?: (selected: SearchMenuItem[]) => void;
  boundingRect?: DOMRect;
  maxSelections?: number;
  blurOnSelect?: boolean;
  menuTrailingContent?: (selectionsLeft: number) => JSX.Element | undefined;
  scrollAddIntoView?: boolean;
  onMenuStatusChange?: (open: boolean) => void;
  initialSelection?: SearchMenuItem[];
  shouldLimitSize: boolean;
}

const MultiSelectSearch = ({
  onSelected,
  onSelectionsChange,
  onBlur,
  autofocus = true,
  defaultMenuIsOpen = true,
  investmentsOnly = false,
  portfoliosOnly = false,
  privateAssetSearchMode = 'PUBLIC_ONLY',
  excludedItems,
  defaultValue,
  isClearable,
  disabled,
  isOptionDisabled,
  onQueryChange,
  showRecentlyAnalyzed = true,
  className,
  initialQuery,
  shortPlaceholder,
  fixedMenuWidth,
  optionDisabledTooltipContent,
  usePortal,
  customPlaceholder,
  smallScreen = false,
  proxyable = false,
  displayResultsAsBlock,
  columns = getDefaultColumns(portfoliosOnly, smallScreen),
  location,
  clearQueryOnBlur = false,
  darkPlaceholder = false,
  placeholderIds,
  showQuickFilters = false,
  footer,
  maxSelections,
  boundingRect,
  blurOnSelect,
  menuTrailingContent,
  scrollAddIntoView,
  onMenuStatusChange,
  menuStyles,
  initialSelection = [],
  forceMenuIsOpen,
  includeTags = true,
  includeBenchmarks = true,
  shouldLimitSize,
}: MultiSelectSearchProps) => {
  const selectRef = useRef<SelectInstance<SearchMenuItem, true> | null>(null);
  // Flag for if a value has been selected from the menu
  const hasSelectedValueRef = useRef<boolean>(false);
  const { selectedFilters, setSelectedFilters } = useContext(FiltersContext);
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(!!forceMenuIsOpen);

  const isOptionDisabledFunc = getOptionDisabledFunc(isOptionDisabled, investmentsOnly, portfoliosOnly);
  const {
    items,
    totalResults,
    loading,
    query,
    selections,
    onSearch,
    onSelect,
    onClear,
    onSelectAllWithTags,
    onUnselectSelectAllWithTags,
    onTagsDropdown,
    onTagsClose,
    onBulkTagSelect,
    onBulkTagUnselect,
    selectedTagDropdowns,
    openedTags,
    onMenuClose,
  } = useMultiSelectSearch({
    location,
    selectedFilters,
    menuIsOpen,
    excludedItems,
    investmentsOnly,
    portfoliosOnly,
    privateAssetSearchMode,
    showRecentlyAnalyzed,
    proxyable,
    initialQuery,
    placeholderIds,
    isOptionDisabled: isOptionDisabledFunc,
    initialSelection,
    onSelectionsChange,
    includeTags,
  });

  useEffect(() => {
    if (onMenuStatusChange) {
      onMenuStatusChange(menuIsOpen);
    }
  }, [menuIsOpen, onMenuStatusChange]);

  const noOptionsMessage = useCallback(
    ({ inputValue }) => {
      if (inputValue.length) {
        if (loading) {
          return <PaddingWrapper>Searching...</PaddingWrapper>;
        }
        return (
          <PaddingWrapper>
            <div>
              <b>No results found for your query</b>
            </div>
            <div>
              {`You may want to try using different keywords or searching by name${
                !portfoliosOnly ? ' or ticker' : ''
              }.`}
            </div>
          </PaddingWrapper>
        );
      }
      return loading ? <PaddingWrapper>Loading...</PaddingWrapper> : null;
    },
    [loading, portfoliosOnly],
  );

  const closeMenu = useCallback(() => {
    // blur causes onMenuBlur to fire which closes the menu
    // we don't directly close the menu because the input won't blur and we get to a weird state
    selectRef.current?.blur();
  }, []);

  const onInputChange = useCallback(
    (search: string, { action }: SelectTypes.InputActionMeta) => {
      switch (action) {
        case 'input-change':
          hasSelectedValueRef.current = false;
          onQueryChange?.(search);
          onSearch(search);
          break;
        case 'set-value':
          hasSelectedValueRef.current = true;
          break;
      }
    },
    [onQueryChange, onSearch],
  );

  const onChange = useCallback(
    (selection: SelectTypes.MultiValue<SearchMenuItem>, actionMeta: SelectTypes.ActionMeta<SearchMenuItem>) => {
      if (actionMeta.action === 'pop-value') {
        return;
      }
      if (actionMeta.action === 'clear') {
        hasSelectedValueRef.current = false;
        onClear?.();
        return;
      }
      if (Array.isArray(selection)) {
        onSelect(selection);
      }
    },
    [onClear, onSelect],
  );

  // used to differentiate the initial closed state from the actual close event
  const hasMenuOpened = useRef(false);
  useEffect(() => {
    // Whenever menuIsOpen is false, it must be a search dismissal
    // aside from the very first time, as denoted by hasMenuOpened.current being false
    if (hasMenuOpened.current && !menuIsOpen) {
      const noResultsShowing = !query && !showRecentlyAnalyzed;
      if (!hasSelectedValueRef.current && !noResultsShowing) {
        const quickFilters = compact(selectedFilters.map((filter) => filter.quickFilter));
        const typeFilters = compact(selectedFilters.map((filter) => filter.itemType));
        analyticsService.searchDismissed({
          query,
          location,
          totalResults,
          visibleResults: items.length,
          quickFilters,
          typeFilters,
        });
      }
      onMenuClose();
      if (clearQueryOnBlur) {
        onClearSearch();
        setSelectedFilters(() => []);
      }
    } else if (menuIsOpen) {
      hasMenuOpened.current = true;
    }
    // Disabled 'location', 'query', and 'showRecentlyAnalyzed', as we want to track this event based only on menuIsOpen flag
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuIsOpen]);

  const onClearSearch = useCallback(() => {
    onSearch('');
  }, [onSearch]);

  const onMenuBlur = useCallback(() => {
    onBlur?.();
    if (forceMenuIsOpen) {
      return;
    }
    setMenuIsOpen(false);
  }, [forceMenuIsOpen, onBlur]);

  const onMenuFocus = useCallback(() => {
    setMenuIsOpen(true);
    hasSelectedValueRef.current = false;
  }, []);

  const handleEnterPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    const focusedClickedOption = selectRef.current?.state.focusedOption;

    if (!focusedClickedOption) {
      return;
    }

    if (focusedClickedOption?.category === 'tag') {
      const isTagOpen =
        openedTags.some((openedTag: SearchMenuItem) => itemEqualityCheck(openedTag, focusedClickedOption)) || false;

      isTagOpen ? onTagsClose(focusedClickedOption) : onTagsDropdown(focusedClickedOption);

      return;
    }

    onSelect([...selections, focusedClickedOption]);
  };

  const handleKeyEvents = (e: React.KeyboardEvent<HTMLDivElement>) => {
    switch (e.key) {
      case 'Enter':
        handleEnterPress(e);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (autofocus) {
      selectRef.current?.focus();
    }
  }, [autofocus, selectRef]);

  const handleOnSelected = () => {
    const selectedItemTypes = compact(selectedFilters.map((filter) => filter.itemType));
    const selectedQuickFilters = compact(selectedFilters.map((filter) => filter.quickFilter));
    const createdBy = selections.map((item: SearchMenuItem) => getCreatedByTrackingField(item.searchResult));
    const objectType = selections.map((item) => getItemType(item));
    const countItemsFromTag = selections.filter((selection) => selection.isTagged).length || 0;

    analyticsService.searchMultiSelectionMade({
      location,
      countItems: selections.length,
      countItemsFromTag,
      query,
      totalResults: items.length,
      quickFilters: selectedQuickFilters,
      typeFilters: selectedItemTypes,
      createdBy,
      objectType,
    });

    const filterOutTags = selections.filter((selection) => isEmpty(selection.searchResult?.tagIds));

    onSelected?.(filterOutTags);
    if (blurOnSelect) {
      selectRef.current?.blur();
    }
  };

  const maxHeightCss = boundingRect?.top ? `calc(100vh - ${boundingRect?.top}px - 70px)` : undefined;

  const customProps: CustomMultiSelectProps = {
    columns,
    onClearSearch,
    fixedMenuWidth,
    showQuickFilters,
    investmentsOnly,
    portfoliosOnly,
    privateAssetSearchMode,
    footer,
    closeSearchMenu: closeMenu,
    onClear,
    onTagsDropdown,
    onTagsClose,
    selectedTagDropdowns,
    openedTags,
    loading,
    selections,
    onSelectAllWithTags,
    onUnselectSelectAllWithTags,
    onBulkTagSelect,
    onBulkTagUnselect,
    handleOnSelected,
    excludedItems,
    maxSelections,
    smallScreen,
    menuTrailingContent,
    scrollAddIntoView,
    menuStyles,
    includeTags,
    includeBenchmarks,
    shouldLimitSize,
  };

  return (
    <StyledSelect
      ref={selectRef}
      classNamePrefix="select"
      className={className ?? 'qa-search-menu-bar'}
      value={selections}
      defaultValue={defaultValue}
      captureMenuScroll={false}
      placeholder={
        !selectedFilters.length &&
        placeholder({
          customPlaceholder,
          condition: shortPlaceholder || (darkPlaceholder && !menuIsOpen),
          portfoliosOnly,
          investmentsOnly,
          smallScreen,
        })
      }
      options={items}
      inputValue={query}
      onInputChange={onInputChange}
      onChange={onChange}
      onBlur={onMenuBlur}
      onFocus={onMenuFocus}
      onKeyDown={handleKeyEvents}
      filterOption={() => true}
      isOptionDisabled={isOptionDisabledFunc}
      optionDisabledTooltipContent={getOptionDisabledMessageFunc(
        optionDisabledTooltipContent,
        investmentsOnly,
        portfoliosOnly,
      )}
      defaultMenuIsOpen={defaultMenuIsOpen}
      isClearable={isClearable}
      noOptionsMessage={noOptionsMessage}
      isDisabled={disabled}
      menuPortalTarget={usePortal ? document.body : undefined}
      displayResultsAsBlock={displayResultsAsBlock}
      menuIsOpen={forceMenuIsOpen ?? menuIsOpen}
      darkPlaceholder={darkPlaceholder}
      tabSelectsValue={false}
      closeMenuOnSelect={false}
      isMulti
      hideSelectedOptions={false}
      controlShouldRenderValue={false}
      blurInputOnSelect={false}
      {...customProps}
      maxHeightCss={maxHeightCss}
      closeMenuOnScroll={usePortal ? () => true : undefined}
      components={{
        ValueContainer,
        MenuList: MultiSelectMenuList,
        Menu,
        Option,
      }}
    />
  );
};

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default React.memo((props: MultiSelectSearchProps) => (
  <FiltersProvider>
    <MultiSelectSearch {...props} />
  </FiltersProvider>
));
