import React from 'react';
import { Radio } from '../radio/Radio';
import { isEqual } from 'lodash';
import { cn } from '@/lib/utils';

type LabelledType<T> = {
  value: T;
  element?: React.ReactNode;
  label?: string;
  description?: string;
  showRadio?: boolean;
  disabled?: boolean;
};

export interface RadioGroupProps<T> {
  /** Used to group radios so that only one can be selected at a time. Must be unique. */
  groupName: string;

  /** onChange event handler. Uses only the value associated with radio (without label) */
  onChange: (value: T) => void;

  /** List of options presented. These must contain a value of any type and a string label */
  options: LabelledType<T>[];

  /** The value of the option to be checked on initial render. Deep equality check is performed. */
  defaultOption?: T;

  /** Optional className prop */
  className?: string;
}

export const RadioGroup = <T,>({ groupName, onChange, options, defaultOption, className }: RadioGroupProps<T>) => (
  <>
    {options.map(({ value, element, label, description, showRadio = true, disabled = false }, index) =>
      showRadio ? (
        <Radio
          key={`radio-group-${value}-index`}
          name={groupName}
          defaultChecked={defaultOption ? isEqual(value, defaultOption) : index === 0}
          value={label}
          description={description}
          onChange={() => onChange(value)}
          inputId={`${groupName}-${index}`}
          className={cn(className, { 'cursor-pointer': !disabled, 'cursor-not-allowed': disabled })}
          disabled={disabled}
        >
          {element ?? label}
        </Radio>
      ) : (
        <div key={`radio-group-${value}-index`}>{element ?? label}</div>
      ),
    )}
  </>
);
